import axios from 'axios';
import qs from 'qs';

const apiCMS = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_URL
});

export type BaseApiResponse<T = any> = {
  data: {
    id: number | string;
    attributes: T;
  };
};

export interface File {
  name: string;
  hash: string;
  ext: string;
  url: string;
  size: string;
}

export interface Link {
  privacyPolicy: BaseApiResponse<File>;
  privacyNotice: BaseApiResponse<File>;
}

interface UrlParamsObject {
  populate?: string | string[] | Record<string, string | string[] | UrlParamsObject>;
  filters?: Record<string, any>;
  fields?: string[];
}

export const fetchApi = async <T = any>(
  path: string,
  urlParamsObject: UrlParamsObject = {}
): Promise<T> => {
  const queryString = qs.stringify({ ...urlParamsObject });
  const completeQueryString = queryString ? `?${queryString}` : '';
  const completeUrl = '/api' + path + completeQueryString;
  const resultUrl = await apiCMS.get<T>(completeUrl);
  return resultUrl.data;
};

export const getFileFullURL = (file: File) => {
  return `${process.env.REACT_APP_CMS_API_URL}/uploads/${file.hash}${file.ext}`;
};
