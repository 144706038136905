import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import { CurriculumFile } from '../../modules/careers/models/Candidate';

interface ConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  file?: File;
  curriculumFile?: CurriculumFile;
  getUrlImage?: (imageName: string) => string;
}

const ImagePreviewModal = ({
  isOpen,
  onClose,
  file,
  curriculumFile,
  getUrlImage
}: ConfigModalProps) => {
  const getPreviewURL = (file: File) => {
    const previewURL = URL.createObjectURL(file);
    return previewURL;
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed z-50 justify-center items-center h-full inset-0">
              <div className="relative px-4 w-full max-w-6xl m-auto">
                <div className="flex justify-center">
                  <div className="relative bg-white rounded shadow w-10/12 ">
                    <div className="absolute top-0 right-0 p-2">
                      <XIcon className="w-5 h-5 text-red-600 cursor-pointer" onClick={onClose} />
                    </div>
                    <div className="flex justify-center items-center p-4 mt-6">
                      {curriculumFile && curriculumFile.mimetype.includes('image') ? (
                        <img src={getUrlImage && getUrlImage(curriculumFile.completeName)} />
                      ) : (
                        curriculumFile &&
                        curriculumFile.mimetype.includes('application/pdf') && (
                          <iframe
                            width="100%"
                            style={{ height: '90vh' }}
                            src={getUrlImage && getUrlImage(curriculumFile.completeName)}
                          ></iframe>
                        )
                      )}
                      {file && file.type.includes('image') ? (
                        <img src={getPreviewURL(file)} />
                      ) : (
                        file &&
                        file.type.includes('application/pdf') && (
                          <iframe
                            width="100%"
                            style={{ height: '90vh' }}
                            src={URL.createObjectURL(file)}
                          ></iframe>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImagePreviewModal;
