import api from '../../_shared/services/api';
import { Result, ResultLogin } from '../../_shared/types/api.types';
import { LoginCredentials } from '../contexts/AuthProvider';

export interface ForgotPassword {
  email: string;
  originPath: string;
  validationParam: string;
}

export interface RedefinePassword {
  password: string;
  confirmPassword: string;
  verifyCode: string;
}

class AuthService {
  public signIn = async (login: LoginCredentials): Promise<Result<ResultLogin>> => {
    const response = await api.post('/auth/login', login);
    return response.data;
  };

  public async forgetPassword(forgetPasswordDto: ForgotPassword): Promise<void> {
    await api.post('/auth/forgot-password', forgetPasswordDto);
  }

  public async redefinePassword(redefinePassword: RedefinePassword): Promise<Result<ResultLogin>> {
    const response = await api.post('/auth/redefine-password', redefinePassword);
    return response.data;
  }
}

export default new AuthService();
