import { Notifiable } from '../../../infra/Notifiable';
import { Validator } from '../../../infra/Validator';
import { UniversityCourse } from '../models/Scholarity';

export class CreateUniversityCourseContract extends Notifiable {
  private dto: UniversityCourse;
  private validator: Validator;

  constructor(dto: UniversityCourse) {
    super();
    this.dto = dto;
    this.validator = new Validator();
  }

  validate(): boolean {
    this.validateUniversityCourseType();
    this.validateCourse();
    this.validateCoursePeriod();
    this.validateTeachingEntity();

    this.addReports(this.validator.reports);
    return this.isValid();
  }

  private validateUniversityCourseType() {
    const { type } = this.dto;
    this.validator.isRequired(type, 'type', 'Informe o tipo do curso superior');
  }

  private validateCourse() {
    const { course } = this.dto;
    this.validator.isRequired(course, 'course', 'Informe o nome do curso superior');
  }

  private validateCoursePeriod() {
    const { startDate } = this.dto;
    this.validator.isValidDate(startDate, 'startDate', 'Data de início inválida');

    const { endDate } = this.dto;
    this.validator.isValidDate(endDate, 'endDate', 'Data de término invalida');
    if (startDate && endDate) {
      this.validator.isDateGreaterOrEqualsThan(
        startDate,
        endDate,
        'endDate',
        'Data de conclusão não pode ser igual ou menor que a data de início do curso'
      );
    }
  }

  private validateTeachingEntity() {
    const { teachingEntity } = this.dto;
    this.validator.isRequired(teachingEntity, 'teachingEntity', 'Informe a entidade de ensino');
  }
}
