import queryString from 'query-string';
import api from '../../../_shared/services/api';
import { Paginate, Result } from '../../../_shared/types/api.types';
import { CreateOpportunityDto } from '../dtos/CreateOpportunityDto';
import { EditOpportunityDto } from '../dtos/EditOpportunity';
import { PaginateOpportunityDto } from '../dtos/PaginateOpportunityDto';
import { Opportunity } from '../models/Opportunity';

class OpportunityService {
  public async newOpportunity(
    createOpportunityDto: CreateOpportunityDto
  ): Promise<Result<CreateOpportunityDto>> {
    const response = await api.post('/opportunities', createOpportunityDto);
    return response.data;
  }

  public async editOpportunity(
    id: string,
    editOpportunity: EditOpportunityDto
  ): Promise<Result<EditOpportunityDto>> {
    const response = await api.put(`/opportunities/${id}`, editOpportunity);
    return response.data;
  }

  public async paginateOpportunity({
    ...paginateOpportunity
  }: PaginateOpportunityDto): Promise<Paginate<Opportunity>> {
    const queryParams = queryString.stringify(paginateOpportunity);
    const response = await api.get(`/opportunities?${queryParams}`);
    return response.data.data;
  }

  public async getOpportunity(opportunityId: string): Promise<Result<Opportunity>> {
    const response = await api.get(`/opportunities/${opportunityId}`);
    return response.data;
  }
}

export default new OpportunityService();
