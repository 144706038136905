import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import Routes from '../modules/_shared/constants/Routes.enum';
import { useAuth } from '../modules/auth/contexts/AuthProvider';
import ListCareers from '../modules/careers/pages/ListCareers/Index';
import RegisterCareers from '../modules/careers/pages/RegisterCareers';
import DiscloseOpportunityDetail from '../modules/discloseOpportunities/pages/DiscloseOpportunityDetail';
import EditJobsProfile from '../modules/jobsProfile/pages/editJobsProfile';
import ListJobsProfile from '../modules/jobsProfile/pages/listJobsProfile';
import LogoutNotice from '../modules/notify/pages/LogoutNotice';
import ListOccupationArea from '../modules/opportunities/OccupationArea/pages/ListOccupationArea';
import ListOpportunity from '../modules/opportunities/Opportunities/pages/ListOpportunity';
import RegistrationOpportunity from '../modules/opportunities/Opportunities/pages/RegistrationOpportunity';
import ListTypeOpportunity from '../modules/opportunities/TypeOpportunity/pages/ListTypeOpportunity';
import ListWorkspace from '../modules/opportunities/Workspace/pages/ListWorkspace';
import ListUsers from '../modules/users/pages/ListUsers';

const BaseRoute: React.FC = ({ children }) => (
  <HashRouter>
    <MainLayout>
      <Switch>{children}</Switch>
    </MainLayout>
  </HashRouter>
);

const AppRoutes: React.FC = () => {
  const { user } = useAuth();

  const PATHNAME = window.location.hash.slice(1);

  const KEY_PARAMS = 'verifyCode';
  const haveVerifyCode = PATHNAME.indexOf(KEY_PARAMS) !== -1;

  return (
    <BaseRoute>
      {user?.isAdmin || user?.isRecruiter ? (
        <>
          {haveVerifyCode ? (
            <Redirect to={{ pathname: Routes.NOTIFY, search: PATHNAME }} />
          ) : (
            <Redirect exact path="/" to={Routes.LIST_CAREERS} />
          )}
          <Route path={Routes.NOTIFY} exact component={LogoutNotice} />
          <Route path={Routes.LIST_CAREERS} exact component={ListCareers} />
          <Route path={Routes.LIST_USERS} exact component={ListUsers} />
          <Route path={Routes.LIST_TYPE_OPPORTUNITY} exact component={ListTypeOpportunity} />
          <Route path={Routes.LIST_WORKSPACE} exact component={ListWorkspace} />
          <Route path={Routes.LIST_OCCUPATION_AREA} exact component={ListOccupationArea} />
          <Route path={Routes.LIST_OPPORTUNITY} exact component={ListOpportunity} />
          <Route path={Routes.CREATE_OPPORTUNITY} exact component={RegistrationOpportunity} />
          <Route path={Routes.EDIT_OPPORTUNITY} exact component={RegistrationOpportunity} />
          <Route path={Routes.LIST_JOBS} exact component={ListJobsProfile} />
          <Route path={Routes.EDIT_JOB} exact component={EditJobsProfile} />
          <Route
            path={Routes.DISCLOSE_OPPORTUNITIES_DETAIL}
            exact
            component={DiscloseOpportunityDetail}
          />
          <Route exact path="/">
            <Redirect exact path="/" to={Routes.LIST_CAREERS} />
          </Route>
        </>
      ) : (
        <>
          {haveVerifyCode ? (
            <Redirect to={{ pathname: Routes.NOTIFY, search: PATHNAME }} />
          ) : (
            <Redirect exact path="/" to={Routes.REGISTER_CAREERS} />
          )}
          <Route path={Routes.NOTIFY} exact component={LogoutNotice} />
          <Route path={Routes.REGISTER_CAREERS} exact component={RegisterCareers} />
          <Route
            path={Routes.DISCLOSE_OPPORTUNITIES_DETAIL}
            exact
            component={DiscloseOpportunityDetail}
          />
          <Route exact path="/">
            <Redirect exact path="/" to={Routes.REGISTER_CAREERS} />
          </Route>
        </>
      )}
    </BaseRoute>
  );
};
export default AppRoutes;
