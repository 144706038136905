import { Combobox, Transition } from '@headlessui/react';
import { SelectorIcon, XIcon } from '@heroicons/react/solid';
import React, { Fragment, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { OptionSelect } from '../../../../../components/FormComponents/SelectForm';

type Props = {
  onChange: (value: any, userId?: any) => void;
  options: OptionSelect[];
  userActive?: any;
  itemActive?: any;
  placeHolder?: string;
  isFilter?: boolean;
};
const AutoCompleteJobProfile: React.FC<Props> = ({
  onChange,
  options,
  userActive,
  itemActive,
  placeHolder,
  isFilter
}) => {
  const [valueSelected, setValueSelected] = useState<OptionSelect | undefined>(itemActive);
  const [query, setQuery] = useState('');

  const error = false;

  const filteredSelect =
    query === ''
      ? options
      : options.filter((option) =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const handleClickclearFilter = () => {
    setValueSelected(undefined);
    setQuery('');
    if (isFilter) {
      onChange(null);
    } else {
      onChange(null, userActive.id);
    }
  };

  const handleChangeHiringProfile = (optionSelected: OptionSelect) => {
    setValueSelected(optionSelected);
    if (isFilter) {
      onChange(optionSelected.value);
    } else {
      onChange(optionSelected.value, userActive.id);
    }
  };

  useEffect(() => {
    if (itemActive) {
      const indexDefaultValue = options.findIndex((option) => option.value === itemActive.id);
      setValueSelected(options[indexDefaultValue]);
    } else {
      setValueSelected(undefined);
    }
  }, [itemActive]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query]);

  return (
    <div data-for="select-description" data-tip={valueSelected?.label}>
      <Combobox as="div" value={valueSelected} onChange={handleChangeHiringProfile}>
        <div className="relative z-auto">
          <Combobox.Button
            data-for={itemActive?.id}
            data-tip={valueSelected?.label}
            className="relative w-full py-2 pl-3 pr-10 text-left bg-transparent rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-2 sm:text-sm"
          >
            <span
              className={`block truncate  text-gray-500 ${
                valueSelected ? 'font-semibold' : 'font-medium'
              }`}
            >
              {valueSelected ? valueSelected.label : placeHolder || 'Perfil de contratação'}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Combobox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options className="absolute right-0 w-72 sm:w-64 p-1 rounded-sm mt-1 text-base bg-white shadow-lg  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
              <div className="py-2 px-3 text-gray-900">
                <div className="flex flex-row relative rounded p-1.5 border focus:outline-none placeholder:text-gray-400 text-gray-500 items-center">
                  <Combobox.Input
                    className="w-full focus:outline-none block truncate pr-2"
                    displayValue={valueSelected ? (option: OptionSelect) => option.label : () => ''}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Pesquisar"
                  />
                  {valueSelected && (
                    <div className="cursor-pointer">
                      <XIcon
                        className="justify-end w-4 h-4  text-gray-400"
                        onClick={handleClickclearFilter}
                      />
                    </div>
                  )}
                </div>
              </div>
              {filteredSelect.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-500">
                  Nenhuma opção encontrada.
                </div>
              ) : (
                <div className="max-h-[300px] overflow-auto">
                  {filteredSelect.map((option) => (
                    <Combobox.Option
                      data-for="option-description"
                      data-tip={option.label}
                      key={option.value}
                      className={({ active }) =>
                        `cursor-pointer select-none relative rounded-sm py-2 px-4 ${
                          active && valueSelected ? 'text-green-900 bg-gray-100' : 'text-gray-900'
                        }`
                      }
                      value={option}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected && valueSelected
                                ? 'font-semibold text-teal-600'
                                : 'font-normal'
                            }  `}
                          >
                            {option.label}
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </div>
              )}
              <ReactTooltip
                className="z-50 bg-primary-700 text-sm rounded text-neutral-10 max-w-sm"
                id="option-description"
                place="top"
              />
            </Combobox.Options>
          </Transition>
        </div>
        <ReactTooltip
          className="z-50 bg-primary-700 text-sm rounded text-neutral-10 max-w-sm"
          id={itemActive?.id}
          place="top"
        />
      </Combobox>
    </div>
  );
};

export default AutoCompleteJobProfile;
