import React, { useEffect, useState } from 'react';
import { Step } from '../..';
import { useAuth } from '../../../../../auth/contexts/AuthProvider';
import CandidateItemMenu from './CandidateItemMenu';

type Props = {
  onClick: (step: Step) => void;
  valueActive: Step;
};

const CandidateMenu = ({ onClick, valueActive }: Props) => {
  const [step, setStep] = useState<Step>(valueActive | Step.PROFILE_CANDIDATE);
  const { signed } = useAuth();

  const handleClickItemMenu = (stepValue: Step) => {
    setStep(stepValue);
    onClick(stepValue);
  };

  const isActive = (stepValue: Step) => {
    return stepValue === step;
  };

  useEffect(() => {
    handleClickItemMenu(valueActive);
  }, [valueActive]);

  return (
    <div className={`${!signed ? 'hidden' : 'flex sm:mt-10 mt-2 w-full'}`}>
      <ul className="flex flex-col gap-2 sm:px-4 pt-3 sm:mt-2 w-full">
        <li onClick={() => handleClickItemMenu(Step.PROFILE_CANDIDATE)}>
          <CandidateItemMenu
            disabled={false}
            active={isActive(Step.PROFILE_CANDIDATE)}
            name={'Perfil'}
          />
        </li>
        <li onClick={() => handleClickItemMenu(Step.APPLICATION_OPPORTUNITY)}>
          <CandidateItemMenu
            disabled={!signed}
            active={isActive(Step.APPLICATION_OPPORTUNITY)}
            name={'Candidaturas'}
          />
        </li>
        <li onClick={() => handleClickItemMenu(Step.LIST_OPPORTUNITIES)}>
          <CandidateItemMenu
            disabled={!signed}
            active={isActive(Step.LIST_OPPORTUNITIES)}
            name={'Oportunidades'}
          />
        </li>
        <li onClick={() => handleClickItemMenu(Step.EDIT_CURRICULUM)}>
          <CandidateItemMenu
            disabled={!signed}
            active={isActive(Step.EDIT_CURRICULUM)}
            name={'Anexar Curriculum'}
          />
        </li>
      </ul>
      <span className="sm:border-l sm:border-gray-300 sm:h-80" />
    </div>
  );
};

export default CandidateMenu;
