import { PencilIcon } from '@heroicons/react/solid';
import React from 'react';
import Button from '../../../../../../../components/Button';
import ListCard from '../../../../../../../components/ListCard';
import Table from '../../../../../../../components/Table';
import { TypeOpportunity } from '../../../../models/TypeOpportunity';

interface Props {
  vague?: TypeOpportunity[];
  onClickEditTypeOpportunity: (typeOpportunityId: number) => void;
}

const TypeOpportunityTable = ({ onClickEditTypeOpportunity, vague }: Props) => {
  const translateStatus = (value: any) => {
    if (value) {
      return 'Ativo';
    } else {
      return 'Inativo';
    }
  };

  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            { columnName: 'Nome', key: 'name' },
            {
              columnName: 'Status',
              key: 'isActive',
              transform: (value) => translateStatus(value)
            },
            {
              columnName: '',
              key: '',
              component: (value, itemActive) => {
                return (
                  <div className="flex justify-end space-x-2 gap-1">
                    <PencilIcon
                      className="w-5 cursor-pointer text-teal-600"
                      onClick={() => onClickEditTypeOpportunity(itemActive.id)}
                    />
                  </div>
                );
              }
            }
          ]}
          values={vague}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          items={[
            { label: 'Nome', key: 'name' },
            { label: 'Status', key: 'isActive', transform: (value) => translateStatus(value) },
            {
              label: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
                  <Button
                    styles="w-full"
                    variant="primary"
                    onClick={() => onClickEditTypeOpportunity(itemActive.id)}
                  >
                    Editar
                  </Button>
                </div>
              )
            }
          ]}
          values={vague}
        />
      </div>
    </>
  );
};

export default TypeOpportunityTable;
