import { Switch } from '@headlessui/react';
import { ArrowLeftIcon, PencilIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../../components/Button';
import Routes from '../../../_shared/constants/Routes.enum';
import { Level } from '../../../_shared/enum/Level';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { UpdateJobsProfileDto } from '../../dtos/updateJobsProfileDto';
import { JobsProfile } from '../../models/JobsProfile';
import JobsProfileService from '../../service/JobsProfileService';
import { JobProfileGroup } from '../listJobsProfile';
import CreateJobsProfileModal from '../listJobsProfile/components/createJobsProfileModal';

// type ParamsProps = { id: string };

interface EditProps {
  id: string;
}

const EditJobsProfile = ({ id }: EditProps) => {
  const formRef = useRef<FormHandles>(null);
  // const { id } = useParams<ParamsProps>();
  const location = useLocation();
  const [jobProfileGroup, setJobProfileGroup] = useState<JobProfileGroup>();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleAddNewLevel = async () => {
    const convertMultiLevel = jobProfileGroup?.jobProfiles.length === 1;
    const jobProfiles = [];
    if (convertMultiLevel) {
      try {
        const jobProfile = jobProfileGroup?.jobProfiles[0];
        if (jobProfile) {
          const updateJobProfile = {
            name: jobProfile?.name,
            level: Level.I,
            isActive: true
          } as UpdateJobsProfileDto;
          const result = await JobsProfileService.updateJobsProfile(
            jobProfile.id,
            updateJobProfile
          );
          jobProfiles.push(result.data);
        }
      } catch (error) {
        handleErrors(error);
      }
    }

    try {
      if (jobProfileGroup && jobProfileGroup?.level !== 5) {
        const createJobProfile = {
          name: jobProfileGroup?.name,
          level: jobProfileGroup.jobProfiles.length + 1,
          isActive: true
        } as JobsProfile;
        const result = await JobsProfileService.createJobsProfile(createJobProfile);

        setJobProfileGroup({
          ...jobProfileGroup,
          level: jobProfileGroup.jobProfiles.length,
          jobProfiles: convertMultiLevel
            ? jobProfiles.concat(result.data)
            : jobProfileGroup.jobProfiles.concat(result.data)
        });
        toast.success(result.message);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleSwitchChange = (jobProfileId: string) => {
    return () => {
      setJobProfileGroup((profileGroup) => {
        if (!profileGroup) return profileGroup;

        const updatedJobProfiles = profileGroup.jobProfiles.map((profile) => {
          if (profile.id === jobProfileId) {
            profile.isActive = !profile.isActive;
          }
          return profile;
        });

        return {
          ...profileGroup,
          isActive: updatedJobProfiles
        };
      });
    };
  };

  const handleConfirmEditNameJobProfiles = (value: any) => {
    setJobProfileGroup((profileGroup) => {
      if (!profileGroup) return profileGroup;

      const updatedJobProfiles = profileGroup.jobProfiles.map((profile) => {
        profile.name = value.newName;
        return profile;
      });

      return {
        ...profileGroup,
        name: value.newName,
        jobProfiles: updatedJobProfiles
      };
    });
  };

  const handleEditJobNameAndStatus = async () => {
    try {
      const jobProfiles = jobProfileGroup?.jobProfiles;
      if (!jobProfiles) return;
      for (const jobProfile of jobProfiles) {
        const updateJobProfile = {
          name: jobProfile.name,
          isActive: jobProfile.isActive
        } as UpdateJobsProfileDto;
        await JobsProfileService.updateJobsProfile(jobProfile.id, updateJobProfile);
      }
      toast.success('Cargos de trabalho atualizados com sucesso!');
      history.push(Routes.LIST_JOBS);
    } catch (error) {
      handleErrors(error);
    }
  };

  const convertToRomanAlgoritm = (num: number) => {
    return num === 1
      ? 'I'
      : num === 2
      ? 'II'
      : num === 3
      ? 'III'
      : num === 4
      ? 'IV'
      : num === 5
      ? 'V'
      : null;
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorReponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorReponse);
    toast.error(error);
  };

  useEffect(() => {
    setJobProfileGroup(location.state as JobProfileGroup);
    // handleEditJobNameAndStatus();
    // handleAddNewLevel();
  }, [location.state]);

  useEffect(() => {
    return;
  }, [jobProfileGroup]);

  return (
    <div className="flex flex-col pt-16">
      <div className="flex flex-col justify-between">
        <div className="flex flex-row justify-between items-center mb-8">
          <h1 className="text-xl font-semibold justify-start text-teal-600 lg:text-3xl">
            Editar Cargo de Trabalho
          </h1>
          <div
            className="hover:bg-gray-100 rounded flex flex-row items-center justify-end cursor-pointer mx-1 px-3 py-1"
            onClick={() => history.goBack()}
          >
            <ArrowLeftIcon className="w-3 h-3 mr-1" />
            <span className="text-xs">Voltar</span>
          </div>
        </div>
        <Form
          onSubmit={handleEditJobNameAndStatus}
          ref={formRef}
          className="flex flex-col justify-center"
        >
          <div className="gap-4">
            <div className="flex flex-row items-center gap-3">
              <div className="flex gap-3 flex-1">
                <span className="text-2xl">{jobProfileGroup?.name}</span>
                <PencilIcon
                  className="w-6 h-6 text-slate-600 hover:text-opacity-70 cursor-pointer"
                  onClick={() => setIsOpen(true)}
                />
              </div>
              <div>
                <Button
                  variant="default"
                  type="button"
                  disabled={jobProfileGroup?.jobProfiles.length === 5}
                  onClick={handleAddNewLevel}
                >
                  Adicionar Nível
                </Button>
              </div>
            </div>
            <div className="flex flex-col justify-center gap-4 p-6 py-7">
              {jobProfileGroup?.jobProfiles.map((job: JobsProfile, index: number) => (
                <div
                  className="flex flex-row justify-between items-center align-middle gap-4 py-2 border-b px-2 border-gray-200"
                  key={index}
                >
                  <div className="gap-2">
                    <span>{job.name} </span>
                    <span className="font-mono">{convertToRomanAlgoritm(job.level)}</span>
                  </div>
                  <div className="text-sm font-medium flex items-center gap-2">
                    <span className="whitespace-nowrap border-y-0">Ativo:</span>
                    <Switch
                      checked={job.isActive}
                      onChange={handleSwitchChange(job.id)}
                      className={`${
                        job.isActive ? 'bg-teal-600' : 'bg-gray-600'
                      } inline-flex h-4 w-10 items-center rounded-full`}
                    >
                      <span
                        className={`${
                          job.isActive ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </div>
                </div>
              ))}
              <div className={`flex justify-end gap-8 py-2 p-4 items-center`}></div>
            </div>
          </div>
        </Form>
        <div className="flex justify-end">
          <Button variant="primary" type="button" onClick={handleEditJobNameAndStatus}>
            Salvar
          </Button>
        </div>
      </div>
      <CreateJobsProfileModal
        isOpen={isOpen}
        modalText={'Editar nome de Cargos de perfil'}
        onClose={() => setIsOpen(false)}
        onConfirm={handleConfirmEditNameJobProfiles}
        isEdit={true}
      />
    </div>
  );
};

export default EditJobsProfile;
