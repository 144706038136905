import React from 'react';
import ListCard from '../../../../../../../components/ListCard';
import Table, { Action } from '../../../../../../../components/Table';
import { Opportunity } from '../../../../models/Opportunity';

interface Props {
  opportunity?: Opportunity[];
  onClickEditOpportunity: (vagueId: string) => void;
  onClickPreviewOpportunity: (vagueId: string) => void;
  onClickTotalCandidates: (vagueId: string) => void;
}

const OpportunityTable = ({
  opportunity,
  onClickEditOpportunity,
  onClickPreviewOpportunity,
  onClickTotalCandidates
}: Props) => {
  const actions: Action[] = [
    {
      label: 'Visualizar',
      onClick: onClickPreviewOpportunity
    },
    {
      label: 'Editar',
      onClick: onClickEditOpportunity
    }
  ];

  const formatDatePeriod = (value: any) => {
    return value;
  };

  const translateStatus = (value: any) => {
    if (value) {
      return 'Ativo';
    } else {
      return 'Inativo';
    }
  };

  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            { columnName: 'Nº', key: 'index' },
            { columnName: 'Título', key: 'title' },
            { columnName: 'Tipo de Oportunidade', key: 'typeOpportunity.name' },
            { columnName: 'Local de Trabalho', key: 'workspaceOpportunity.name' },
            { columnName: 'Período', key: 'period', transform: (value) => formatDatePeriod(value) },
            { columnName: 'Status', key: 'isActive', transform: (value) => translateStatus(value) },
            {
              columnName: 'Candidatos',
              key: 'candidate.totalCandidates',
              onClickRow: (value) => {
                onClickTotalCandidates(value);
              },
              transform: (value) => {
                return value ? value : 0;
              }
            }
          ]}
          values={opportunity}
          menuItems={actions}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          items={[
            { label: 'Nº', key: 'index' },
            { label: 'Título', key: 'title' },
            { label: 'Tipo de Vaga', key: 'typeVague.name' },
            { label: 'Local de Trabalho', key: 'workspaceVague.name' },
            { label: 'Período', key: 'period', transform: (value) => formatDatePeriod(value) },
            { label: 'Status', key: 'isActive', transform: (value) => translateStatus(value) },
            {
              label: 'Candidatos',
              key: 'candidate.totalCandidates',
              onClickRow: (value) => {
                onClickTotalCandidates(value);
              },
              transform: (value) => {
                return value ? value : 0;
              }
            }
          ]}
          values={opportunity}
          menuItems={actions}
        />
      </div>
    </>
  );
};

export default OpportunityTable;
