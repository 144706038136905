import { Notifiable } from '../../../infra/Notifiable';
import { Validator } from '../../../infra/Validator';
import { WorkExperience } from '../models/WorkExperience';

export class CreateWorkExperienceContract extends Notifiable {
  private dto: WorkExperience;
  private validator: Validator;

  constructor(dto: WorkExperience) {
    super();
    this.dto = dto;
    this.validator = new Validator();
  }

  validate(): boolean {
    this.validateCompany();
    this.validateOccupation();
    this.validateExperiencePeriod();
    this.validateDescriptionActivities();

    this.addReports(this.validator.reports);
    return this.isValid();
  }

  private validateCompany() {
    const { company } = this.dto;
    this.validator.isRequired(company, 'company', 'O campo Empresa é de preenchimento obrigatório');
  }

  private validateOccupation() {
    const { occupation } = this.dto;
    this.validator.isRequired(
      occupation,
      'occupation',
      'O campo Cargo é de preenchimento obrigatório'
    );
  }

  private validateExperiencePeriod() {
    const { initialDate } = this.dto;
    this.validator.dateIsRequired(initialDate, 'initialDate', 'Data de início é obrigatório');
    this.validator.isValidDate(initialDate, 'initialDate', 'Data de início inválida');

    const { currentJob, finishDate } = this.dto;
    if (!currentJob && finishDate) {
      this.validator.dateIsRequired(finishDate, 'finishDate', 'Data de término é obrigatório');
      this.validator.isValidDate(finishDate, 'finishDate', 'Data de término invalida');
      this.validator.isDateGreaterOrEqualsThan(
        initialDate,
        finishDate,
        'finishDate',
        'Data de rescisão não pode ser igual ou maior que a data de contratação'
      );
    }
  }

  private validateDescriptionActivities() {
    const { description } = this.dto;
    this.validator.isRequired(
      description,
      'description',
      'O campo Descrição das atividades é de preenchimento obrigatório'
    );
  }
}
