import { useField } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import cmsService from '../../../../_shared/services/cms.service';

interface Props {
  name: string;
}

const PrivacyNoticeTermCheckbox = ({ name, ...rest }: Props) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState(false);
  const [privacyProtectionURL, setPrivacyProtectionURL] = useState('');
  const [privacyNoticeURL, setPrivacyNoticeURL] = useState('');

  useEffect(() => {
    const fetchLinks = async () => {
      const { privacyProtectionURL, privacyNoticeURL } = await cmsService.findLinks();
      setPrivacyProtectionURL(privacyProtectionURL);
      setPrivacyNoticeURL(privacyNoticeURL);
    };

    fetchLinks();
  }, []);

  const handleChange = (event: any) => {
    setValue(event.target.checked);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      setValue: (ref: any, value: any) => {
        setValue(value);
      }
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <label className="text-sm">
        <input
          className="accent-teal-600 mr-1"
          type="checkbox"
          defaultChecked={defaultValue}
          ref={inputRef}
          checked={value}
          onChange={handleChange}
          id={fieldName}
          {...rest}
        />
        Li e estou de acordo com o
        <a
          className="text-teal-600 font-medium mx-1"
          href={privacyNoticeURL}
          target="_blank"
          rel="noreferrer"
        >
          Aviso de privacidade
        </a>
        e
        <a
          className="text-teal-600 font-medium mx-1"
          href={privacyProtectionURL}
          target="_blank"
          rel="noreferrer"
        >
          Política de proteção de dados.
        </a>
      </label>

      {error && <span>{error}</span>}
    </div>
  );
};

export default PrivacyNoticeTermCheckbox;
