import { OptionSelect } from '../../../components/FormComponents/SelectForm';
import { Level } from '../enum/Level';

const levelDictionary = [
  { pt: 'I', key: 1, unavailable: true },
  { pt: 'II', key: 2, unavailable: false },
  { pt: 'III', key: 3, unavailable: false },
  { pt: 'IV', key: 4, unavailable: false },
  { pt: 'V', key: 5, unavailable: false }
];

export const translateLevel = (level: number) => {
  for (const levelItem of levelDictionary) {
    if (levelItem.key === level) {
      return levelItem;
    }
  }
};

export const getLevel = () => {
  const arrayLevel = Object.values(Level);
  return arrayLevel;
};

export const getLevelOptions = (isFilter?: boolean): OptionSelect[] => {
  const items = Object.values(Level).map((level) => {
    const levelMapped = translateLevel(Number(level));
    return {
      label: (levelMapped && levelMapped?.pt) || '',
      value: Number(level),
      unavailable: (levelMapped && levelMapped?.unavailable) || false
    };
  });
  if (isFilter) {
    items.unshift({ label: 'Nível', value: 0, unavailable: true });
  } else {
    items.unshift({ label: 'Selecione', value: 0, unavailable: true });
  }

  return items.filter((item) => item.value);
};
