import { FormHandles } from '@unform/core';
import React, { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as CandidatoPhoto } from '../../../../assets/candidato.svg';
import { LOCAL_KEY_OPPORTUNITY_ID } from '../../../_shared/constants/LocalStorage.constants';
import {
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { LoginCredentials, useAuth } from '../../contexts/AuthProvider';
import LoginForm from './components/LoginForm';

const Login = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();

  const handleLogin = async () => {
    try {
      const singInCredentials = formRef.current?.getData() as LoginCredentials;
      await signIn(singInCredentials);
    } catch (resultError) {
      const fieldsErrors = getFieldErrors(resultError);
      formRef.current?.setErrors(fieldsErrors);
      const resultErrorResponse = manageApiErrorResponse(resultError);
      const resultErrors = manageApiErrorMessages(resultErrorResponse);
      resultErrors.map((resultError) => toast.error(resultError));
    }
  };

  useEffect(() => {
    if (location.state) {
      localStorage.setItem(LOCAL_KEY_OPPORTUNITY_ID, JSON.stringify(location.state));
    } else {
      localStorage.removeItem(LOCAL_KEY_OPPORTUNITY_ID);
    }
  }, [location]);

  return (
    <div className="flex flex-col items-center w-full md:flex-row gap-2 py-8">
      <div className="lg:w-1/2 max-w-xl items-center flex flex-col">
        <div className="mb-12 sm:mt-8">
          <h1 className="text-3xl font-semibold text-teal-600 mb-8">INOVVATI Carreiras</h1>

          <p className="mb-3 max-w-sm py-3 rounded-sm text-3xl font-semibold uppercase">
            Venha fazer parte da nossa equipe!
          </p>

          <p className="text-lg text-stone-700">
            Faça seu cadastro em alguma de nossas áreas de atuação que entraremos em contato caso
            esteja alinhado com nossas expectativas.
          </p>
        </div>

        <CandidatoPhoto className="w-60 h-60 mx-auto" />
      </div>
      <LoginForm />
    </div>
  );
};

export default Login;
