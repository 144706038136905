import { CheckIcon, PlusIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../../components/Button';
import DatepickerForm from '../../../../../components/FormComponents/DatepickerForm';
import InputForm from '../../../../../components/FormComponents/InputForm';
import SelectForm from '../../../../../components/FormComponents/SelectForm';
import { Report } from '../../../../../infra/Report';
import { getFieldErrorsInReport } from '../../../../_shared/helpers/handleApiErrorResponse';
import { getUniversityEducationOptions } from '../../../../_shared/helpers/UniversityEducationHelper';
import { CreateUniversityCourseContract } from '../../../contracts/CreateUniversityCourseContract';
import { UniversityCourse } from '../../../models/Scholarity';

const universityEducationTypeList = getUniversityEducationOptions();

type ConfirmProps = { universityCourse: UniversityCourse; isEdit: boolean };

interface Props {
  universityCourseToEdit?: UniversityCourse;
  onConfirm: (confirmProps: ConfirmProps) => void;
  onClear: () => void;
}

const UniversityEducationForm = ({ universityCourseToEdit, onConfirm, onClear }: Props) => {
  const [initialData, setInitialData] = useState<UniversityCourse>();
  const formRef = useRef<FormHandles>(null);

  const submitNewUniversityCourse = () => {
    try {
      const mainFormData = formRef.current?.getData();

      const universityCourse = {
        ...mainFormData,
        id: universityCourseToEdit?.id
      } as UniversityCourse;

      const resultContract = new CreateUniversityCourseContract(universityCourse);
      resultContract.validate();
      if (!resultContract.isValid()) {
        throw resultContract.reports;
      }

      const isEditUniversityCourse = !!universityCourseToEdit;

      onConfirm({
        universityCourse: universityCourse,
        isEdit: isEditUniversityCourse
      });
      clearForm();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrorsInReport(resultError as Report[]);
    formRef.current?.setErrors(fieldsErrors);
  };

  const clearForm = () => {
    formRef.current?.reset();
    setInitialData(undefined);
    onClear();
  };

  useEffect(() => {
    if (universityCourseToEdit) {
      const universityTypeSelected = universityEducationTypeList.find(
        (universityEducationType) => universityEducationType.value === universityCourseToEdit.type
      );
      formRef.current?.setData(universityCourseToEdit);
      formRef.current?.setFieldValue('type', universityTypeSelected);
      setInitialData(universityCourseToEdit);
    } else {
      clearForm();
    }
  }, [universityCourseToEdit]);

  return (
    <div className="flex flex-col border border-gray-300 bg-gray-100 bg-opacity-75 z-0 rounded-sm w-full px-5 pb-5">
      <div className="w-full gap-4 mb-6 mt-1">
        <Form
          ref={formRef}
          initialData={universityCourseToEdit}
          onSubmit={submitNewUniversityCourse}
        >
          <SelectForm
            name="type"
            label="Tipo de ensino superior*"
            options={universityEducationTypeList}
          />
          <InputForm type="text" name="course" label="Curso*" />
          <div className="flex flex-col mt-3">
            <span className="mb-1 text-sm font-medium text-stone-800">Período*</span>
            <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
              <DatepickerForm name="startDate" label="Inicío*" />
              <DatepickerForm name="endDate" label="Término (Previsão)*" />
            </div>
          </div>
          <InputForm type="text" name="teachingEntity" label="Entidade de Ensino" />
        </Form>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-2 justify-end">
        <Button type="button" variant="default" onClick={clearForm}>
          Limpar
        </Button>
        <Button type="button" variant="primary" onClick={submitNewUniversityCourse}>
          {initialData ? (
            <div className="flex flex-row gap-2 items-center justify-center">
              <CheckIcon className="text-white h-6 w-6" />
              <span>Salvar</span>
            </div>
          ) : (
            <div className="flex flex-row gap-2 items-center justify-center">
              <PlusIcon className="text-white h-6 w-6" />
              <span>Adicionar curso</span>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

export default UniversityEducationForm;
