import { DocumentTextIcon, EyeIcon, TrashIcon } from '@heroicons/react/solid';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import Button from '../../../../../components/Button';
import { OptionSelect } from '../../../../../components/FormComponents/SelectForm';
import ListCard from '../../../../../components/ListCard';
import Table from '../../../../../components/Table';
import User from '../../../../users/models/User';
import { translateArea } from '../../../../_shared/helpers/AreaHelper';
import { formatDateString } from '../../../../_shared/helpers/FormatDateHelper';
import DeleteAlertComponent from './DeleteAlertComponent';
import AutoCompleteJobProfile from './JobProfileFilter';
import StatusComponent from './StatusComponent';

type props = {
  users?: User[];
  onClickDownload: (item: string) => void;
  onClickDownloadPdf: (value: any) => void;
  downloadCandidate?: string;
  onClickChangeStatus?: (userId: string, status: string) => void;
  onClickDeleteCandidate: (item: string) => void;
  onChangeHiringArea: (value: any, userId: string) => void;
  optionsJobProfile: OptionSelect[];
};

const CareersTable = ({
  users = [],
  onClickDownload,
  onClickDownloadPdf,
  onClickChangeStatus,
  onClickDeleteCandidate,
  onChangeHiringArea,
  optionsJobProfile
}: props) => {
  const actionsButton = (itemActive: any) => {
    return (
      <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
        <Button styles="w-full" variant="primary" onClick={() => onClickDownload(itemActive.id)}>
          Download
        </Button>
        <Button
          styles="w-full"
          variant="error"
          onClick={() => onClickDeleteCandidate(itemActive.id)}
        >
          Excluir
        </Button>
      </div>
    );
  };

  const handleClickChangeStatus = (userId: string, status: string) => {
    if (onClickChangeStatus) {
      onClickChangeStatus(userId, status);
    }
  };

  const handleChangeHiringArea = (value: any, userId?: string) => {
    console.log(userId);

    if (userId) onChangeHiringArea(value, userId);
  };

  const formatName = (name: string) => {
    return <div className="capitalize">{name}</div>;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [users]);

  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            {
              columnName: 'Nome',
              key: 'name',
              transform: (columnValue) => formatName(columnValue)
            },
            {
              columnName: 'Criado em',
              key: 'createdAt',
              transform: (columnValue) => formatDateString(columnValue)
            },

            {
              columnName: 'Área de interesse',
              key: 'candidate.area',
              transform: (columnValue) => translateArea(columnValue)
            },
            {
              columnName: 'Perfil de contratação',
              key: 'candidate.hiringProfile',
              component: (columnValue, currentItem) => (
                <div className="w-[200px]">
                  <AutoCompleteJobProfile
                    onChange={handleChangeHiringArea}
                    options={optionsJobProfile}
                    userActive={currentItem}
                    itemActive={columnValue}
                    placeHolder={'Não avaliado'}
                  />
                </div>
              )
            },
            {
              columnName: 'Status',
              key: 'candidate.status',
              component: (columnValue, currentItem) => (
                <StatusComponent
                  status={columnValue}
                  onClickChangeStatus={handleClickChangeStatus}
                  itemActive={currentItem}
                />
              )
            },
            {
              columnName: '',
              key: 'itsCloseToExpirationDate',
              component: (columnValue, currentItem) =>
                columnValue === true && <DeleteAlertComponent candidate={currentItem} />
            },
            {
              columnName: '',
              key: '',
              component: (columnValue, currentItem: User) => (
                <div className="flex justify-end space-x-2 gap-1">
                  <TrashIcon
                    className="text-red-600 w-5 hover:text-red-500 transition-all duration-300 cursor-pointer"
                    onClick={() => onClickDeleteCandidate(currentItem.id)}
                  />
                  <EyeIcon
                    className="w-5 hover:text-gray-600 transition-all duration-300 cursor-pointer"
                    onClick={() => onClickDownload(currentItem.id)}
                  />
                  <DocumentTextIcon
                    className={`w-5 transition-all duration-300 ${
                      currentItem.candidate?.curriculumPdf
                        ? 'cursor-pointer hover:text-gray-600'
                        : 'text-gray-200'
                    }`}
                    onClick={() => onClickDownloadPdf(currentItem)}
                  />
                </div>
              )
            }
          ]}
          values={users}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          items={[
            { label: 'Nome', key: 'name' },
            {
              label: 'Área de interesse',
              key: 'candidate.area',
              transform: (value) => translateArea(value)
            },
            { label: 'E-mail', key: 'email' },
            {
              label: 'Criado em',
              key: 'createdAt',
              transform: (value) => formatDateString(value)
            },
            {
              label: 'Perfil de contratação',
              key: 'candidate.hiringProfile',
              component: (columnValue, currentItem) => (
                <AutoCompleteJobProfile
                  onChange={handleChangeHiringArea}
                  options={optionsJobProfile}
                  userActive={currentItem}
                  itemActive={columnValue}
                  placeHolder={'Não avaliado'}
                />
              )
            },
            {
              label: 'Status',
              key: 'candidate.status',
              component: (value, itemActive) => (
                <StatusComponent
                  status={value}
                  onClickChangeStatus={handleClickChangeStatus}
                  itemActive={itemActive}
                />
              )
            },
            {
              label: '',
              key: 'itsCloseToExpirationDate',
              component: (value, itemActive) =>
                value === true && <DeleteAlertComponent candidate={itemActive} direction="right" />
            }
          ]}
          values={users}
          actionsButton={actionsButton}
        />
      </div>
    </>
  );
};
export default CareersTable;
