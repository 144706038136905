import { OptionSelect } from '../../../components/FormComponents/SelectForm';
import { Area } from '../../careers/enum/Area';

const areaDictionary = [
  { pt: 'Administrativo', key: 'administrative' },
  { pt: 'Comercial', key: 'commercial' },
  { pt: 'Desenvolvimento', key: 'development' },
  { pt: 'Digitalização', key: 'scanning' },
  { pt: 'Infraestrutura', key: 'infrastructure' },
  { pt: 'Recepção', key: 'reception' },
  { pt: '(RH) Recursos humanos', key: 'human resources' },
  { pt: 'Suporte técnico', key: 'technical support' },
  { pt: 'Serviços gerais', key: 'general services' }
];

export const translateArea = (area?: string) => {
  for (const areaItem of areaDictionary) {
    if (areaItem.key === area) return areaItem.pt;
  }
  return '';
};

export const getAreaOptions = (isFilter?: boolean): OptionSelect[] => {
  const items = Object.values(Area).map((area) => ({
    label: translateArea(area),
    value: area as string,
    unavailable: false
  }));
  if (isFilter) {
    items.unshift({ label: 'Área', value: '', unavailable: true });
  } else {
    items.unshift({ label: 'Selecione', value: '', unavailable: true });
  }
  return items;
};
