import { Report } from './Report';

export class Validator {
  public reports: Report[];

  constructor() {
    this.reports = [];
  }

  public isRequired(value: string, name: string, message: string) {
    if (!value || value.length <= 0) {
      this.reports.push({ name, message });
    }
  }

  public isUndefined(value: any, name: string, message: string) {
    if (value === undefined) {
      this.reports.push({ name, message });
    }
  }

  public isGreaterThan(valuea: number, valueb: number, name: string, message: string) {
    if (valuea > valueb) {
      this.reports.push({ name, message });
    }
  }

  public isGreaterOrEqualsThan(valuea: number, valueb: number, name: string, message: string) {
    if (valuea >= valueb) {
      this.reports.push({ name, message });
    }
  }

  public isDateGreaterOrEqualsThan(dateA: Date, dateB: Date, name: string, message: string) {
    if (dateA.getTime() >= dateB.getTime()) {
      this.reports.push({ name, message });
    }
  }

  public isLessThan(valuea: number, valueb: number, name: string, message: string) {
    if (valuea < valueb) {
      this.reports.push({ name, message });
    }
  }

  public hasMinLen(value: string, min: number, name: string, message: string) {
    if (!value || value.length < min) {
      this.reports.push({ name, message });
    }
  }

  public hasMaxLen(value: any, max: number, name: string, message: string) {
    if (!value || value.length > max) {
      this.reports.push({ name, message });
    }
  }

  public isValidEmail(value: string, name: string, message: string) {
    const REGULAR_EXPRESSION = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (!REGULAR_EXPRESSION.test(value)) {
      this.reports.push({ name, message });
    }
  }

  public isValidUrl(value: string, name: string, message: string) {
    const REGULAR_EXPRESSION = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
    if (!REGULAR_EXPRESSION.test(value)) {
      this.reports.push({ name, message });
    }
  }

  public isValidNumber(value: any, name: string, message: string) {
    if (isNaN(Number(value))) {
      this.reports.push({ name, message });
    }
  }

  public isValidDate(date: string | Date, name: string, message: string) {
    const parseDate = new Date(date);
    const isInvalidDate = isNaN(parseDate.getTime());
    if (isInvalidDate) {
      this.reports.push({ name, message });
    }
  }

  public dateIsRequired(date: Date, name: string, message: string) {
    if (!date || isNaN(date.getTime())) this.reports.push({ name, message });
  }
}
