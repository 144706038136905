import { Switch } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../../components/Button';
import DatepickerForm from '../../../../../components/FormComponents/DatepickerForm';
import InputForm from '../../../../../components/FormComponents/InputForm';
import SelectForm, { OptionSelect } from '../../../../../components/FormComponents/SelectForm';
import TextEditor from '../../../../../components/TextEditor';
import Routes from '../../../../_shared/constants/Routes.enum';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../_shared/helpers/handleApiErrorResponse';
import OccupationAreaService from '../../../OccupationArea/service/OccupationAreaService';
import TypeOpportunityService from '../../../TypeOpportunity/service/TypeOpportunityService';
import WorkspaceService from '../../../Workspace/service/WorkspaceService';
import { CreateOpportunityDto } from '../../dtos/CreateOpportunityDto';
import { EditOpportunityDto } from '../../dtos/EditOpportunity';
import { Opportunity } from '../../models/Opportunity';
import OpportunityService from '../../service/OpportunityService';

type ParamsProps = { id: string };

const RegistrationOpportunity = () => {
  const formRef = useRef<FormHandles>(null);
  const [enabled, setEnabled] = useState(false);
  const [optionsTypeOpportunity, setOptionsTypeOpportunity] = useState<OptionSelect[]>([]);
  const [optionsWorkspace, setOptionsWorkspace] = useState<OptionSelect[]>([]);
  const [optionsOccupationArea, setOptionsOccupationArea] = useState<OptionSelect[]>([]);
  const [descriptionOpportunity, setDescriptionOpportunity] = useState<string>('');
  const [descriptionOpportunityEdit, setDescriptionOpportunityEdit] = useState<string>('');
  const [initialDataValue, setInitialDataValue] = useState<Opportunity>();
  const { id } = useParams<ParamsProps>();
  const history = useHistory();

  const getOptionsTypeOpportunity = async () => {
    const { results: typesOpportunities } = await TypeOpportunityService.paginateTypeOpportunity({
      limit: 999,
      isActive: true
    });
    if (typesOpportunities.length > 0) {
      const optionsTypesOpportunity = typesOpportunities.map((typeOpportunity) => ({
        value: typeOpportunity.id,
        label: typeOpportunity.name
      }));
      setOptionsTypeOpportunity(optionsTypesOpportunity);
    }
  };

  const getOptionsWorkspace = async () => {
    const { results: workspaces } = await WorkspaceService.paginateWorkspace({
      limit: 999,
      isActive: true
    });
    if (workspaces.length > 0) {
      const optionsWorkspacesOpportunity = workspaces.map((workspace) => ({
        value: workspace.id,
        label: workspace.name
      }));
      setOptionsWorkspace(optionsWorkspacesOpportunity);
    }
  };

  const getOptionsOccupationArea = async () => {
    const { results: occupationAreas } = await OccupationAreaService.paginateOccupationArea({
      limit: 999,
      isActive: true
    });
    if (occupationAreas.length > 0) {
      const optionsOccupationAreas = occupationAreas.map((occupationArea) => ({
        value: occupationArea.id,
        label: occupationArea.name
      }));
      setOptionsOccupationArea(optionsOccupationAreas);
    }
  };

  const handleAddNewOpportunity = async () => {
    try {
      const mainFormData = formRef.current?.getData();

      if (id) {
        const editOpportunity = {
          ...mainFormData,
          isActive: enabled,
          descriptionOpportunity: descriptionOpportunity
        } as EditOpportunityDto;

        const result = await OpportunityService.editOpportunity(id, editOpportunity);

        toast.success(result.message);
      } else {
        const newOpportunityToCreate = {
          ...mainFormData,
          isActive: enabled,
          descriptionOpportunity: descriptionOpportunity
        } as CreateOpportunityDto;

        const result = await OpportunityService.newOpportunity(newOpportunityToCreate);

        toast.success(result.message);
      }
      history.push(Routes.LIST_OPPORTUNITY);

      clearForm();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorReponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorReponse);
    toast.error(error);
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  useEffect(() => {
    getOptionsTypeOpportunity();
    getOptionsWorkspace();
    getOptionsOccupationArea();
  }, []);

  const loadOpportunity = async () => {
    try {
      const result = await OpportunityService.getOpportunity(id);
      const opportunity: Opportunity = {
        ...result.data,
        startDateOpportunity: new Date(result.data.startDateOpportunity),
        endDateOpportunity: new Date(result.data.endDateOpportunity)
      };
      formRef.current?.setData(opportunity);
      formRef.current?.setFieldValue(
        'typeOpportunityId',
        optionsTypeOpportunity.find(
          (typeOpportunity) => typeOpportunity.value === opportunity.typeOpportunity.id
        )
      );
      formRef.current?.setFieldValue(
        'workspaceOpportunityId',
        optionsWorkspace.find(
          (workspaceOpportunity) =>
            workspaceOpportunity.value === opportunity.workspaceOpportunity.id
        )
      );
      formRef.current?.setFieldValue(
        'occupationAreaId',
        optionsOccupationArea.find(
          (occupationArea) => occupationArea.value === opportunity.occupationArea.id
        )
      );
      setInitialDataValue(opportunity);
      setEnabled(opportunity.isActive);
      setDescriptionOpportunityEdit(opportunity.descriptionOpportunity);
    } catch (error) {
      handleErrors(error);
    }
  };

  useEffect(() => {
    if (id) loadOpportunity();
  }, [optionsOccupationArea]);

  return (
    <div className="flex flex-col pt-16">
      <div className="flex flex-wrap justify-between">
        <h1 className="text-3xl font-semibold text-teal-600 mb-8">
          {id ? 'Editar Oportunidade' : 'Cadastrar Oportunidade'}
        </h1>
      </div>

      <div className="row">
        <div className="flex flex-wrap justify-between">
          <Form
            ref={formRef}
            onSubmit={handleAddNewOpportunity}
            className="flex justify-center w-full"
          >
            <div className="flex flex-col lg:flex-row w-full gap-4">
              <div className="border-dashed border border-gray-300 w-full lg:w-6/12">
                <div className="flex flex-col p-5">
                  <span className="text-base font-bold text-gray-600 mb-4">
                    Informe os requisitos da oportunidade
                  </span>
                  <div className="flex flex-col lg:flex-row justify-between">
                    <div className="flex flex-col w-full gap-3">
                      <InputForm type="text" name="title" label="Título" placeholder="" />
                      <SelectForm
                        name="typeOpportunityId"
                        label="Tipo de Oportunidade"
                        options={optionsTypeOpportunity}
                      />
                      <SelectForm
                        name="workspaceOpportunityId"
                        label="Local de Trabalho"
                        options={optionsWorkspace}
                      />
                      <SelectForm
                        name="occupationAreaId"
                        label="Áreas"
                        options={optionsOccupationArea}
                      />
                      <div className="gap-3 flex flex-col xl:flex-row">
                        <div className="w-full">
                          <DatepickerForm name="startDateOpportunity" label="Período" />
                        </div>
                        <div className="w-full">
                          <DatepickerForm name="endDateOpportunity" label="Até" />
                        </div>
                      </div>
                      <div className="pt-3 text-sm font-medium flex items-center gap-2">
                        <span className="whitespace-nowrap border-y-0">Ativo:</span>
                        <Switch
                          checked={enabled}
                          onChange={setEnabled}
                          className={`${
                            enabled ? 'bg-teal-600' : 'bg-gray-600'
                          } inline-flex h-4 w-10 items-center rounded-full`}
                        >
                          <span
                            className={`${
                              enabled ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                          />
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-dashed border border-gray-300 p-5 w-full">
                <div className="flex">
                  <span className="text-base font-bold text-gray-600 mb-4">
                    Descrição da oportunidade
                  </span>
                </div>
                <TextEditor
                  setContent={(value) => setDescriptionOpportunity(value)}
                  placeholder={'Descreva aqui informações da oportunidade a ser publicada.'}
                  textEditorValue={descriptionOpportunityEdit}
                />
              </div>
            </div>
          </Form>
        </div>
        <div className="flex justify-end gap-4 py-6">
          <Button variant="default" onClick={() => history.goBack()}>
            Cancelar
          </Button>
          <Button variant="primary" type="button" onClick={handleAddNewOpportunity}>
            Salvar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationOpportunity;
