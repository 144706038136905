import queryString from 'query-string';
import api from '../../../_shared/services/api';
import { Paginate, Result } from '../../../_shared/types/api.types';
import { CreateWorkspaceDto } from '../dtos/CreateWorkspaceDto';
import { PaginateWorkspaceDto } from '../dtos/PaginateWorkspaceDto';
import { ResultWorkspaceDto } from '../dtos/ResultWorkspaceDto';
import { UpdateWorkspaceDto } from '../dtos/UpdateWorkspaceDto';
import { Workspace } from '../models/Workspace';

class WorkspaceService {
  public async newWorkspace(
    createWorkspaceDto: CreateWorkspaceDto
  ): Promise<Result<ResultWorkspaceDto>> {
    const response = await api.post('/workspace', createWorkspaceDto);
    return response.data;
  }

  public async update(
    workspaceId: number,
    updateWorkspaceDto: UpdateWorkspaceDto
  ): Promise<Result<void>> {
    const response = await api.put(`/workspace/${workspaceId}`, updateWorkspaceDto);
    return response.data;
  }

  public async getWorkspace(workspaceId: number): Promise<Result<Workspace>> {
    const response = await api.get(`/workspace/${workspaceId}`);
    return response.data;
  }

  public async paginateWorkspace({
    ...paginateWorkspace
  }: PaginateWorkspaceDto): Promise<Paginate<Workspace>> {
    const queryParams = queryString.stringify(paginateWorkspace);
    const response = await api.get(`/workspace?${queryParams}`);
    return response.data.data;
  }
}

export default new WorkspaceService();
