import { DotsVerticalIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import Dropdown from '../Dropdown';
import { Action } from '../Table';

export interface ItemsProps {
  label: string;
  key: string;
  transform?: (value: any) => any;
  component?: (value: any, itemActive: any) => any;
  onClickRow?: (value: any) => void;
}

interface Props {
  items: ItemsProps[];
  values?: any[];
  menuItems?: Action[];
  actionsButton?: (itemActive: any) => any;
}

const ListCard = ({ items, values, menuItems, actionsButton }: Props) => {
  const [itemActive, setItemActive] = useState<any>();

  const handleClickItemMenu = (onClick: (currentSelected: any) => void) => {
    if (itemActive) onClick(itemActive.id);
  };

  const handleClickOpenMenu = (item: any) => {
    setItemActive(item);
  };

  const getValueByItem = (item: ItemsProps, value: any) => {
    const keys = item.key.split('.');
    let valueToReturn: any;

    if (keys.length > 1) {
      let currentValue: any;
      for (const key of keys) {
        currentValue = !currentValue ? value[key] : currentValue[key];
      }
      valueToReturn = currentValue;
    } else {
      valueToReturn = value[item.key];
    }

    if (item.transform) {
      valueToReturn = item.transform(valueToReturn);
    }

    if (item.component) {
      valueToReturn = item.component(valueToReturn, value);
    }

    return valueToReturn;
  };

  const handleClickItem = (value: any, item: ItemsProps) => {
    if (item.onClickRow) item.onClickRow(value);
  };

  return (
    <div className="relative flex flex-col gap-2 w-full">
      {values?.map((value, index) => (
        <div key={index} className="relative flex flex-col border-2 rounded border-stone-300">
          <div>
            {items.map((item, index) => (
              <div className="p-3" key={index}>
                <label className="font-medium">{item.label}</label>
                <div className="text-gray-500" onClick={() => handleClickItem(value, item)}>
                  {getValueByItem(item, value)}
                </div>
              </div>
            ))}
            {actionsButton && <div className="p-3">{actionsButton(value)}</div>}
          </div>
          <div className="absolute top-0 right-0 p-4 mr-1 mt-1">
            {menuItems && (
              <Dropdown
                rowValue={value}
                menuItens={menuItems}
                onClick={handleClickItemMenu}
                size="default"
              >
                <DotsVerticalIcon
                  className="w-5 h-5 cursor-pointer text-black"
                  onClick={() => handleClickOpenMenu(value)}
                />
              </Dropdown>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListCard;
