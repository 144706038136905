export const getVerifyCode = (CURRENT_URL: string) => {
  const splitUrl = CURRENT_URL.split('=');
  if (splitUrl.length > 1) {
    const indexToGetVerifyCode = splitUrl.length - 1;
    const verifyCode = splitUrl[indexToGetVerifyCode];
    return verifyCode;
  } else {
    return;
  }
};
