import { BaseApiResponse, Link, fetchApi, getFileFullURL } from './apiCMS';

type Links = {
  privacyNoticeURL: string;
  privacyProtectionURL: string;
};

class CMSService {
  async findLinks(): Promise<Links> {
    const linksResponse = await fetchApi<BaseApiResponse<Link>>('/link', { populate: '*' });
    const { privacyNotice, privacyPolicy } = linksResponse.data.attributes;
    const privacyNoticeURL = getFileFullURL(privacyNotice.data.attributes);
    const privacyProtectionURL = getFileFullURL(privacyPolicy.data.attributes);

    return { privacyNoticeURL, privacyProtectionURL: privacyProtectionURL };
  }
}

export default new CMSService();
