import { OptionSelect } from '../../../components/FormComponents/SelectForm';
import { Status } from '../../careers/enum/Status';

const statusDictionary = [
  { pt: 'Selecionado', key: 'selected' },
  { pt: 'Apto', key: 'apt' },
  { pt: 'Inapto', key: 'inapt' },
  { pt: 'Não avaliado', key: 'not rated' },
  { pt: 'Não selecionado', key: 'not selected' }
];

export const translateStatus = (status?: string) => {
  for (const statusItem of statusDictionary) {
    if (statusItem.key === status) return statusItem.pt;
  }
  if (status) return status;
  return '';
};

export const getStatus = () => {
  const arrayStatus = Object.values(Status);
  return arrayStatus;
};

export const getStatusOptions = (isFilter?: boolean): OptionSelect[] => {
  const items = Object.values(Status).map((status) => ({
    label: translateStatus(status),
    value: status as string,
    unavailable: false
  }));
  if (isFilter) {
    items.unshift({ label: 'Status', value: '', unavailable: true });
  } else {
    items.unshift({ label: 'Selecione', value: '', unavailable: true });
  }
  return items;
};
