import { SearchIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import debounceEvent from '../../../../_shared/helpers/debounce';
import Search from '../../../../careers/pages/ListCareers/components/Search';
import { ListJobsProfileDto } from '../../../dtos/listJobsProfileDto';

type Props = { onChange: (filterActive: ListJobsProfileDto) => void };

const FilterJobsProfile = ({ onChange }: Props) => {
  const [filterValue, setFilterValue] = useState<ListJobsProfileDto>({});

  const handleChangeSearchValue = async (searchValue: string) => {
    setFilterValue((old) => ({ ...old, page: 1, search: searchValue }));
  };

  useEffect(() => {
    onChange(filterValue);
  }, [filterValue]);

  return (
    <div className="flex flex-row items-center grow bg-white w-96 rounded px-2">
      <SearchIcon className="w-5 h-5 text-gray-500" />
      <Search
        onChange={debounceEvent(handleChangeSearchValue, 1000)}
        initValue={filterValue.search}
      />
    </div>
  );
};

export default FilterJobsProfile;
