import { XIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../../../components/Button';
import InputForm from '../../../../../components/FormComponents/InputForm';
import Routes from '../../../../_shared/constants/Routes.enum';
import {
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../../_shared/helpers/handleApiErrorResponse';
import { LoginCredentials, useAuth } from '../../../contexts/AuthProvider';

type LoginProps = {
  isModal?: boolean;
  onClick?: () => void;
};

const LoginForm = ({ isModal, onClick }: LoginProps) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { signIn } = useAuth();

  const handleLogin = async () => {
    try {
      const singInCredentials = formRef.current?.getData() as LoginCredentials;
      await signIn(singInCredentials);
    } catch (resultError) {
      const fieldsErrors = getFieldErrors(resultError);
      formRef.current?.setErrors(fieldsErrors);
      const resultErrorResponse = manageApiErrorResponse(resultError);
      const resultErrors = manageApiErrorMessages(resultErrorResponse);
      resultErrors.map((resultError) => toast.error(resultError));
    }
  };

  return (
    <div
      className={`${isModal ? 'p-6' : 'lg:w-1/2 xl:pl-10 px-0 py-14'} w-full flex flex-col gap-4`}
    >
      {isModal && (
        <div className="flex justify-end">
          <XIcon className="h-5 w-5 hover:text-gray-500 cursor-pointer" onClick={onClick} />
        </div>
      )}
      <div className={`${!isModal && 'border-[1px]'} rounded  p-4`}>
        <p className="text-xl font-medium">Novo por aqui?</p>
        <p className="text-md mb-5">Clique no botão abaixo e faça seu cadastro</p>

        <Button variant="primary" onClick={() => history.push(Routes.REGISTER_CAREERS)}>
          Cadastre-se
        </Button>
      </div>
      {!isModal && (
        <div className="rounded border-[1px] p-4">
          <p className="text-xl font-medium">Oportunidades</p>
          <p className="text-md mb-5">Confira nossas vagas disponíveis aqui</p>
          <Button variant="primary" onClick={() => history.push(Routes.DISCLOSE_OPPORTUNITIES)}>
            Acesse já
          </Button>
        </div>
      )}
      <div className={`${isModal ? 'border-t-[1px]' : 'border-[1px]'} rounded  p-4`}>
        <Form ref={formRef} onSubmit={handleLogin}>
          <p className="text-xl font-medium mb-5">Entrar</p>
          <div className="mb-4 ">
            <InputForm name="email" type="email" label="E-mail" placeholder="email@example.com" />
            <div className="mb-4"></div>
            <InputForm name="password" type="password" label="Senha" placeholder="Senha" />
          </div>
          <div className="flex justify-between items-center mb-4 flex-wrap">
            <Link to={Routes.FORGOT_PASSWORD} className="text-teal-600">
              Esqueceu a senha ?
            </Link>
            <Button variant="primary">Acessar</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
