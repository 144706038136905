import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import React from 'react';
import { convertDateToString } from '../../../../_shared/helpers/FormatDateHelper';
import { translateUniversityEducation } from '../../../../_shared/helpers/UniversityEducationHelper';
import { UniversityCourse } from '../../../models/Scholarity';

interface Props {
  universityCourse: UniversityCourse;
  onClickEditItem: (item: any) => void;
  onClickRemoveItem: (item: any) => void;
}

const UniversityEducationItem = ({
  universityCourse,
  onClickEditItem,
  onClickRemoveItem
}: Props) => {
  return (
    <div className="flex flex-col border border-gray-300 rounded-sm w-full p-4 relative">
      <div className="w-full gap-1">
        <span className="text-base font-medium">
          {translateUniversityEducation(universityCourse.type)}
        </span>
        <div className="text-sm mb-2">
          <p>{universityCourse.course}</p>
          <p>{universityCourse.teachingEntity}</p>
        </div>
        <span className="text-xs">
          <p>
            Inicio: {convertDateToString(new Date(universityCourse.startDate))} - Término:{' '}
            {convertDateToString(new Date(universityCourse.endDate))}{' '}
          </p>
        </span>
      </div>
      <div className="flex flex-grow absolute top-3 right-3 items-center gap-2">
        <div className="group">
          <div
            className="p-1 group-hover:bg-red-500 rounded transition duration-700 ease-in-out cursor-pointer"
            onClick={onClickRemoveItem}
          >
            <TrashIcon className="h-5 w-5 group-hover:text-red-50 text-red-600 transition duration-700 ease-in-out" />
          </div>
        </div>
        <div className="group">
          <div
            className="p-1 group-hover:bg-gray-400 rounded transition duration-700 ease-in-out cursor-pointer"
            onClick={onClickEditItem}
          >
            <PencilIcon className="h-5 w-5 group-hover:text-gray-50 text-teal-600 transition duration-700 ease-in-out " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityEducationItem;
