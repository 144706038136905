import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import { useField } from '@unform/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';

export type OptionSelect = { value: any; label: string; unavailable?: boolean };

interface Props {
  name: string;
  label?: string;
  options: OptionSelect[];
  onChangeValue?: (value: any) => void;
}

type SelectProps = JSX.IntrinsicElements['select'] & Props;

const SelectForm = ({ name, label, options, onChangeValue, ...rest }: SelectProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, defaultValue = '', registerField, error, clearError } = useField(name);
  const [valueSelect, setValueSelect] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => {
        return valueSelect?.value;
      },
      setValue: (ref: any, newValue: any) => {
        if (newValue) {
          setValueSelect(newValue);
        } else {
          setValueSelect(defaultValue);
        }
      },
      clearValue: () => {
        setValueSelect('');
        clearError();
      }
    });
  }, [fieldName, registerField, valueSelect, setValueSelect]);

  useEffect(() => {
    clearError();
    if (onChangeValue && valueSelect) onChangeValue(valueSelect.value);
  }, [valueSelect]);

  return (
    <div className={`flex flex-col mt-2 text-sm ${rest.className || ''}`}>
      <Listbox value={valueSelect} onChange={setValueSelect} disabled={rest.disabled}>
        {/* <div className="relative mt-3 z-auto"> */}
        <Listbox.Label className={`py-1 font-medium ${error ? 'text-red-500' : 'text-stone-800'}`}>
          {label}
        </Listbox.Label>
        <div
          className={`relative border rounded ${
            error
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
              : 'text-gray-500 border-gray-200 focus:border-teal-600 focus:ring-teal-600'
          }`}
        >
          <Listbox.Button
            className={`flex justify-between items-center group rounded focus:ring-1 p-2 font-sans w-full focus:outline-none bg-white ${
              error
                ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-200 focus:border-teal-600 focus:ring-teal-600 active:border-teal-600'
            }`}
          >
            <span
              className={`block truncate font-semibold ${
                error ? 'text-red-500' : 'text-stone-500'
              }`}
            >
              {valueSelect.label || 'Selecione'}
            </span>
            <span className="inset-y-0 flex pr-2 pointer-events-none items-center">
              <SelectorIcon
                className={`w-5 h-5 ${error ? 'text-red-500' : 'text-gray-400'}`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-sm shadow-lg max-h-40 ring-teal-600 ring-1 ring-opacity-5 focus:outline-none font-sans">
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  className={({ active }) =>
                    `${active ? 'text-teal-600 bg-gray-100' : 'text-stone-700'}
                         select-none relative p-2 ${
                           option.unavailable
                             ? 'cursor-default text-gray-300'
                             : 'cursor-pointer hover:bg-gray-200 hover:bg-opacity-50'
                         } `
                  }
                  value={option}
                  disabled={option.unavailable}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium text-teal-600' : 'font-normal'
                        }  `}
                      >
                        {option.label}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
        <div className="h-4">
          {error && <span className="text-red-500 text-xs mt-1 ml-1">{error}</span>}
        </div>
      </Listbox>
    </div>
  );
};

export default SelectForm;
