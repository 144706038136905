import { Menu } from '@headlessui/react';
import React from 'react';
import { Action } from '../Table';

type props = {
  menuItens: Action[];
  onClick: (value: any, key?: string) => void;
  size: 'default' | 'medium';
  rowValue: any;
};

const Dropdown: React.FC<props> = ({ children, menuItens, onClick, size, rowValue }) => {
  const handleDisableOption = (key?: string) => {
    if (key) {
      return rowValue[key];
    }
    return false;
  };

  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className="flex items-center w-full justify-center rounded-md text-sm font-medium text-white">
        {children}
      </Menu.Button>
      <div className="relative">
        <Menu.Items className="origin-top-right absolute right-0 py-2 mt-2 w-28 bg-white rounded shadow-xl z-20">
          {menuItens.map((menuItem, index) => (
            <Menu.Item
              as="span"
              disabled={handleDisableOption(menuItem.keyValidadeOptionDisable)}
              key={index}
              onClick={() => onClick(menuItem.onClick)}
              className={`flex justify-between w-full px-2 py-1 text-sm font-semibold leading-5 text-gray-700 ${
                handleDisableOption(menuItem.keyValidadeOptionDisable)
                  ? 'opacity-50 cursor-default'
                  : 'cursor-pointer hover:bg-gray-200'
              }`}
            >
              {menuItem.label}
            </Menu.Item>
          ))}
        </Menu.Items>
      </div>
    </Menu>
  );
};

export default Dropdown;
