import { ArrowLeftIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ConfirmModal from '../../../../components/ModalComponents/ConfirmModal';
import InviteModal from '../../../../components/ModalComponents/InviteModal';
import Pagination from '../../../../components/Paginate/Pagination';
import {
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { Paginate } from '../../../_shared/types/api.types';
import { ListUsersDto } from '../../dtos/ListUsersDto';
import { ListUsersResultDto } from '../../dtos/ListUsersResultDto';
import userService from '../../service/userService';
import FilterUsers from './components/FilterUsers';
import UsersTable from './components/UsersTable';

const ListUsers = () => {
  const [filterActive, setFilterActive] = useState<ListUsersDto>({});
  const [usersPaginate, setUsersPaginate] = useState<Paginate<ListUsersResultDto>>();
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openModalRemoveUser, setOpenModalRemoveUser] = useState(false);
  const [itemActive, setItemActive] = useState<any>({});

  const loadUsers = async () => {
    const result = await userService.paginateUsers({ ...filterActive, limit: 10 });
    setUsersPaginate(result);
  };

  const onChangePage = async (page: number) => {
    setFilterActive((old) => ({ ...old, page }));
  };

  const handleChangeFilter = (filterValue: ListUsersDto) => {
    setFilterActive(filterValue);
  };

  const handleClickGenerateInvitation = () => {
    setOpenInviteModal(true);
  };

  const handeDeleteUser = async () => {
    try {
      const result = await userService.deleteUser(itemActive);

      toast.success(result.message);
      loadUsers();
    } catch (resultError) {
      handleErrors(resultError);
    } finally {
      setOpenModalRemoveUser(false);
    }
  };

  const handleClickDeleteUser = (user: any) => {
    setItemActive(user.id);
    setOpenModalRemoveUser(true);
  };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(resultErrorResponse);
    resultErrors.map((resultError) => toast.error(resultError));
  };

  useEffect(() => {
    loadUsers();
  }, [filterActive]);

  return (
    <div className="pt-16">
      <div className="mb-8 flex flex-row">
        <h1 className="text-3xl font-semibold text-teal-600 grow">Painel administrador</h1>
        <div
          className="hover:bg-gray-100 rounded flex flex-row items-center cursor-pointer px-3 py-1 mr-4"
          onClick={() => history.back()}
        >
          <ArrowLeftIcon className="w-3 h-3 mr-1" />
          <span className="text-xs">Voltar</span>
        </div>
      </div>

      <div className="mt-2">
        <FilterUsers onChange={handleChangeFilter} onClick={handleClickGenerateInvitation} />
      </div>

      <div className="mt-8">
        <UsersTable users={usersPaginate?.results} onClickDeleteUser={handleClickDeleteUser} />

        <div className="mt-4">
          <Pagination
            currentPageLength={usersPaginate?.results.length}
            page={usersPaginate?.currentPage}
            pageSize={usersPaginate?.limit}
            onChangePage={onChangePage}
            totalItems={usersPaginate?.totalItems}
          />
        </div>
      </div>
      <InviteModal isOpen={openInviteModal} onClose={() => setOpenInviteModal(false)} />
      <ConfirmModal
        isOpen={openModalRemoveUser}
        onClose={() => setOpenModalRemoveUser(false)}
        modalText="Deseja excluir esse usuário?"
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: () => setOpenModalRemoveUser(false)
        }}
        confirmButton={{
          buttonText: 'Confirmar',
          onClick: handeDeleteUser
        }}
      />
    </div>
  );
};

export default ListUsers;
