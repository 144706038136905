import { Dialog, Switch, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../../components/Button';
import CheckboxForm from '../../../../../components/FormComponents/CheckboxForm';
import InputForm from '../../../../../components/FormComponents/InputForm';
import SelectForm from '../../../../../components/FormComponents/SelectForm';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../../_shared/helpers/handleApiErrorResponse';
import { getLevelOptions } from '../../../../_shared/helpers/LevelHelper';
import { CreateJobsProfileDto } from '../../../dtos/createJobsProfileDto';
import { UpdateJobsProfileDto } from '../../../dtos/updateJobsProfileDto';
import JobsProfileService from '../../../service/JobsProfileService';

interface ConfigModalProps {
  modalText: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (value?: any) => void;
  id?: string;
  isEdit?: boolean;
}

const CreateJobsProfileModal = ({
  modalText,
  isOpen,
  onClose,
  onConfirm,
  id,
  isEdit
}: ConfigModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const [enabled, setEnabled] = useState(true);
  const [isCreateGroup, setIsCreateGroup] = useState(false);

  useEffect(() => {
    if (id) {
      getJobsProfile();
    }
  }, [id]);

  const getJobsProfile = async () => {
    if (id) {
      const result = await JobsProfileService.getJobsProfileById(id);
      formRef.current?.setData(result.data);
      setEnabled(result.data.isActive);
    } else {
      setEnabled(true);
    }
  };

  const handleAddNewJobsProfile = async () => {
    try {
      const mainFormData = formRef.current?.getData();
      if (id) {
        const updateJobsProfile = {
          name: mainFormData?.name,
          isActive: enabled
        } as UpdateJobsProfileDto;

        const result = await JobsProfileService.updateJobsProfile(id, updateJobsProfile);
        toast.success(result.message);
      } else {
        const newJobsProfileToCreate = {
          ...mainFormData,
          isActive: enabled
        } as CreateJobsProfileDto;

        const result = await JobsProfileService.createJobsProfile(newJobsProfileToCreate);

        toast.success(result.message);
      }

      onClose();
      onConfirm();
      clearForm();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleEditNameJobProfiles = () => {
    const mainFormData = formRef.current?.getData();
    onConfirm({ newName: mainFormData?.name });
    onClose();
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorReponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorReponse);
    toast.error(error);
  };

  const handleClose = () => {
    onClose();
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  useEffect(() => {
    return;
  }, [isCreateGroup]);

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={handleClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-lg h-auto m-auto">
                <Form
                  ref={formRef}
                  className="flex justify-center"
                  onSubmit={handleAddNewJobsProfile}
                >
                  <div className="relative bg-white rounded-lg shadow w-[460px]">
                    <div className="absolute top-1 right-1 p-2">
                      <XIcon
                        className="w-5 h-5 text-red-600 cursor-pointer"
                        onClick={handleClose}
                      />
                    </div>
                    <div className="flex justify-between items-start p-6 py-6 rounded-t border-b">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        {modalText}
                      </span>
                    </div>
                    <div className="p-6 py-7">
                      <InputForm name="name" type="string" label="Nome" />
                      {!isEdit && (
                        <>
                          <div className="flex justify-start pt-4 flex-col">
                            <CheckboxForm
                              name="createGroup"
                              label="Criar grupo"
                              onChangeValue={(e) => setIsCreateGroup(e)}
                            />
                            {isCreateGroup && (
                              <SelectForm
                                className="w-60"
                                name="level"
                                label="Niveis"
                                options={getLevelOptions()}
                              />
                            )}
                          </div>
                          {id && (
                            <div className="pt-3 text-sm font-medium flex items-center gap-2 mt-6">
                              <span className="whitespace-nowrap border-y-0">Ativo:</span>
                              <Switch
                                checked={enabled}
                                onChange={setEnabled}
                                className={`${
                                  enabled ? 'bg-teal-600' : 'bg-gray-600'
                                } inline-flex h-4 w-10 items-center rounded-full`}
                              >
                                <span
                                  className={`${
                                    enabled ? 'translate-x-6' : 'translate-x-1'
                                  } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                />
                              </Switch>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="flex items-center p-6 space-x-3 rounded-b border-t border-gray-200 justify-end">
                      <Button variant="default" type="button" onClick={onClose}>
                        Cancelar
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={isEdit ? handleEditNameJobProfiles : handleAddNewJobsProfile}
                      >
                        {isEdit ? 'Atualizar' : 'Enviar'}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateJobsProfileModal;
