export enum ScholarityType {
  INCOMPLETE_ELEMENTARY_SCHOOL = 'incomplete elementary school',
  ELEMENTARY_SCHOOL = 'elementary school',
  INCOMPLETE_HIGH_SCHOOL = 'incomplete high school',
  HIGH_SCHOOL = 'high school',
  INCOMPLETE_UNIVERSITY_EDUCATION = 'incomplete university education',
  UNIVERSITY_EDUCATION = 'university education'
}

export enum UniversityEducationType {
  GRADUATION = 'graduation',
  POSTGRADUATE = 'postgraduate',
  MASTERS_DEGREE = "master's degree",
  DOCTORATE = 'doctorate',
  POST_DOCTORAL = 'post doctoral'
}
