import queryString from 'query-string';
import api from '../../../_shared/services/api';
import { Paginate, Result } from '../../../_shared/types/api.types';
import { CreateTypeOpportunityDto } from '../dtos/CreateTypeOpportunityDto';
import { PaginateTypeOpportunityDto } from '../dtos/PaginateTypeOpportunityDto';
import { ResultTypeOpportunityDto } from '../dtos/ResultTypeOpportunityDto';
import { UpdateTypeOpportunityDto } from '../dtos/UpdateTypeOpportunityDto';
import { TypeOpportunity } from '../models/TypeOpportunity';

class TypeOpportunityService {
  public async newTypeOpportunity(
    createTypeOpportunityDto: CreateTypeOpportunityDto
  ): Promise<Result<ResultTypeOpportunityDto>> {
    const response = await api.post('/type-opportunity', createTypeOpportunityDto);
    return response.data;
  }

  public async update(
    typeOpportunityId: number,
    updateTypeOpportunityDto: UpdateTypeOpportunityDto
  ): Promise<Result<void>> {
    const response = await api.put(
      `/type-opportunity/${typeOpportunityId}`,
      updateTypeOpportunityDto
    );
    return response.data;
  }

  public async getTypeOpportunity(typeOpportunityId: number): Promise<Result<TypeOpportunity>> {
    const response = await api.get(`/type-opportunity/${typeOpportunityId}`);
    return response.data;
  }

  public async paginateTypeOpportunity({
    ...paginateVague
  }: PaginateTypeOpportunityDto): Promise<Paginate<TypeOpportunity>> {
    const queryParams = queryString.stringify(paginateVague);
    const response = await api.get(`/type-opportunity?${queryParams}`);
    return response.data.data;
  }
}

export default new TypeOpportunityService();
