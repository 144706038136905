import queryString from 'query-string';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { PaginateDiscloseOpportunitiesDto } from '../dtos/PaginateDiscloseOpportunitiesDto';
import { DiscloseOpportunity } from '../models/DiscloseOpportunity';

class DiscloseOpportunitiesService {
  public async getDiscloseOpportunityDetail(
    opportunityId: string
  ): Promise<Result<DiscloseOpportunity>> {
    const response = await api.get(`/public/opportunities/${opportunityId}`);
    return response.data;
  }

  public async paginateDiscloseOpportunities({
    ...paginateOpportunities
  }: PaginateDiscloseOpportunitiesDto): Promise<Paginate<DiscloseOpportunity>> {
    const queryParams = queryString.stringify(paginateOpportunities);
    const response = await api.get(`/public/opportunities?${queryParams}`);
    return response.data.data;
  }
}

export default new DiscloseOpportunitiesService();
