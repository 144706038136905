import { DocumentSearchIcon, PhotographIcon, TrashIcon } from '@heroicons/react/solid';
import React from 'react';
import { CurriculumFile } from '../../../../models/Candidate';

interface ImageDetailType {
  file?: File;
  image?: CurriculumFile;
  showImage: () => void;
  removeImage: () => void;
}

const ImageDetail = ({ showImage, removeImage, file, image }: ImageDetailType) => {
  return (
    <div className="flex w-full py-2 px-3 sm:px-4 rounded border border-gray-300 border-dashed items-center justify-between bg-gray-50">
      <div className="flex items-center gap-2 sm:gap-3 text-gray-500">
        <PhotographIcon className="w-6 h-6" />
        <p className="text-sm sm:text-base font-medium">
          {(file && file.name) || (image && image.completeName)}
        </p>
      </div>
      <div className="flex items-center sm:gap-3 gap-0">
        <div className="cursor-pointer">
          <DocumentSearchIcon
            className="w-5 h-5 ml-1 sm:w-6 sm:h-6 text-teal-600"
            onClick={() => showImage()}
          />
        </div>
        {(file || image) && (
          <div className="cursor-pointer">
            <TrashIcon className="w-5 h-5 sm:w-6 sm:h-6 text-red-600" onClick={removeImage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageDetail;
