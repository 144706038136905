import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import InputForm from '../../../../../components/FormComponents/InputForm';

const ChangePassword = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickChangePassword = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="mb-1">
      <Disclosure>
        <div
          className={`flex transition-all bg-gray-100 hover:bg-gray-200 cursor-pointer ${
            isOpen ? 'rounded-t bg-gray-100 bg-opacity-75' : 'rounded'
          }`}
          onClick={handleClickChangePassword}
        >
          <Disclosure.Button className="flex px-3 py-2 justify-between w-full items-center">
            <span className="font-semibold text-base text-gray-600">Alterar senha</span>
            <ChevronDownIcon
              className={`h-8 w-8 text-teal-600 ${isOpen ? 'transform rotate-180' : ''}`}
            />
          </Disclosure.Button>
        </div>
        <Disclosure.Panel className="flex bg-gray-100 bg-opacity-75">
          <div className="mb-8 px-3 w-full">
            <InputForm
              type="password"
              name="password"
              label="Nova senha*"
              placeholder="Digite uma nova senha."
            />
            <InputForm
              type="password"
              name="confirmPassword"
              label="Confirmar nova senha*"
              placeholder="Confirme sua nova senha."
            />
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

export default ChangePassword;
