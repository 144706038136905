import React from 'react';
import ListCard from '../../../../../../components/ListCard';
import Table, { Action } from '../../../../../../components/Table';
import { ListUsersResultDto } from '../../../../dtos/ListUsersResultDto';

interface Props {
  users?: ListUsersResultDto[];
  onClickDeleteUser: (item: string) => void;
}

const UsersTable = ({ users, onClickDeleteUser }: Props) => {
  const actions: Action[] = [{ label: 'Excluir', onClick: onClickDeleteUser }];
  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            { columnName: 'Nome', key: 'name' },
            { columnName: 'E-mail', key: 'email' }
          ]}
          values={users}
          menuItems={actions}
        />
      </div>
      <div className="sm:hidden flex">
        <ListCard
          items={[
            { label: 'Nome', key: 'name' },
            { label: 'E-mail', key: 'email' }
          ]}
          values={users}
          menuItems={actions}
        />
      </div>
    </>
  );
};

export default UsersTable;
