import { ArrowLeftIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import Pagination from '../../../../../components/Paginate/Pagination';
import { Paginate } from '../../../../_shared/types/api.types';
import { PaginateOccupationAreaDto } from '../../dtos/PaginateOccupationAreaDto';
import { OccupationArea } from '../../models/OccupationArea';
import OccupationAreaService from '../../service/OccupationAreaService';
import OccupationAreaModal from './components/OccupationAreaModal';
import OccupationAreaTable from './components/OccupationAreaTable';

const ListOccupationArea = () => {
  const [paginationActive, setPaginationActive] = useState<PaginateOccupationAreaDto>({});
  const [paginateArea, setPaginateArea] = useState<Paginate<OccupationArea>>();
  const [openOccupationAreaModal, setOpenOccupationAreaModal] = useState(false);
  const [itemActive, setItemActive] = useState<OccupationArea>();

  const loadOccupationArea = async () => {
    const result = await OccupationAreaService.paginateOccupationArea({
      ...paginationActive,
      limit: 10
    });
    setPaginateArea(result);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  const handleClickEditOccupationArea = async (occupationAreaId?: number) => {
    if (occupationAreaId) {
      const response = await OccupationAreaService.getOccupationArea(occupationAreaId);
      setItemActive(response.data);
    }
    setOpenOccupationAreaModal(true);
  };

  const handleClickNewOccupationArea = () => {
    setItemActive(undefined);
    setOpenOccupationAreaModal(true);
  };

  const handleOccupationArea = () => {
    loadOccupationArea();
  };

  const handleCloseOccupationArea = () => {
    setOpenOccupationAreaModal(false);
  };

  useEffect(() => {
    loadOccupationArea();
  }, [paginationActive]);

  return (
    <div className="pt-16">
      <div className="flex flex-row mb-8">
        <h1 className="text-3xl font-semibold text-teal-600 grow">Áreas de Trabalho</h1>
        <div className="flex flex-col mr-4">
          <div
            className="hover:bg-gray-100 rounded flex flex-row items-center justify-end cursor-pointer mx-1 px-3 py-1"
            onClick={() => history.back()}
          >
            <ArrowLeftIcon className="w-3 h-3 mr-1" />
            <span className="text-xs">Voltar</span>
          </div>
          <div className="flex items-center px-1 py-4 gap-3 w-full sm:w-auto justify-end">
            <Button variant="primary" type="button" onClick={handleClickNewOccupationArea}>
              Novo
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <OccupationAreaTable
          occupationArea={paginateArea?.results}
          onClickEditOccupationArea={handleClickEditOccupationArea}
        />
      </div>

      <div className="mt-4">
        <Pagination
          currentPageLength={paginateArea?.results.length}
          page={paginateArea?.currentPage}
          pageSize={paginateArea?.limit}
          onChangePage={onChangePage}
          totalItems={paginateArea?.totalItems}
        />
      </div>

      <OccupationAreaModal
        occupationArea={itemActive}
        isOpen={openOccupationAreaModal}
        modalText={itemActive?.id ? 'Editar Área de Trabalho' : 'Nova Área de Trabalho'}
        onClose={handleCloseOccupationArea}
        onConfirm={handleOccupationArea}
      />
    </div>
  );
};

export default ListOccupationArea;
