import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';
import ReactInputMask from 'react-input-mask';

interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  labelStyle?: string;
  inputStyle?: string;
  inputMask: string;
  maskChar: any;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const InputMaskForm = ({
  placeholder,
  labelStyle,
  inputStyle,
  label,
  name,
  inputMask,
  maskChar
}: InputProps) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      }
    });
  }, [fieldName, registerField]);

  return (
    <div className="flex flex-col mt-2 text-sm">
      <label
        htmlFor={name}
        className={labelStyle || `py-1 font-medium  ${error ? ' text-red-500' : 'text-stone-800'}`}
      >
        {label}
      </label>
      <ReactInputMask
        name={fieldName}
        mask={inputMask}
        ref={inputRef}
        onChange={clearError}
        defaultValue={defaultValue}
        placeholder={placeholder}
        alwaysShowMask={true}
        className={`rounded border focus:ring-1 p-2 focus:outline-none
          ${
            error
              ? ' border-red-500 focus:border-red-500 focus:ring-red-500'
              : ' text-gray-500 border-gray-200 focus:border-teal-600 focus:ring-teal-600'
          }
        `}
      />
      {error && <span className="text-red-500 text-xs mt-1 ml-1">{error}</span>}
    </div>
  );
};

export default InputMaskForm;
