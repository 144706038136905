import queryString from 'query-string';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { CreateJobsProfileDto } from '../dtos/createJobsProfileDto';
import { PaginateJobsProfileDto } from '../dtos/paginateJobsProfileDto';
import { UpdateJobsProfileDto } from '../dtos/updateJobsProfileDto';
import { JobsProfile } from '../models/JobsProfile';
import { JobProfileGroup } from '../pages/listJobsProfile';

class JobsProfileService {
  public async createJobsProfile(
    createJobsProfileDto: CreateJobsProfileDto
  ): Promise<Result<JobsProfile[]>> {
    const response = await api.post('job-profile', createJobsProfileDto);
    return response.data;
  }

  public async updateJobsProfile(
    id: string,
    updateJobsProfileDto: UpdateJobsProfileDto
  ): Promise<Result<JobsProfile>> {
    const response = await api.put(`/job-profile/${id}`, updateJobsProfileDto);
    return response.data;
  }

  public async getJobsProfileById(profileId: string): Promise<Result<JobsProfile>> {
    const response = await api.get(`/job-profile/${profileId}`);
    return response.data;
  }

  public async paginateJobsProfile({
    ...paginateJobsProfile
  }: PaginateJobsProfileDto): Promise<Paginate<JobProfileGroup>> {
    const queryParams = queryString.stringify(paginateJobsProfile);
    const response = await api.get(`/job-profile?${queryParams}`);
    return response.data.data;
  }

  public async getJobsProfile(): Promise<Result<JobsProfile>> {
    const response = await api.get(`job-profile`);
    return response.data.data;
  }
}

export default new JobsProfileService();
