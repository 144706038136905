import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import Routes from '../modules/_shared/constants/Routes.enum';
import ForgotPassword from '../modules/auth/pages/ForgotPassword';
import Login from '../modules/auth/pages/Login';
import RegisterCareers from '../modules/careers/pages/RegisterCareers';
import RegisterFinished from '../modules/careers/pages/RegisterFinished';
import DiscloseOpportunityDetail from '../modules/discloseOpportunities/pages/DiscloseOpportunityDetail';
import ListDisclosedOpportunities from '../modules/discloseOpportunities/pages/ListDisclosedOpportunities';
import EmailConfirmationNotice from '../modules/notify/pages/EmailConfirmationNotice';
import CreateUser from '../modules/users/pages/CreateUser';

const AuthRoutes = () => (
  <HashRouter>
    <MainLayout>
      <Switch>
        <Route path={Routes.LOGIN} exact component={Login} />
        <Route path={Routes.REGISTER_CAREERS} exact component={RegisterCareers} />
        <Route path={Routes.REGISTER_CAREERS_FINISHED} exact component={RegisterFinished} />
        <Route path={Routes.NOTIFY_CONFIRMATION_EMAIL} exact component={EmailConfirmationNotice} />
        <Route path={Routes.FORGOT_PASSWORD} exact component={ForgotPassword} />
        <Route path={Routes.CREATE_USERS} exact component={CreateUser} />
        <Route path={Routes.DISCLOSE_OPPORTUNITIES} exact component={ListDisclosedOpportunities} />
        <Route
          path={Routes.DISCLOSE_OPPORTUNITIES_DETAIL}
          exact
          component={DiscloseOpportunityDetail}
        />
        <Redirect path="/" to={Routes.LOGIN} />
      </Switch>
    </MainLayout>
  </HashRouter>
);

export default AuthRoutes;
