import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ConfirmModal from '../../../../components/ModalComponents/ConfirmModal';
import { useAuth } from '../../../auth/contexts/AuthProvider';
import { DiscloseOpportunity } from '../../../discloseOpportunities/models/DiscloseOpportunity';
import DiscloseOpportunityDetail from '../../../discloseOpportunities/pages/DiscloseOpportunityDetail';
import ListDisclosedOpportunities from '../../../discloseOpportunities/pages/ListDisclosedOpportunities';
import DiscloseOpportunitiesService from '../../../discloseOpportunities/service/DiscloseOpportunitiesService';
import User from '../../../users/models/User';
import userService from '../../../users/service/userService';
import { LOCAL_KEY_OPPORTUNITY_ID } from '../../../_shared/constants/LocalStorage.constants';
import { getAreaOptions } from '../../../_shared/helpers/AreaHelper';
import {
  getErrorMessage,
  getErrorMessagesByName,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { getScholarityOptions } from '../../../_shared/helpers/ScholarityHelper';
import candidateService from '../../service/candidateService';
import ApplicationsOpportunities from './components/ApplicationOpportunities/ApplicationsOpportunities';
import CandidateForm from './components/CandidateForm';
import CandidateMenu from './components/CandidateMenu/CandidateMenu';
import EditCurriculum from './components/EditCurriculum';

const areaList = getAreaOptions();
const scholarityTypeList = getScholarityOptions();

export const enum Step {
  PROFILE_CANDIDATE = 1,
  APPLICATION_OPPORTUNITY = 2,
  LIST_OPPORTUNITIES = 3,
  EDIT_CURRICULUM = 4
}

const RegisterCareers = () => {
  const { signed, user } = useAuth();
  const [step, setStep] = useState<Step>(1);
  const [opportunity, setOpportunity] = useState<DiscloseOpportunity | undefined>();
  const [userActive, setUserActive] = useState<User>();
  const [openModalConfirmAplicationOpportunities, setOpenModalConfirmAplicationOpportunities] =
    useState(false);

  const handleErrors = (resultError: unknown) => {
    let skipDefaultError = false;
    const messageErrors = getErrorMessagesByName(resultError, 'toDelete', 'workExperience');
    for (const messageError of messageErrors) {
      toast.error(messageError);
      skipDefaultError = true;
    }
    if (skipDefaultError) return;

    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(resultErrorResponse);

    if (signed) {
      resultErrors.map((resultError) => toast.error(resultError));
    } else {
      if (resultErrorResponse.errors[0].name == 'privacyTerm') {
        toast.error(resultErrors[0]);
      } else {
        const error = getErrorMessage(resultErrorResponse);
        toast.error(error);
      }
    }
  };

  const handleConfirmAplicationOpportunities = async () => {
    if (!opportunity) return;
    try {
      const result = await candidateService.addOpportunityToCandidate({
        opportunityId: opportunity.id
      });
      toast.success(result.message);
      await loadUserInfo();
      setStep(Step.APPLICATION_OPPORTUNITY);
      handleCloseModalConfirmAplicationOpportunities();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleOpenConfirmAplicationOpportunities = () => {
    setOpenModalConfirmAplicationOpportunities(true);
  };

  const loadUserInfo = async () => {
    if (!user) return;
    try {
      setTimeout(async () => {
        const { data: userCandidate } = await userService.getUser(user.id);
        setUserActive(userCandidate);
      }, 1000);
    } catch (resultError) {
      handleErrors(resultError);
    }
  };

  const handleClickOpportunity = (discloseOpportunity: DiscloseOpportunity) => {
    setOpportunity(discloseOpportunity);
  };

  const handleQuitOpportunity = async (discloseOpportunityId: string) => {
    try {
      const result = await candidateService.quitOpportunityToCandidate({
        opportunityId: discloseOpportunityId
      });
      loadUserInfo();
      toast.success(result.message);
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleClickItemMenu = (step: Step) => {
    setStep(step);
    setOpportunity(undefined);
  };

  const handleApplyForOpportunityWhenLogin = async () => {
    const storedOpportunityId = localStorage.getItem(LOCAL_KEY_OPPORTUNITY_ID);
    if (storedOpportunityId) {
      const opportunityId = JSON.parse(storedOpportunityId);
      const result = await DiscloseOpportunitiesService.getDiscloseOpportunityDetail(opportunityId);
      setOpportunity(result.data);
      handleOpenConfirmAplicationOpportunities();
    }
  };

  const handleCloseModalConfirmAplicationOpportunities = () => {
    setOpenModalConfirmAplicationOpportunities(false);
    localStorage.removeItem(LOCAL_KEY_OPPORTUNITY_ID);
  };

  useEffect(() => {
    if (signed) {
      loadUserInfo();
      handleApplyForOpportunityWhenLogin();
    }
  }, [user]);

  return (
    <div className="flex flex-col sm:flex-row mt-2">
      <div className="flex flex-col items-center">
        <CandidateMenu onClick={handleClickItemMenu} valueActive={step} />
      </div>
      <div className="w-full">
        <div className="py-8 sm:p-10 lg:mt-5">
          {step === Step.PROFILE_CANDIDATE && <CandidateForm />}
          {step === Step.APPLICATION_OPPORTUNITY && !opportunity && (
            <ApplicationsOpportunities
              onClickQuitOpportunity={handleQuitOpportunity}
              opportunities={userActive?.candidate?.linkedOpportunities}
              onClickViewOpportunity={handleClickOpportunity}
            />
          )}
          {step === Step.LIST_OPPORTUNITIES && !opportunity && (
            <ListDisclosedOpportunities onClick={handleClickOpportunity} />
          )}
          {(step === Step.LIST_OPPORTUNITIES || step === Step.APPLICATION_OPPORTUNITY) &&
            opportunity && (
              <DiscloseOpportunityDetail
                discloseOpportunityId={opportunity.id}
                goBack={() => setOpportunity(undefined)}
                onClickConfirm={handleOpenConfirmAplicationOpportunities}
                isApplicationOppotunity={step === Step.APPLICATION_OPPORTUNITY}
              />
            )}
          {step === Step.EDIT_CURRICULUM && <EditCurriculum candidate={userActive?.candidate} />}
        </div>
      </div>
      <ConfirmModal
        isOpen={openModalConfirmAplicationOpportunities}
        onClose={() => setOpenModalConfirmAplicationOpportunities(false)}
        subTitle={opportunity?.title}
        modalText="Deseja confirmar candidatura a essa oportunidade?"
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: handleCloseModalConfirmAplicationOpportunities
        }}
        confirmButton={{
          buttonText: 'Confirmar',
          onClick: handleConfirmAplicationOpportunities
        }}
      />
    </div>
  );
};

export default RegisterCareers;
