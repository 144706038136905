import { Form } from '@unform/web';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../../components/Button';
import DropzoneForm from '../../../../../components/FormComponents/DropzoneForm';
import ImagePreviewModal from '../../../../../components/ImagePreviewModal';
import userService from '../../../../users/service/userService';
import {
  getErrorMessage,
  manageApiErrorResponse
} from '../../../../_shared/helpers/handleApiErrorResponse';
import Candidate, { CurriculumFile } from '../../../models/Candidate';
import candidateService from '../../../service/candidateService';
import ImageDetail from './ImageDetail';

type Props = { candidate?: Candidate };

const EditCurriculum = ({ candidate }: Props) => {
  const [file, setFile] = useState<File>();
  const [isOpen, setIsOpen] = useState(false);
  const [curriculumFile, setCurriculumFile] = useState<CurriculumFile | undefined>(
    candidate?.curriculumPdf
  );

  const handleRemoveImage = () => {
    setCurriculumFile(undefined);
    setFile(undefined);
  };

  const imageUrl = (fileName: string) => {
    return userService.getImageUrl(fileName);
  };

  const handleUpdateCurriculumPdf = async () => {
    try {
      if (!file) return;
      const response = await candidateService.updateCurricumPdf({
        filename: file.name,
        curriculumPdf: file
      });
      setFile(undefined);
      setCurriculumFile(response.data.candidate?.curriculumPdf);
      toast.success(response.message);
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorResponse);
    toast.error(error);
  };

  const handleShowImage = () => {
    setIsOpen(true);
  };

  const handleCloseImage = () => {
    setIsOpen(false);
  };

  const handleChangeSelectFile = (file: File) => {
    if (file) setCurriculumFile(undefined);
    setFile(file);
  };

  return (
    <>
      <h1 className="text-3xl font-semibold text-teal-600 py-3 mb-7">Curriculum</h1>
      <Form onSubmit={handleUpdateCurriculumPdf}>
        <DropzoneForm
          onChange={handleChangeSelectFile}
          name="candidate.curriculumPdf"
          label="adicionar um arquivo PDF"
          acceptFiles={{ 'application/pdf': ['.pdf'] }}
        />
        {(file || curriculumFile) && (
          <div className="mt-5">
            <ImageDetail
              showImage={handleShowImage}
              removeImage={handleRemoveImage}
              file={file}
              image={curriculumFile}
            />
          </div>
        )}
        {file && (
          <div className="flex justify-end mt-4">
            <Button variant="primary" type="button" onClick={handleUpdateCurriculumPdf}>
              Salvar
            </Button>
          </div>
        )}
      </Form>
      <ImagePreviewModal
        getUrlImage={imageUrl}
        file={file}
        isOpen={isOpen}
        onClose={handleCloseImage}
        curriculumFile={curriculumFile}
      />
    </>
  );
};

export default EditCurriculum;
