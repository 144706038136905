import React from 'react';
import { JobProfileGroup } from '..';
import ListCard from '../../../../../components/ListCard';
import Table, { Action } from '../../../../../components/Table';

interface Props {
  jobProfile?: JobProfileGroup[];
  onClickEditJobProfile: (edit: any) => void;
}

const JobsProfileTable = ({ jobProfile, onClickEditJobProfile }: Props) => {
  const actions: Action[] = [
    {
      label: 'Editar',
      onClick: onClickEditJobProfile
    }
  ];

  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            { columnName: 'Cargo', key: 'name' },
            {
              columnName: 'Níveis',
              key: 'level'
            }
          ]}
          values={jobProfile}
          menuItems={actions}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          items={[
            { label: 'Cargo', key: 'name' },
            { label: 'Níveis', key: 'level' }
          ]}
          values={jobProfile}
          menuItems={actions}
        />
      </div>
    </>
  );
};

export default JobsProfileTable;
