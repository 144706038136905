import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../../components/Paginate/Pagination';
import { useAuth } from '../../../auth/contexts/AuthProvider';
import { convertDateToString } from '../../../_shared/helpers/FormatDateHelper';
import { Paginate } from '../../../_shared/types/api.types';
import { PaginateDiscloseOpportunitiesDto } from '../../dtos/PaginateDiscloseOpportunitiesDto';
import { DiscloseOpportunity } from '../../models/DiscloseOpportunity';
import DiscloseOpportunitiesService from '../../service/DiscloseOpportunitiesService';
import DiscloseOpportunityCard from './components/DiscloseOpportunityCard';

type Props = {
  onClick?: (opportunityId: DiscloseOpportunity) => void;
};

const ListDisclosedOpportunities = ({ onClick }: Props) => {
  const [paginationActive, setPaginationActive] = useState<PaginateDiscloseOpportunitiesDto>({});
  const [opportunities, setOpportunities] = useState<DiscloseOpportunity[]>([]);
  const history = useHistory();
  const { signed } = useAuth();

  const [paginateOpportunity, setPaginateOpportunity] = useState<Paginate<DiscloseOpportunity>>();

  const loadOpportunity = async () => {
    const result = await DiscloseOpportunitiesService.paginateDiscloseOpportunities({
      ...paginationActive,
      limit: 10
    });
    setPaginateOpportunity(result);

    const newResult: DiscloseOpportunity[] = result.results.map((opportunity) => ({
      ...opportunity,
      startDateVague: convertDateToString(new Date(opportunity.startDateOpportunity)),
      endDateVague: convertDateToString(new Date(opportunity.endDateOpportunity))
    }));

    setOpportunities(newResult);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  useEffect(() => {
    loadOpportunity();
  }, [paginationActive]);

  const handleClickOpenOpportunityDetail = (opportunity: DiscloseOpportunity) => {
    if (onClick) return onClick(opportunity);
    history.push(`/disclose-opportunities/${opportunity.id}`);
  };

  return (
    <div className={`flex flex-col ${!signed && 'pt-16'}`}>
      <div className="flex flex-wrap justify-between">
        <h1 className={`text-3xl font-semibold text-teal-600 mb-8 ${signed && 'py-3'}`}>
          Oportunidades de vagas
        </h1>
      </div>
      <div className={`flex flex-col ${!signed && 'mt-6'} gap-3`}>
        {opportunities.length > 0 ? (
          opportunities.map((opportunity, index) => (
            <DiscloseOpportunityCard
              opportunity={opportunity}
              onClick={handleClickOpenOpportunityDetail}
              key={index}
            />
          ))
        ) : (
          <div className="flex w-full justify-center py-4 bg-gray-50 rounded-sm">
            <span className="font-medium text-gray-500 text-base ">
              Não hà oportunidades disponiveis no momento
            </span>
          </div>
        )}
      </div>

      <div className="mt-4">
        <Pagination
          currentPageLength={paginateOpportunity?.results.length}
          page={paginateOpportunity?.currentPage}
          pageSize={paginateOpportunity?.limit}
          onChangePage={onChangePage}
          totalItems={paginateOpportunity?.totalItems}
        />
      </div>
    </div>
  );
};

export default ListDisclosedOpportunities;
