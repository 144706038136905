import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';

interface Props {
  name: string;
  label?: string;
  value?: string;
  onChangeValue?: (value: any) => void;
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & Props;

const CheckboxForm = ({ name, label, onChangeValue, ...rest }: InputProps) => {
  const inputRef = useRef<any>(null);
  const { fieldName, defaultValue = false, registerField, error } = useField(name);
  const [value, setValue] = useState(false);

  const handleChange = (event: any) => {
    setValue(event.target.checked);
    onChangeValue && onChangeValue(event.target.checked);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      setValue: (ref: any, value: any) => {
        setValue(value);
      }
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    onChangeValue && onChangeValue(value);
  }, [value]);

  return (
    <div className="flex flex-col text-sm">
      <div>
        <input
          className="accent-teal-600 cursor-pointer"
          ref={inputRef}
          checked={value}
          onChange={handleChange}
          type="checkbox"
          id={fieldName}
        />
        <label
          className={`py-1 font-medium ${error ? ' text-red-500' : 'text-gray-500'}`}
          htmlFor={fieldName}
          key={fieldName}
        >
          <span className={`py-1 ml-2 font-medium ${error ? ' text-red-500' : 'text-gray-500'}`}>
            {label}
          </span>
        </label>
      </div>

      {error && <span className="text-red-500 text-xs ml-1">{error}</span>}
    </div>
  );
};

export default CheckboxForm;
