import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon, XIcon } from '@heroicons/react/solid';
import React, { Fragment, useEffect, useState } from 'react';
import { OptionSelect } from '../../../../../components/FormComponents/SelectForm';

type Props = { onChange: (value: any) => void; options: OptionSelect[]; defaultValue?: any };

const SelectFilter: React.FC<Props> = ({ onChange, options, defaultValue }) => {
  const [selected, setSelected] = useState<OptionSelect>(options[0]);

  const handleClickclearFilter = () => {
    onChange(undefined);
  };

  useEffect(() => {
    if (selected?.value == '') {
      onChange(undefined);
    } else {
      onChange(selected?.value);
    }
  }, [selected]);

  useEffect(() => {
    if (defaultValue) {
      const indexDefaultValue = options.findIndex((option) => option.value === defaultValue);
      setSelected(options[indexDefaultValue]);
    } else {
      setSelected(options[0]);
    }
  }, [options]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-transparent rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-2 sm:text-sm">
          <span
            className={`block truncate  text-gray-500 ${
              selected?.unavailable ? 'font-medium' : 'font-semibold'
            }`}
          >
            {selected?.label || 'Selecione'}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute right-0 w-72 sm:w-64 p-1 rounded-sm mt-1 overflow-auto text-base bg-white shadow-lg max-h-screen ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
            {options.map((person, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `cursor-pointer select-none relative rounded-sm py-2 px-4 ${
                    active && !person.unavailable ? 'text-green-900 bg-gray-100' : 'text-gray-900'
                  } ${person.unavailable && 'cursor-default text-gray-300'}`
                }
                value={person}
                disabled={person.unavailable}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected && !person.unavailable
                          ? 'font-semibold text-teal-600'
                          : 'font-normal'
                      }  `}
                    >
                      {person.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
            <Listbox.Option value={options[0]} className="flex justify-end border-t-2 py-2">
              <div
                className="group flex items-center cursor-pointer px-3 py-1 rounded-sm"
                onClick={handleClickclearFilter}
              >
                <XIcon className="w-3 h-3 mr-2 group-hover:font-semibold text-gray-400 group-hover:text-gray-600" />
                <span className="font-medium select-none text-gray-400 group-hover:font-semibold group-hover:text-gray-600">
                  Limpar
                </span>
              </div>
            </Listbox.Option>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SelectFilter;
