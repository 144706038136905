import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../../../components/Paginate/Pagination';
import Routes from '../../../../_shared/constants/Routes.enum';
import { convertDateToString } from '../../../../_shared/helpers/FormatDateHelper';
import { Paginate } from '../../../../_shared/types/api.types';
import { PaginateOpportunityDto } from '../../dtos/PaginateOpportunityDto';
import { Opportunity } from '../../models/Opportunity';
import OpportunityService from '../../service/OpportunityService';
import OpportunityTable from './components/OpportunityTable';

const ListOpportunity = () => {
  const [paginationActive, setPaginationActive] = useState<PaginateOpportunityDto>({});
  const [paginateOpportunity, setPaginateOpportunity] = useState<Paginate<Opportunity>>();
  const [opportunity, setOpportunity] = useState<Opportunity[]>([]);
  const history = useHistory();

  const loadOpportunity = async () => {
    const result = await OpportunityService.paginateOpportunity({
      ...paginationActive,
      limit: 10
    });
    setPaginateOpportunity(result);

    const newResult: Opportunity[] = result.results.map((opportunity, index) => ({
      ...opportunity,
      index: index + 1,
      period: `${convertDateToString(
        new Date(opportunity.startDateOpportunity)
      )} até ${convertDateToString(new Date(opportunity.endDateOpportunity))}`
    }));

    setOpportunity(newResult);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  const handleClickEditOpportunity = (vague: any) => {
    history.push(`${Routes.CREATE_OPPORTUNITY}/${vague.id}`);
  };

  const handleClickPreviewOpportunity = (vague: any) => {
    history.push(`/disclose-opportunities/${vague.id}`);
  };

  const handleClickTotalCandidates = (vague: any) => {
    history.push({ pathname: Routes.LIST_CAREERS, state: vague.id });
  };

  useEffect(() => {
    loadOpportunity();
  }, [paginationActive]);

  return (
    <div className="flex flex-col pt-16">
      <div className="flex flex-wrap justify-between">
        <h1 className="text-3xl font-semibold text-teal-600 mb-8">Painel Oportunidades</h1>
      </div>
      <div className="flex flex-col md:flex-row w-full gap-2 items-center bg-gray-200 p-2 rounded">
        <div className="flex flex-row w-full gap-2">
          <span
            className="bg-teal-600 text-white transition duration-300 hover:bg-teal-500 font-medium rounded text-base px-1 py-2 text-center block cursor-pointer w-full"
            onClick={() => history.push(Routes.LIST_TYPE_OPPORTUNITY)}
          >
            Tipos de oportunidades
          </span>
          <span
            className="bg-teal-600 text-white transition duration-300 hover:bg-teal-500 font-medium rounded text-base px-1 py-2 text-center block cursor-pointer w-full"
            onClick={() => history.push(Routes.LIST_WORKSPACE)}
          >
            Locais de trabalho
          </span>
          <span
            className="bg-teal-600 text-white transition duration-300 hover:bg-teal-500 font-medium rounded text-base px-1 py-2 text-center block cursor-pointer w-full"
            onClick={() => history.push(Routes.LIST_OCCUPATION_AREA)}
          >
            Área de trabalho
          </span>
        </div>
        <div className="w-full md:w-auto">
          <span
            className="flex flex-row text-gray-500 bg-gray-300 transition duration-300 hover:bg-gray-100 font-semibold rounded text-base px-4 py-2 text-center items-center cursor-pointer  md:min-w-[150px] gap-1 justify-center"
            onClick={() => history.push(Routes.CREATE_OPPORTUNITY)}
          >
            Cadastrar
          </span>
        </div>
      </div>

      <div className="mt-6">
        <OpportunityTable
          opportunity={opportunity}
          onClickEditOpportunity={handleClickEditOpportunity}
          onClickPreviewOpportunity={handleClickPreviewOpportunity}
          onClickTotalCandidates={handleClickTotalCandidates}
        />
      </div>

      <div className="mt-4">
        <Pagination
          currentPageLength={paginateOpportunity?.results.length}
          page={paginateOpportunity?.currentPage}
          pageSize={paginateOpportunity?.limit}
          onChangePage={onChangePage}
          totalItems={paginateOpportunity?.totalItems}
        />
      </div>
    </div>
  );
};

export default ListOpportunity;
