import { XIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';

type Props = { initValue?: string; onChange?: (value: string) => void };

const Search: React.FC<Props> = ({ initValue, onChange }) => {
  const [value, setValue] = useState(initValue);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  useEffect(() => {
    setValue(initValue);
    if (initValue) {
      setShowCloseIcon(true);
    } else {
      setShowCloseIcon(false);
    }
  }, [initValue]);

  const onChangeValue = (value: string) => {
    setValue(value);

    if (value) {
      setShowCloseIcon(true);
    } else {
      setShowCloseIcon(false);
    }

    if (onChange) {
      onChange(value);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      cleanValue();
    }
  };

  const cleanValue = () => {
    const cleanValue = '';
    setValue(cleanValue);
    setShowCloseIcon(false);

    if (onChange) onChange(cleanValue);
  };

  return (
    <div className="flex flex-row justify-between items-center w-full">
      <input
        type="text"
        className="bg-transparent py-3 ml-2 focus:outline-none text-gray-600 flex flex-1"
        placeholder="Nome, e-mail ou palavra-chave"
        value={value}
        onChange={(event) => onChangeValue(event.target.value)}
        onKeyUp={handleKeyUp}
      />
      {showCloseIcon && (
        <div
          className="flex flex-row items-center px-3 py-2 rounded text-gray-600 font-medium text-xs cursor-pointer hover:bg-gray-200 transition-all duration-100 ease-in-out"
          onClick={cleanValue}
        >
          Limpar <XIcon className="w-3 h-3 ml-1 font-semibold text-gray-700" />
        </div>
      )}
    </div>
  );
};

export default Search;
