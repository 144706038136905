import { PencilIcon } from '@heroicons/react/solid';
import React from 'react';
import Button from '../../../../../../../components/Button';
import ListCard from '../../../../../../../components/ListCard';
import Table from '../../../../../../../components/Table';
import { Workspace } from '../../../../models/Workspace';

interface Props {
  workspace?: Workspace[];
  onClickEditWorkspace: (workspaceId: number) => void;
}

const WorkspaceTable = ({ workspace, onClickEditWorkspace }: Props) => {
  const translateStatus = (value: any) => {
    if (value) {
      return 'Ativo';
    } else {
      return 'Inativo';
    }
  };

  return (
    <>
      <div className="sm:flex hidden">
        <Table
          columns={[
            { columnName: 'Nome', key: 'name' },
            { columnName: 'Status', key: 'isActive', transform: (value) => translateStatus(value) },
            {
              columnName: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex justify-end space-x-2 gap-1">
                  <PencilIcon
                    className="w-5 cursor-pointer text-teal-600"
                    onClick={() => onClickEditWorkspace(itemActive.id)}
                  />
                </div>
              )
            }
          ]}
          values={workspace}
        />
      </div>
      <div className="flex sm:hidden">
        <ListCard
          items={[
            { label: 'Nome', key: 'name' },
            { label: 'Status', key: 'isActive', transform: (value) => translateStatus(value) },
            {
              label: '',
              key: '',
              component: (value, itemActive) => (
                <div className="flex flex-col gap-2 sm:hidden w-full mt-[-16px]">
                  <Button
                    styles="w-full"
                    variant="primary"
                    onClick={() => onClickEditWorkspace(itemActive.id)}
                  >
                    Editar
                  </Button>
                </div>
              )
            }
          ]}
          values={workspace}
        />
      </div>
    </>
  );
};

export default WorkspaceTable;
