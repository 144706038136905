import queryString from 'query-string';
import { CreateUserDto } from '../../users/dtos/CreateUserDto';
import User from '../../users/models/User';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { InviteUserDto } from '../dtos/InviteUserDto';
import { ListUsersDto } from '../dtos/ListUsersDto';
import { ListUsersResultDto } from '../dtos/ListUsersResultDto';

export const API_IMAGE_URL = process.env.REACT_APP_API_URL + '/users/curriculum/';

class UserService {
  public async create(createUserDto: CreateUserDto): Promise<Result<User>> {
    const response = await api.post('/users', createUserDto);
    return response.data;
  }

  public async inviteUser(inviteUserDto: InviteUserDto): Promise<Result<any>> {
    const response = await api.post('/users/invite', inviteUserDto);
    return response.data;
  }

  public async resendInviteUser(email: string): Promise<Result<any>> {
    const response = await api.get(`/users/invite?email=${email}`);
    return response.data;
  }

  public paginateUsers = async ({
    ...listUsersDto
  }: ListUsersDto): Promise<Paginate<ListUsersResultDto>> => {
    const queryParams = queryString.stringify(listUsersDto);
    const response = await api.get(`/users?${queryParams}`);
    return response.data.data;
  };

  public deleteUser = async (userId: string): Promise<Result<void>> => {
    const response = await api.delete(`/users/${userId}`);
    return response.data;
  };

  public getUser = async (userId: string): Promise<Result<User>> => {
    const response = await api.get(`/users/candidates/${userId}`);
    return response.data;
  };

  getImageUrl(imageName: string): string {
    return `${API_IMAGE_URL}${imageName}`;
  }
}

export default new UserService();
