import { OptionSelect } from '../../../components/FormComponents/SelectForm';
import { ScholarityType } from '../../careers/enum/Scholarity';

const scholarityDictionary = [
  { pt: 'Fundamental Incompleto', key: 'incomplete elementary school' },
  { pt: 'Fundamental Completo', key: 'elementary school' },
  { pt: 'Ensino Médio Incompleto', key: 'incomplete high school' },
  { pt: 'Ensino Médio Completo', key: 'high school' },
  { pt: 'Superior Incompleto', key: 'incomplete university education' },
  { pt: 'Superior Completo', key: 'university education' }
];

export const translateScholarity = (scholarity?: string) => {
  for (const scholarityItem of scholarityDictionary) {
    if (scholarityItem.key === scholarity) return scholarityItem.pt;
  }
  return '';
};

export const getScholarityOptions = (isFilter?: boolean): OptionSelect[] => {
  const items = Object.values(ScholarityType).map((scholarity) => ({
    label: translateScholarity(scholarity),
    value: scholarity as string,
    unavailable: false
  }));
  if (isFilter) {
    items.unshift({ label: 'Escolaridade', value: '', unavailable: true });
  } else {
    items.unshift({ label: 'Selecione', value: '', unavailable: true });
  }
  return items;
};
