import { SearchIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import debounceEvent from '../../../../../_shared/helpers/debounce';
import Search from '../../../../../careers/pages/ListCareers/components/Search';
import { ListUsersDto } from '../../../../dtos/ListUsersDto';

type Props = { onChange: (filterActive: ListUsersDto) => void; onClick: () => void };

const FilterUsers: React.FC<Props> = ({ onChange, onClick }) => {
  const [filterValue, setFilterValue] = useState<ListUsersDto>({});

  const handleChangeSearchValue = async (searchValue: string) => {
    setFilterValue((old) => ({ ...old, page: 1, search: searchValue }));
  };

  useEffect(() => {
    onChange(filterValue);
  }, [filterValue]);

  return (
    <div className="flex flex-row flex-wrap items-center w-full bg-gray-200 p-2 rounded gap-2">
      <div className="flex flex-row items-center grow bg-white rounded px-2">
        <SearchIcon className="w-5 h-5 text-gray-500" />
        <Search
          onChange={debounceEvent(handleChangeSearchValue, 1000)}
          initValue={filterValue.search}
        />
      </div>
      <div
        className="hover:bg-teal-500 text-white transition duration-300 bg-teal-600 font-medium rounded text-base px-4 py-3 text-center block cursor-pointer w-full sm:w-auto"
        onClick={() => onClick()}
      >
        Gerar convite
      </div>
    </div>
  );
};

export default FilterUsers;
