import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as InvalidCode } from '../../../../assets/invalidCode.svg';
import { ReactComponent as WellDone } from '../../../../assets/wellDone.svg';
import Button from '../../../../components/Button';
import InputForm from '../../../../components/FormComponents/InputForm';
import Loading from '../../../../components/Loading';
import Routes from '../../../_shared/constants/Routes.enum';
import { getVerifyCode } from '../../../_shared/helpers/VerifyCodeHelper';
import {
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { ResendConfirmEmailDto } from '../../dtos/ResendConfirmEmailDto';
import candidateService from '../../service/candidateService';

const RegisterFinished = () => {
  const formRef = useRef<FormHandles>(null);
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const CURRENT_URL = window.location.href;
  const history = useHistory();

  const handleValidateEmail = async (verifyCode: string) => {
    try {
      await candidateService.validateEmail(verifyCode);
      setValidatedEmail(true);
    } catch (error) {
      setValidatedEmail(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendConfirmEmail = async () => {
    const mainFormData = formRef.current?.getData();
    const originPath = origin + history.createHref({ pathname: Routes.REGISTER_CAREERS_FINISHED });

    const resendConfirmEmailDto = {
      ...mainFormData,
      originPath: originPath,
      validationParam: 'verifyCode'
    } as ResendConfirmEmailDto;

    try {
      await candidateService.resendConfirmEmail(resendConfirmEmailDto);
      history.push({
        pathname: Routes.NOTIFY_CONFIRMATION_EMAIL,
        state: resendConfirmEmailDto.email
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(resultErrorResponse);
    resultErrors.map((resultError) => toast.error(resultError));
  };

  useEffect(() => {
    const verifyCode = getVerifyCode(CURRENT_URL);
    if (verifyCode) {
      handleValidateEmail(verifyCode);
    } else {
      setValidatedEmail(false);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (validatedEmail) {
    return (
      <div className="py-16 flex flex-col items-center justify-center">
        <div className="mb-16">
          <WellDone className="max-w-[260px] w-full h-auto" />
        </div>
        <div className="text-center max-w-xl">
          <span className="block text-3xl mb-4">Cadastro concluído com sucesso!</span>
          <span className="block text-xl text-stone-500">
            Seu perfil já está no nosso banco de dados. Agora é só esperar que uma oportunidade se
            alinhe com o seu perfil e entraremos em contato com você.
          </span>

          <span className="block mt-5 text-md text-stone-400">
            Caso precise editar alguma informação basta clicar em{' '}
            <Link to={Routes.LOGIN}>
              <span className="font-bold text-teal-600 hover:underline">login</span>
            </Link>
          </span>

          <div className="flex flex-row flex-wrap gap-4 justify-center mt-8">
            <a
              href={process.env.REACT_APP_URL_SITE_PSG}
              target="_blank"
              rel="noreferrer"
              className="py-1 px-4 bg-gray-400 rounded text-white hover:bg-gray-500 transition-all"
            >
              Ir para o site
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-16 flex flex-col items-center justify-center">
      <div className="mb-16">
        <InvalidCode className="max-w-[260px] w-full h-auto" />
      </div>
      <div className="text-center max-w-xl">
        <span className="block text-3xl mb-6">Codigo de verificação inválido!</span>
        <span className="block text-xl text-stone-500">
          Seu código de verificação expirou ou já foi verificado, caso ainda não tenha feito a sua
          verificação, podemos enviar um novo para seu e-mail cadastrado:
        </span>

        <span className="block mt-5 text-md text-stone-400">
          Caso já tenha feito a verificação de e-mail, basta efutar seu{' '}
          <Link to={Routes.LOGIN}>
            <span className="font-bold text-teal-600 hover:underline">login</span>
          </Link>
        </span>

        <div className="flex w-full justify-center text-left py-4">
          <Form ref={formRef} onSubmit={handleResendConfirmEmail} className="w-4/6">
            <InputForm type="email" name="email" placeholder="Digite seu e-mail cadastrado." />
            <div className="flex flex-row flex-wrap gap-4 justify-center mt-8">
              <a
                href={process.env.REACT_APP_URL_SITE_PSG}
                target="_blank"
                rel="noreferrer"
                className="py-1 px-4 bg-gray-400 rounded text-white hover:bg-gray-500 transition-all"
              >
                Ir para o site
              </a>
              <Button type="button" variant="primary" onClick={handleResendConfirmEmail}>
                Enviar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default RegisterFinished;
