import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as PasswordPhoto } from '../../../../assets/password.svg';
import Button from '../../../../components/Button';
import InputForm from '../../../../components/FormComponents/InputForm';
import Routes from '../../../_shared/constants/Routes.enum';
import {
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { getVerifyCode } from '../../../_shared/helpers/VerifyCodeHelper';
import { useAuth } from '../../contexts/AuthProvider';
import authService, {
  ForgotPassword as ForgotPasswordDto,
  RedefinePassword
} from '../../services/authService';

const INIT_REDEFINE_PASSWORD_MESSAGE =
  'Informe o seu e-mail cadastrado e enviaremos um link para redefinir a sua senha.';
const INFORMATION_MESSAGE = 'Enviamos um email com o link para realizar a troca da senha.';
const REDEFINE_PASSWORD_MESSAGE = 'Defina uma nova senha.';

const enum Step {
  INIT_REDEFINE_PASSWORD = 1,
  INFORMATION = 2,
  REDEFINE_PASSWORD = 3
}

const ForgotPassword = () => {
  const { redefinePassword } = useAuth();
  const CURRENT_URL = window.location.href;
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [step, setStep] = useState<Step>(Step.INIT_REDEFINE_PASSWORD);

  const handleSubmit = async () => {
    switch (step) {
      case Step.INIT_REDEFINE_PASSWORD:
        handleForgotPassword();
        break;
      case Step.REDEFINE_PASSWORD:
        handleRedefinePassword();
        break;
      default:
        break;
    }
  };

  const handleForgotPassword = async () => {
    const formData = formRef.current?.getData();
    if (!formData) return;
    const forgotPassword: ForgotPasswordDto = {
      email: formData.email,
      originPath: CURRENT_URL,
      validationParam: 'verifyCode'
    };
    try {
      await authService.forgetPassword(forgotPassword);
      handleToSecondStep();
    } catch (resultError) {
      handleError(resultError);
    }
  };

  function handleError(resultError: unknown) {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(
      resultErrorResponse,
      'email',
      'password',
      'confirmPassword'
    );
    resultErrors.map((resultError) => toast.error(resultError));
  }

  const handleToSecondStep = () => {
    step <= 2 && setStep(step + 1);
  };

  const handleRedefinePassword = async () => {
    const formData = formRef.current?.getData();
    if (!formData) return;
    const redefinePasswordDto: RedefinePassword = {
      confirmPassword: formData.confirmPassword,
      password: formData.password,
      verifyCode: getVerifyCode(CURRENT_URL) || ''
    };

    try {
      const user = await redefinePassword(redefinePasswordDto);
      if (user?.isAdmin || user?.isRecruiter) {
        history.push(Routes.LIST_CAREERS);
      } else {
        history.push(Routes.REGISTER_CAREERS);
      }
      toast.success('Senha redefinida com sucesso.');
    } catch (error) {
      handleError(error);
    }
  };

  function handleRequestOrRedefinePage() {
    if (CURRENT_URL.includes('?') && CURRENT_URL.includes('=')) {
      setStep(3);
    } else {
      setStep(1);
    }
  }

  useEffect(() => {
    handleRequestOrRedefinePage();
  }, []);

  return (
    <div className="flex items-center w-full h-full justify-center flex-col gap-2 py-8">
      <div className="rounded sm:border-[1px] sm:p-8 p-4 w-full max-w-[400px]">
        <div className="max-w-xl items-center flex flex-col">
          <PasswordPhoto className="w-full max-w-[230px] sm:w-52 h-52 mx-auto" />
          <div className="mt-8 w-full flex justify-start">
            <h1 className="text-3xl font-semibold  text-teal-600 mb-8">Redefinir senha</h1>
          </div>
        </div>
        <div className="w-full flex flex-col gap-4">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <p className="text-sm">
              {step === Step.INIT_REDEFINE_PASSWORD && INIT_REDEFINE_PASSWORD_MESSAGE}
              {step === Step.INFORMATION && INFORMATION_MESSAGE}
              {step === Step.REDEFINE_PASSWORD && REDEFINE_PASSWORD_MESSAGE}
            </p>
            <div className="mb-4 ">
              {step === Step.INIT_REDEFINE_PASSWORD && (
                <InputForm type="email" name="email" label="Email" />
              )}
              {step === Step.REDEFINE_PASSWORD && (
                <>
                  <InputForm type="password" name="password" label="Nova senha" />
                  <div className="mb-4"></div>
                  <InputForm type="password" name="confirmPassword" label="Confirmar nova senha" />
                </>
              )}
            </div>
            <div className="w-full flex justify-between">
              <Link to={Routes.LOGIN} className="text-teal-500 self">
                Voltar para o login
              </Link>
              {step !== 2 && (
                <Button variant="primary" type="submit">
                  {step === Step.INIT_REDEFINE_PASSWORD ? 'Enviar' : 'Redefinir senha'}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
