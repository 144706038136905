import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  label?: string;
  name: string;
  cols: number;
  rows: number;
  placeholder?: string;
  maxLength?: number;
}
type TextareaProps = JSX.IntrinsicElements['textarea'] & Props;

const TextareaForm: React.FC<TextareaProps> = ({
  label,
  name,
  cols,
  rows,
  placeholder,
  maxLength,
  children,
  ...rest
}) => {
  const textareaRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name);
  const [content, setContent] = useState(defaultValue?.toString().slice(0, maxLength));

  const setFormattedContent = useCallback(
    (text) => {
      clearError();
      setContent(text.slice(0, maxLength));
    },
    [maxLength, setContent]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) setFormattedContent(defaultValue);
  }, [!defaultValue]);

  return (
    <div className="flex flex-col py-2 mt-2 text-sm">
      {label && (
        <label
          htmlFor={name}
          className={`text-sm py-1 font-medium  ${error ? ' text-red-500' : 'text-stone-800'}`}
        >
          {label}
        </label>
      )}
      <div
        className={`relative border rounded ${
          error
            ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
            : 'text-gray-500 border-gray-200 focus:border-teal-600 focus:ring-teal-600'
        }`}
      >
        <textarea
          id={name}
          defaultValue={defaultValue}
          ref={textareaRef}
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={(event) => setFormattedContent(event.target.value)}
          className={`w-full rounded p-2 focus:outline-none focus:ring-1 resize-none ${
            error
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500 '
              : 'text-gray-500 border-gray-200 focus:border-teal-600 focus:ring-teal-600'
          }`}
          {...rest}
        >
          {children}
        </textarea>
      </div>
      {error && <span className="text-red-500 justify-end text-xs mt-1 ml-1">{error}</span>}
      <p
        className={`flex text-xs justify-end mt-1 ml-1
            ${error ? 'text-red-500' : 'text-gray-500'}
          `}
      >
        {content ? content?.length : 0}/{maxLength}
      </p>
    </div>
  );
};

export default TextareaForm;
