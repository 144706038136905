import React, { useEffect, useState } from 'react';
import ConfirmModal from '../../../../../../components/ModalComponents/ConfirmModal';
import { DiscloseOpportunity } from '../../../../../discloseOpportunities/models/DiscloseOpportunity';
import { Opportunity } from '../../../../../opportunities/Opportunities/models/Opportunity';
import ApplicationOpportunityCard from './ApplicationOpportunityCard';

type Props = {
  opportunities?: Opportunity[];
  onClickViewOpportunity: (disclosedOpportunity: DiscloseOpportunity) => void;
  onClickQuitOpportunity: (disclosedOpportunityId: string) => void;
};

const ApplicationsOpportunities = ({
  opportunities,
  onClickViewOpportunity,
  onClickQuitOpportunity
}: Props) => {
  const [opportunitiesActive, setOpportunitiesActive] = useState<Opportunity[]>([]);
  const [opportunitiesInactive, setOpportunitiesInactive] = useState<Opportunity[]>([]);
  const [openModalConfirmQuitOpportunity, setOpenModalConfirmQuitOpportunity] = useState(false);
  const [opportunityActive, setOpportunityActive] = useState<Opportunity>({} as Opportunity);

  const handleCloseModalConfirmQuitOpportunity = () => {
    setOpenModalConfirmQuitOpportunity(false);
  };

  const handleOpenModalConfirmQuitOpportunity = (opportunity: Opportunity) => {
    setOpportunityActive(opportunity);
    setOpenModalConfirmQuitOpportunity(true);
  };

  const handleConfirmModalConfirmQuitOpportunity = () => {
    onClickQuitOpportunity(opportunityActive.id);
    setOpenModalConfirmQuitOpportunity(false);
  };

  useEffect(() => {
    if (!opportunities) return;
    setOpportunitiesActive([]);
    setOpportunitiesInactive([]);
    for (const applicationOpportunity of opportunities) {
      if (applicationOpportunity.isActive) {
        setOpportunitiesActive((oldList) => {
          const newOpporunitiesList = oldList.concat(applicationOpportunity);
          return newOpporunitiesList;
        });
      } else {
        setOpportunitiesInactive((oldList) => {
          const newOpporunitiesList = oldList.concat(applicationOpportunity);
          return newOpporunitiesList;
        });
      }
    }
  }, [opportunities]);

  return (
    <div className="flex flex-col gap-8">
      <h1 className="text-3xl font-semibold text-teal-600 py-3">Candidaturas</h1>
      <div className="flex flex-col gap-3">
        {opportunitiesActive.length > 0 ? (
          opportunitiesActive.map((opportunityActive, index) => (
            <ApplicationOpportunityCard
              key={index}
              opportunity={opportunityActive}
              onClickQuitOpportunity={handleOpenModalConfirmQuitOpportunity}
              onClick={onClickViewOpportunity}
            />
          ))
        ) : (
          <div className="flex w-full justify-center py-4">
            <span className="font-medium text-gray-600 text-base">
              Você não possui candidaturas ativas
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-wrap justify-between">
        <h1 className="text-3xl font-semibold text-gray-600 py-3">Histórico de Candidaturas</h1>
      </div>
      <div className="flex flex-col gap-3">
        {opportunitiesInactive.length > 0 ? (
          opportunitiesInactive.map((opportunityInactive, index) => (
            <ApplicationOpportunityCard
              key={index}
              opportunity={opportunityInactive}
              onClick={() => {
                return;
              }}
            />
          ))
        ) : (
          <div className="flex w-full justify-center py-4">
            <span className="font-medium text-gray-600 text-base">
              Você não possui candidaturas encerradas
            </span>
          </div>
        )}
      </div>
      <ConfirmModal
        isOpen={openModalConfirmQuitOpportunity}
        onClose={() => setOpenModalConfirmQuitOpportunity(false)}
        subTitle={opportunityActive?.title}
        confirmButton={{
          buttonText: 'Desistir',
          onClick: handleConfirmModalConfirmQuitOpportunity
        }}
        modalText="Deseja desistir dessa oportunidade?"
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: handleCloseModalConfirmQuitOpportunity
        }}
      />
    </div>
  );
};

export default ApplicationsOpportunities;
