import React from 'react';
import { useAuth } from '../../../../auth/contexts/AuthProvider';
import { DiscloseOpportunity } from '../../../models/DiscloseOpportunity';
import DiscloseOpportunityItemLabel from './DiscloseOpportunityItemLabel';

type Props = {
  opportunity: DiscloseOpportunity;
  onClick: (opportunity: DiscloseOpportunity) => void;
};

const DiscloseOpportunityCard = ({ opportunity, onClick }: Props) => {
  const { signed } = useAuth();
  return (
    <>
      {!signed ? (
        <div
          onClick={() => onClick(opportunity)}
          className="rounded flex flex-col md:flex-row border-dashed border md:items-center md:text-center border-slate-300 md:h-14 h-auto px-6 py-2 hover:cursor-pointer hover:bg-gray-100 hover:bg-opacity-75"
        >
          <div className="flex flex-1 flex-col-reverse">
            <div className="flex text-md md:justify-start text-teal-600">
              <strong>{opportunity.title}</strong>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:min-w-[420px] md:text-left md:items-center gap-2 md:gap-20">
            <div className="flex md:flex-col flex-col-reverse md:min-w-[192px]">
              <h3 className="font-semibold text-gray-700">{opportunity.occupationArea.name}</h3>
              <DiscloseOpportunityItemLabel title={'Área'} />
            </div>
            <div className="flex md:flex-col flex-col-reverse md:min-w-[192px]">
              <h3 className="font-medium text-sm text-gray-500">
                {opportunity.workspaceOpportunity.name}/{opportunity.typeOpportunity.name}
                {opportunity.typeOpportunity.name}
              </h3>
              <DiscloseOpportunityItemLabel title={'Local'} />
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => onClick(opportunity)}
          className="rounded flex flex-col lg:flex-row border-dashed border lg:items-center lg:text-center border-slate-300 lg:h-14 h-auto px-6 py-2 hover:cursor-pointer hover:bg-gray-100 hover:bg-opacity-75"
        >
          <div className="flex flex-1 flex-col-reverse">
            <div className="flex text-lg lg:justify-start text-teal-600">
              <strong>{opportunity.title}</strong>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:min-w-[420px] lg:text-left lg:items-center gap-2 lg:gap-20">
            <div className="flex lg:flex-col flex-col-reverse lg:min-w-[192px]">
              <h3 className="font-semibold text-gray-700">{opportunity.occupationArea.name}</h3>
              <DiscloseOpportunityItemLabel title={'Área'} />
            </div>
            <div className="flex lg:flex-col flex-col-reverse lg:min-w-[192px]">
              <h3 className="font-medium text-sm text-gray-500">
                {opportunity.workspaceOpportunity.name}/{opportunity.typeOpportunity.name}
              </h3>
              <DiscloseOpportunityItemLabel title={'Local'} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DiscloseOpportunityCard;
