import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import Alert from '../../../../components/Alert';
import Button from '../../../../components/Button';
import InputForm from '../../../../components/FormComponents/InputForm';
import Routes from '../../../_shared/constants/Routes.enum';
import { getVerifyCode } from '../../../_shared/helpers/VerifyCodeHelper';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { useAuth } from '../../../auth/contexts/AuthProvider';
import { AlertType } from '../../../careers/enum/Alert';
import { CreateUserDto } from '../../dtos/CreateUserDto';
import userService from '../../service/userService';

const CreateUser = () => {
  const { signed } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const CURRENT_URL = window.location.href;
  const [activeErrors, setActiveErrors] = useState<string[]>([]);

  const handleCreateUser = async () => {
    const formData = formRef.current?.getData();
    const redefinePasswordDto = {
      ...formData,
      verifyCode: getVerifyCode(CURRENT_URL)
    } as CreateUserDto;

    try {
      const result = await userService.create(redefinePasswordDto);
      history.push(Routes.LOGIN);
      toast.success(result.message);
    } catch (resultError: unknown) {
      handleErrors(resultError);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    if (resultErrorResponse.errors[0].name == 'verifyCode') {
      const resultErrors = manageApiErrorMessages(resultErrorResponse);
      setActiveErrors(resultErrors);
      const error = getErrorMessage(resultErrorResponse);
      toast.error(error);
    } else {
      const error = getErrorMessage(resultErrorResponse);
      toast.error(error);
    }
  };

  useEffect(() => {
    if (signed) {
      history.push(Routes.NOTIFY);
    }
  }, [signed]);

  return (
    <div className="flex items-center w-full h-full justify-center flex-col gap-2 py-8">
      <div className="rounded sm:border-[1px] sm:p-8 p-4 w-full max-w-[400px]">
        <div className="max-w-xl items-center flex flex-col">
          <div className="mt-8 w-full flex justify-start">
            <h1 className="text-3xl font-semibold  text-teal-600 mb-8">Cadastro Usuário</h1>
          </div>
        </div>
        <div className="w-full flex flex-col gap-4">
          <Form ref={formRef} onSubmit={handleCreateUser}>
            <p className="text-sm font-medium">Seja bem vindo ao Inovvati Carreiras!</p>
            <p className="text-sm py-2">
              Preencha os campos abaixo para prosseguirmos com o seu cadastro.
            </p>
            {activeErrors.length > 0 &&
              activeErrors.map((activeError, index) => (
                <div key={index}>
                  <Alert text={activeError} variant={AlertType.ERROR} />
                </div>
              ))}
            <div className="mb-4 ">
              <InputForm type="text" name="name" label="Nome" />
              <InputForm type="password" name="password" label="Senha" />
              <InputForm type="password" name="confirmPassword" label="Confirmar senha" />
            </div>
            <div className="w-full flex justify-between">
              <Link to={Routes.LOGIN} className="text-teal-500 self">
                Voltar para o login
              </Link>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
