import React, { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import User from '../../users/models/User';
import { LOCAL_KEY_TOKEN, LOCAL_KEY_USER } from '../../_shared/constants/LocalStorage.constants';
import api from '../../_shared/services/api';
import authService, { RedefinePassword } from '../services/authService';

export interface LoginCredentials {
  email: string;
  password: string;
}

export type AuthContextData = {
  signed: boolean;
  user: User | null;
  loading: boolean;
  signIn(login: LoginCredentials): Promise<void>;
  signOut(): void;
  setUser(user: User): void;
  redefinePassword: (redefinePassword: RedefinePassword) => Promise<User>;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { storedUser, storedToken } = getAuthItemsFromLocalStorage();

    function loadStorageData() {
      if (storedUser && storedToken) {
        const parsedUser: User = JSON.parse(storedUser);
        const parsedToken: string = JSON.parse(storedToken);

        setDefaultHeaderToken(parsedToken);
        setUser(parsedUser);
      }
      setLoading(false);
    }

    loadStorageData();
  }, []);

  const signIn = async (login: LoginCredentials) => {
    const response = await authService.signIn(login);
    const { data } = response;
    const { token } = data;

    setUser(response.data.user);
    saveTokenInLocalStorage(token);
    setDefaultHeaderToken(token);
  };

  const signOut = () => {
    setUser(null);
    cleanAuthItemsFromLocalStorage();
  };

  const cleanAuthItemsFromLocalStorage = () => {
    localStorage.removeItem(LOCAL_KEY_USER);
    localStorage.removeItem(LOCAL_KEY_TOKEN);
  };

  useEffect(() => {
    if (!user) return;
    localStorage.setItem(LOCAL_KEY_USER, JSON.stringify(user));
  }, [user]);

  const setDefaultHeaderToken = (token: string) => {
    api.defaults.headers.common['authorization'] = `Bearer ${token}`;

    api.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        if (error?.response?.status === 401) {
          toast.error('Login expirado');
          signOut();
        }
        return Promise.reject(error);
      }
    );
  };

  const saveTokenInLocalStorage = (token: string) => {
    localStorage.setItem(LOCAL_KEY_TOKEN, JSON.stringify(token));
  };

  const getAuthItemsFromLocalStorage = () => {
    const storedUser = localStorage.getItem(LOCAL_KEY_USER);
    const storedToken = localStorage.getItem(LOCAL_KEY_TOKEN);

    return { storedUser, storedToken };
  };

  const redefinePassword = async (redefinePasswordDto: RedefinePassword) => {
    const response = await authService.redefinePassword(redefinePasswordDto);
    if (response.success) {
      setUser(response.data.user);
      saveTokenInLocalStorage(response.data.token);
      setDefaultHeaderToken(response.data.token);
    }
    return response.data.user;
  };

  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, signIn, signOut, loading, setUser, redefinePassword }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
