import { ClockIcon } from '@heroicons/react/solid';
import React from 'react';
import ReactTooltip, { Place } from 'react-tooltip';
import Alert from '../../../../../components/Alert';
import { formatDateString } from '../../../../_shared/helpers/FormatDateHelper';
import { AlertType } from '../../../enum/Alert';

interface Props {
  candidate: any;
  direction?: Place;
}

const DeleteAlertComponent = ({ candidate, direction }: Props) => {
  const ALERT_TEXT = `Este candidato possui uma exclusão agendada para ${formatDateString(
    new Date(candidate.expiresAt)
  )}`;
  return (
    <div>
      <div className="hidden sm:flex">
        <a data-tip data-for={candidate.id}>
          <ClockIcon className="w-6 h-6 text-amber-500 hover:text-amber-500" />
        </a>
        <ReactTooltip id={candidate.id} place={direction ? direction : 'left'} effect="solid">
          Este candidato possui uma exclusão agendada para{' '}
          {formatDateString(new Date(candidate.expiresAt))}
        </ReactTooltip>
      </div>
      <div className="flex sm:hidden">
        <Alert text={ALERT_TEXT} variant={AlertType.WARNING} />
      </div>
    </div>
  );
};

export default DeleteAlertComponent;
