import queryString from 'query-string';
import api from '../../../_shared/services/api';
import { Paginate, Result } from '../../../_shared/types/api.types';
import { CreateOccupationAreaDto } from '../dtos/CreateOccupationAreaDto';
import { PaginateOccupationAreaDto } from '../dtos/PaginateOccupationAreaDto';
import { ResultOccupationAreaDto } from '../dtos/ResultOccupationAreaDto';
import { UpdateOccupationAreaDto } from '../dtos/UpdateOccupationAreaDto';
import { OccupationArea } from '../models/OccupationArea';

class OccupationAreaService {
  public async newOccupationArea(
    createOccupationAreaDto: CreateOccupationAreaDto
  ): Promise<Result<ResultOccupationAreaDto>> {
    const response = await api.post('/occupation', createOccupationAreaDto);
    return response.data;
  }

  public async update(
    occupationAreaId: number,
    updateOccupationAreaDto: UpdateOccupationAreaDto
  ): Promise<Result<void>> {
    const response = await api.put(`/occupation/${occupationAreaId}`, updateOccupationAreaDto);
    return response.data;
  }

  public async getOccupationArea(occupationAreaId: number): Promise<Result<OccupationArea>> {
    const response = await api.get(`/occupation/${occupationAreaId}`);
    return response.data;
  }

  public async paginateOccupationArea({
    ...paginateOccupationArea
  }: PaginateOccupationAreaDto): Promise<Paginate<OccupationArea>> {
    const queryParams = queryString.stringify(paginateOccupationArea);
    const response = await api.get(`/occupation?${queryParams}`);
    return response.data.data;
  }
}

export default new OccupationAreaService();
