export const cnhType = [
  { value: 'A', label: 'Tipo A' },
  { value: 'B', label: 'Tipo B' },
  { value: 'C', label: 'Tipo C' },
  { value: 'D', label: 'Tipo D' },
  { value: 'E', label: 'Tipo E' },
  { value: 'A/B', label: 'Tipo A/B' },
  { value: 'A/C', label: 'Tipo A/C' },
  { value: 'A/D', label: 'Tipo A/D' },
  { value: 'A/E', label: 'Tipo A/E' }
];
