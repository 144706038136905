import { SearchIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { OptionSelect } from '../../../../../components/FormComponents/SelectForm';
import OpportunityService from '../../../../opportunities/Opportunities/service/OpportunityService';
import { getAreaOptions } from '../../../../_shared/helpers/AreaHelper';
import debounceEvent from '../../../../_shared/helpers/debounce';
import { getStatusOptions } from '../../../../_shared/helpers/StatusHelper';
import { ListCandidateDto } from '../../../dtos/ListCandidateDto';
import { Status } from '../../../enum/Status';
import AutoCompleteJobProfile from './JobProfileFilter';
import Search from './Search';
import SelectFilter from './SelectFilter';

const areaList = getAreaOptions(true);
const statusList = getStatusOptions(true);

type Props = {
  onChange: (filterActive: ListCandidateDto) => void;
  optionsJobProfile: OptionSelect[];
};

const FilterCareers: React.FC<Props> = ({ onChange, optionsJobProfile }) => {
  const [filterValue, setFilterValue] = useState<ListCandidateDto>({});
  const [opportunitiesOptions, setOpportunitiesOptions] = useState<OptionSelect[]>([]);
  const { state: opportunityIdUrlState } = useLocation();
  const { state: occupationAreaState } = useLocation();

  const loadOpportunitiesOptions = async () => {
    const { results: opportunities } = await OpportunityService.paginateOpportunity({
      limit: 100,
      isActive: true
    });
    const opportunitiesOptions = opportunities.map((opportunity) => {
      const opportunityMapped: OptionSelect = {
        label: opportunity.title,
        value: opportunity.id,
        unavailable: false
      };
      return opportunityMapped;
    });
    opportunitiesOptions.unshift({ label: 'Oportunidades', value: '', unavailable: true });
    setOpportunitiesOptions(opportunitiesOptions);
  };

  const getOpportunitiesOptions = () => {
    return opportunitiesOptions;
  };

  const getOptionsJobProfile = () => {
    return optionsJobProfile;
  };

  const handleChangeSelectOpportunityValue = async (opportunityIdValue: string) => {
    setFilterValue((old) => ({ ...old, page: 1, opportunityId: opportunityIdValue }));
  };

  const handleChangeSelectOptionsJobProfiles = async (jobProfileId: string) => {
    setFilterValue((old) => ({ ...old, page: 1, jobProfileId: jobProfileId }));
  };

  const handleChangeSearchValue = async (searchValue: string) => {
    setFilterValue((old) => ({ ...old, page: 1, search: searchValue }));
  };

  const handleChangeSelectAreaValue = async (areaValue?: string) => {
    setFilterValue((old) => ({ ...old, page: 1, area: areaValue }));
  };

  const handleChangeSelectStatusValue = async (statusValue?: string) => {
    setFilterValue((old) => ({ ...old, page: 1, status: statusValue }));
  };

  useEffect(() => {
    onChange(filterValue);
  }, [filterValue]);

  useEffect(() => {
    loadOpportunitiesOptions();
  }, []);

  return (
    <div className="flex flex-row flex-wrap items-center w-full bg-gray-200 p-2 rounded gap-2">
      <div className="flex flex-row items-center grow bg-white rounded px-2">
        <SearchIcon className="w-5 h-5 text-gray-500" />
        <Search
          onChange={debounceEvent(handleChangeSearchValue, 1000)}
          initValue={filterValue.search}
        />
      </div>
      <div className="hidden sm:flex px-3 py-2">
        <span className="text-sm font-semibold text-gray-500">Filtrar por :</span>
      </div>
      <div className="sm:w-[200px] w-full ">
        <AutoCompleteJobProfile
          isFilter={true}
          onChange={handleChangeSelectOptionsJobProfiles}
          options={getOptionsJobProfile()}
        />
      </div>
      <div className="sm:w-fit w-full">
        <SelectFilter
          onChange={handleChangeSelectOpportunityValue}
          options={getOpportunitiesOptions()}
          defaultValue={opportunityIdUrlState}
        />
      </div>
      <div className="sm:w-fit w-full">
        <SelectFilter onChange={handleChangeSelectAreaValue} options={areaList} />
      </div>
      <div className="sm:w-fit w-full">
        <SelectFilter
          onChange={handleChangeSelectStatusValue}
          options={statusList}
          defaultValue={!opportunityIdUrlState && Status.NOT_RATED}
        />
      </div>
    </div>
  );
};

export default FilterCareers;
