import { FormHandles } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../../components/Paginate/Pagination';
import Routes from '../../../_shared/constants/Routes.enum';
import { Paginate } from '../../../_shared/types/api.types';
import { ListJobsProfileDto } from '../../dtos/listJobsProfileDto';
import { JobsProfile } from '../../models/JobsProfile';
import JobsProfileService from '../../service/JobsProfileService';
import FilterJobsProfile from './components/FilterJobsProfile';
import CreateJobsProfileModal from './components/createJobsProfileModal';
import JobsProfileTable from './components/jobsProfileTable';

export interface JobProfileGroup {
  name: string;
  jobProfiles: JobsProfile[];
  level: number;
}

const ListJobsProfile = () => {
  const formRef = useRef<FormHandles>(null);
  const [filterActive, setFilterActive] = useState<ListJobsProfileDto>({});
  const [paginateJobsProfile, setPaginateJobsProfile] = useState<Paginate<JobProfileGroup>>();
  const [openCreateJobsProfileModal, setOpenCreateJobsProfileModal] = useState(false);
  const [openUpdateJobsProfileModal, setOpenUpdateJobsProfileModal] = useState(false);
  const [activeId, setActiveId] = useState<string>('');
  const history = useHistory();

  const loadJobsProfile = async () => {
    const result = await JobsProfileService.paginateJobsProfile({
      ...filterActive,
      limit: 10
    });
    setPaginateJobsProfile(result);
  };

  const handleNewJobProfile = () => {
    loadJobsProfile();
  };

  const onChangePage = async (page: number) => {
    setFilterActive((old) => ({ ...old, page }));
  };

  const handleClickEditJobsProfile = (value: any) => {
    setTimeout(() => {
      history.push({ pathname: `${Routes.EDIT_JOB}`, state: value });
    }, 500);
  };

  const handleCloseModal = () => {
    setOpenUpdateJobsProfileModal(false);
    setActiveId('');
  };

  const handleChangeFilter = (filterValue: ListJobsProfileDto) => {
    setFilterActive(filterValue);
  };

  useEffect(() => {
    loadJobsProfile();
  }, [filterActive]);

  return (
    <div className="flex flex-col pt-16">
      <div className="flex flex-wrap justify-between">
        <h1 className="text-3xl font-semibold text-teal-600 mb-8">Cargos de Trabalho</h1>
      </div>
      <div className="flex flex-col md:flex-row w-full gap-2 items-center rounded">
        <div className="w-full flex flex-col md:flex-row gap-2 items-center bg-gray-200 p-2 rounded">
          <FilterJobsProfile onChange={handleChangeFilter} />
          <span
            className="flex flex-row text-gray-500 bg-gray-300 transition duration-300 hover:bg-gray-100 font-semibold rounded text-base px-4 py-2 text-center items-center cursor-pointer  md:min-w-[150px] gap-1 justify-center"
            onClick={() => setOpenCreateJobsProfileModal(true)}
          >
            Cadastrar
          </span>
        </div>
      </div>

      <div className="mt-6">
        <JobsProfileTable
          jobProfile={paginateJobsProfile?.results}
          onClickEditJobProfile={handleClickEditJobsProfile}
        />
      </div>

      <div className="mt-4">
        <Pagination
          currentPageLength={paginateJobsProfile?.results.length}
          page={paginateJobsProfile?.currentPage}
          pageSize={paginateJobsProfile?.limit}
          onChangePage={onChangePage}
          totalItems={paginateJobsProfile?.totalItems}
        />
      </div>

      <CreateJobsProfileModal
        id={activeId}
        isOpen={openCreateJobsProfileModal || openUpdateJobsProfileModal}
        modalText={
          openCreateJobsProfileModal ? 'Novo Cargo de Trabalho' : 'Editar Cargo de Trabalho'
        }
        onClose={
          openCreateJobsProfileModal
            ? () => setOpenCreateJobsProfileModal(false)
            : () => handleCloseModal()
        }
        onConfirm={() => handleNewJobProfile()}
      />
    </div>
  );
};

export default ListJobsProfile;
