import { useField } from '@unform/core';
import {
  addMonths,
  addYears,
  format,
  getDay,
  getDaysInMonth,
  isEqual,
  subMonths,
  subYears
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { convertDateToString, convertToDate } from '../../modules/_shared/helpers/FormatDateHelper';
// import { cleanMask } from '../../helpers/cleanMask';
// import { convertToDate } from '../../helpers/convertToDate';

type DatepickerType = 'date' | 'month' | 'year';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const DatepickerForm = ({ name, label, disabled, ...rest }: InputProps) => {
  const DAYS = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  const [dayCount, setDayCount] = useState<Array<number>>([]);
  const [blankDays, setBlankDays] = useState<Array<number>>([]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(new Date());
  const [year, setYear] = useState(new Date().getFullYear());
  const [type, setType] = useState<DatepickerType>('date');

  const datepickerRef = useRef<any>(null);
  const { fieldName, registerField, defaultValue = '', error, clearError } = useField(name);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateString, setDateString] = useState(defaultValue);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: any) => {
    clearError();
    setDateString(event.target.value);
  };

  const decrement = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case 'month':
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        setYear(year - 1);
        break;
      case 'year':
        setDatepickerHeaderDate((prev) => subYears(prev, 10));
        setYear(year - 10);
        break;
    }
  };

  const increment = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case 'month':
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        setYear(year + 1);
        break;
      case 'year':
        setDatepickerHeaderDate((prev) => addYears(prev, 10));
        setYear(year + 10);
        break;
    }
  };

  const isToday = (date: number) =>
    isEqual(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), date), selectedDate);

  const setDateValue = (date: number) => () => {
    const newDate = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      date
    );

    setSelectedDate(newDate);
    setDateString(format(newDate, 'dd/MM/yyyy', { locale: ptBR }));
    setShowDatepicker(false);
  };

  const getDayCount = (date: Date) => {
    const daysInMonth = getDaysInMonth(date);

    // find where to start calendar day of week
    const dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 1));
    const blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  };

  const isSelectedMonth = (month: number) =>
    isEqual(new Date(selectedDate.getFullYear(), month, selectedDate.getDate()), selectedDate);

  const setMonthValue = (month: number) => () => {
    setDatepickerHeaderDate(
      new Date(datepickerHeaderDate.getFullYear(), month, datepickerHeaderDate.getDate())
    );
    setType('date');
  };

  const setYearValue = (year: number) => () => {
    setDatepickerHeaderDate(
      new Date(year, datepickerHeaderDate.getMonth(), datepickerHeaderDate.getDate())
    );
    setYear(year);
    setType('month');
  };

  const toggleDatepicker = () => {
    clearError();
    setShowDatepicker((prev) => !prev);
  };

  const showMonthPicker = () => setType('month');

  const showYearPicker = (number: number) => {
    if (type === 'year') {
      setType('month');
    } else {
      setType('year');
    }
    setYear(number);
  };

  const mountPreviousYear = -12;

  const getPreviousYear = (number: number) => {
    const currentYear = year;
    const previousYear = mountPreviousYear + number;

    return currentYear + previousYear;
  };

  const setValueDatepicker = (value: any) => {
    const newValue = new Date(value);
    if (newValue) setSelectedDate(new Date(newValue));
    const dateToString = convertDateToString(newValue);
    setDatepickerHeaderDate(newValue);
    setDateString(dateToString);
    setType('date');
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef,
      setValue: (ref: any, value: any) => {
        setValueDatepicker(value);
      },
      getValue: () => {
        return convertToDate(datepickerRef.current.value);
      },
      clearValue: () => {
        setDateString('');
        setSelectedDate(new Date());
        setDatepickerHeaderDate(new Date());
        clearError();
      }
    });

    if (disabled) {
      setDateString('');
      setSelectedDate(new Date());
      clearError();
    }
  }, [fieldName, registerField, disabled]);

  useEffect(() => {
    const handleHideDropdown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowDatepicker(false);
      }
    };

    const handleClickedOutside = (e: any) => {
      if (showDatepicker && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setShowDatepicker(false);
      }
    };
    document.addEventListener('keydown', handleHideDropdown);
    document.addEventListener('mousedown', handleClickedOutside);

    return () => {
      document.removeEventListener('keydown', handleHideDropdown);
      document.removeEventListener('mousedown', handleClickedOutside);
    };
  }, [showDatepicker]);

  useEffect(() => {
    getDayCount(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  useEffect(() => {
    const date = Date.parse(datepickerRef.current?.props.value);
    if (isNaN(date) == false) {
      setValueDatepicker(datepickerRef.current?.props.value);
    } else {
      setSelectedDate(new Date());
    }
  }, []);

  return (
    // <div className="antialiased flex flex-col" ref={wrapperRef}>
    <div className="flex flex-col text-sm mt-2" ref={wrapperRef}>
      <label
        htmlFor={name}
        className={`py-1 font-medium ${error ? ' text-red-500' : 'text-stone-800'}`}
      >
        {label}
      </label>
      <div
        className={`flex border rounded justify-between items-center bg-white relative mt-1 ${
          error
            ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
            : 'text-gray-500 border-gray-200 focus:border-teal-600 focus:ring-teal-600'
        }`}
      >
        {/* <div className="flex items-center"> */}
        {/* <input type="hidden" name={name} /> */}
        <ReactInputMask
          id={name}
          name={fieldName}
          mask="99/99/9999"
          ref={datepickerRef}
          placeholder="Selecionar data"
          onChange={handleChange}
          alwaysShowMask={true}
          value={dateString}
          disabled={disabled}
          className={`w-full font-medium px-2 py-2 mt-1 p-2
          ${disabled ? 'cursor-default border-gray-200 text-gray-200' : 'cursor-pointer'} ${
            error && 'border-red-500 focus:border-red-500 focus:ring-red-500'
          }
                  ${
                    !disabled &&
                    !error &&
                    'text-stone-500 border-gray-400 focus:border-teal-600 focus:ring-teal-600'
                  }
                  `}
        />
        <div
          className={`mr-2 ${disabled ? 'cursor-default text-gray-200' : 'cursor-pointer'}`}
          onClick={disabled ? undefined : toggleDatepicker}
        >
          <svg
            className={`h-6 w-6 ${disabled ? 'cursor-default text-gray-200' : 'text-gray-400'}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        {/* </div> */}
        {showDatepicker && (
          <div className="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0 z-20 w-60 sm:w-[272px]">
            <div className="flex justify-between items-center mb-2">
              {/* <div> */}
              <button
                type="button"
                className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                onClick={decrement}
              >
                {type === 'year' ? (
                  <div>
                    <svg
                      className="h-6 w-6 text-gray-500 inline-flex"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                    <span className="font-semibold text-xs text-gray-500 pr-2">-10</span>
                  </div>
                ) : (
                  <svg
                    className="h-6 w-6 text-gray-500 inline-flex"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                )}
              </button>
              {/* </div> */}
              {type === 'date' && (
                <div
                  onClick={showMonthPicker}
                  className="flex-grow p-1 text-lg font-bold text-gray-600 cursor-pointer hover:bg-teal-100 rounded-lg"
                >
                  <p className="text-center capitalize">
                    {format(datepickerHeaderDate, 'MMMM', { locale: ptBR })}
                  </p>
                </div>
              )}
              <div
                onClick={() => showYearPicker(year)}
                className={`flex-grow p-1 text-lg font-bold  cursor-pointer hover:bg-gray-200 rounded-lg ${
                  type === 'year'
                    ? 'bg-teal-600 text-white hover:bg-teal-600'
                    : 'text-gray-600 hover:bg-teal-100'
                }`}
              >
                <p className="text-center">{format(datepickerHeaderDate, 'yyyy')}</p>
              </div>
              <div>
                <button
                  type="button"
                  className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                  onClick={increment}
                >
                  {type === 'year' ? (
                    <div>
                      <span className="font-semibold text-xs text-gray-500 pl-2">+10</span>
                      <svg
                        className="h-6 w-6 text-gray-500 inline-flex"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  ) : (
                    <svg
                      className="h-6 w-6 text-gray-500 inline-flex"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            {type === 'date' && (
              <>
                <div className="flex flex-wrap mb-3 -mx-1">
                  {DAYS.map((day, i) => (
                    <div key={i} style={{ width: '14.26%' }} className="px-1">
                      <div className="text-gray-600 font-medium text-center text-xs">{day}</div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-wrap -mx-1">
                  {blankDays.map((_, i) => (
                    <div
                      key={i}
                      style={{ width: '14.26%' }}
                      className="text-center border p-1 border-transparent text-sm"
                    ></div>
                  ))}
                  {dayCount.map((d, i) => (
                    <div key={i} style={{ width: '14.26%' }} className="px-1 mb-1">
                      <div
                        onClick={setDateValue(d)}
                        className={`cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100 ${
                          isToday(d) ? 'bg-teal-600 text-white' : 'text-gray-600 hover:bg-teal-100'
                        }`}
                      >
                        {d}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {type === 'month' && (
              <div className="flex flex-wrap -mx-1">
                {Array(12)
                  .fill(null)
                  .map((_, i) => (
                    <div key={i} onClick={setMonthValue(i)} style={{ width: '25%' }}>
                      <div
                        className={`cursor-pointer capitalize p-5 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                          isSelectedMonth(i)
                            ? 'bg-teal-600 text-white hover:bg-teal-600'
                            : 'text-gray-600 hover:bg-teal-100'
                        }`}
                      >
                        {format(
                          new Date(
                            datepickerHeaderDate.getFullYear(),
                            i,
                            datepickerHeaderDate.getDate()
                          ),
                          'MMM',
                          { locale: ptBR }
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}{' '}
            {type === 'year' && (
              <div className="flex flex-wrap -mx-1">
                {Array(12)
                  .fill(null)
                  .map((_, i) => (
                    <div
                      key={i}
                      onClick={setYearValue(getPreviousYear(i))}
                      style={{ width: '25%' }}
                    >
                      <div
                        className={`cursor-pointer capitalize py-5 px-4 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 text-gray-600 hover:bg-teal-100'
                              `}
                      >
                        {getPreviousYear(i)}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
      {error && <span className="text-red-500 text-xs mt-1 ml-1">{error}</span>}
    </div>
    // </div>
  );
};
export default DatepickerForm;
