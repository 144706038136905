import { ArrowLeftIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import parse, { DOMNode, domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import { useAuth } from '../../../auth/contexts/AuthProvider';
import { LOCAL_KEY_OPPORTUNITY_ID } from '../../../_shared/constants/LocalStorage.constants';
import Routes from '../../../_shared/constants/Routes.enum';
import { formatDateString } from '../../../_shared/helpers/FormatDateHelper';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { DiscloseOpportunity } from '../../models/DiscloseOpportunity';
import DiscloseOpportunitiesService from '../../service/DiscloseOpportunitiesService';
import ModalLogInUserDisclosed from './components/ModalLogInUserDisclosed';

type ParamsProps = { id: string };

type Props = {
  discloseOpportunityId?: string;
  goBack?: () => void;
  onClickConfirm?: () => void;
  isApplicationOppotunity?: boolean;
};

const DiscloseOpportunityDetail = ({
  discloseOpportunityId,
  goBack,
  onClickConfirm,
  isApplicationOppotunity
}: Props) => {
  const formRef = useRef<FormHandles>(null);
  const [discloseOpportunity, setDiscloseOpportunity] = useState<DiscloseOpportunity>();
  const { id } = useParams<ParamsProps>();
  const [discloseOpportunityIdActive, setDiscloseOpportunityIdActive] = useState(
    discloseOpportunityId || id
  );
  const { user, signed } = useAuth();
  const [enabled, setEnabled] = useState(false);
  const history = useHistory();

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorReponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorReponse);
    toast.error(error);
  };

  const loadDiscloseOpportunity = async () => {
    try {
      const result = await DiscloseOpportunitiesService.getDiscloseOpportunityDetail(
        discloseOpportunityIdActive
      );
      setDiscloseOpportunity(result.data);
    } catch (error) {
      handleErrors(error);
    }
  };

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element && domNode.tagName === 'ul') {
        return (
          <ul role="list" className="list-disc mt-3 pl-5 sm:pl-10 space-y-0.5">
            {domToReact(domNode.children, options)}
          </ul>
        );
      }
      if (domNode instanceof Element && domNode.tagName === 'li') {
        return <li className="text-sm text-gray-500">{domToReact(domNode.children, options)}</li>;
      }

      if (domNode instanceof Element && domNode.children.length < 1) {
        return <br />;
      }
    }
  };

  const handleGoBack = () => {
    if (goBack) return goBack();
    history.goBack();
  };

  const handleClickConfirm = () => {
    if (onClickConfirm) return onClickConfirm();
    history.push({
      pathname: Routes.LOGIN,
      state: discloseOpportunityIdActive
    });
  };

  const handleClickNewLogInUserDisclosed = () => {
    if (discloseOpportunity)
      localStorage.setItem(LOCAL_KEY_OPPORTUNITY_ID, JSON.stringify(discloseOpportunity?.id));
    setEnabled(true);
  };

  const handleCloseNewLogInUserDisclosed = () => {
    setEnabled(false);
  };

  useEffect(() => {
    if (discloseOpportunityIdActive) loadDiscloseOpportunity();
  }, [discloseOpportunityIdActive]);

  return (
    <div className={` ${!user?.candidate && 'pt-16'}`}>
      <div className="flex flex-row justify-between">
        <div>
          <h1 className={`text-3xl font-semibold text-teal-600 ${user?.candidate && 'mt-3'}`}>
            {discloseOpportunity?.title}
          </h1>
          <h3 className="font-medium text-gray-400">
            Vaga disponível até{' '}
            {discloseOpportunity &&
              formatDateString(new Date(discloseOpportunity?.endDateOpportunity))}
          </h3>
        </div>
      </div>
      <div className="py-8 flex flex-col">
        <span>
          <strong>{discloseOpportunity?.occupationArea.name}</strong>
        </span>
        <h3 className="font-medium text-gray-500">
          {discloseOpportunity?.workspaceOpportunity.name} -{' '}
          {discloseOpportunity?.typeOpportunity.name}
        </h3>
      </div>
      <div>
        {discloseOpportunity?.descriptionOpportunity &&
          parse(discloseOpportunity?.descriptionOpportunity, options)}
      </div>
      <div className="sm:flex hidden justify-end flex-row mt-6 gap-2">
        <div
          className="bg-gray-100 hover:bg-gray-200 rounded flex flex-row items-center justify-center font-medium text-base cursor-pointer px-3 py-1.5 my-4"
          onClick={handleGoBack}
        >
          <ArrowLeftIcon className="w-3 h-3 mr-1" />
          <span className="text-xs">Voltar</span>
        </div>
        {!isApplicationOppotunity && !user?.isAdmin && (
          <div className="items-center px-1 py-4 gap-3 w-full sm:w-auto justify-end">
            <Button
              variant="primary"
              type="button"
              onClick={signed ? handleClickConfirm : handleClickNewLogInUserDisclosed}
            >
              Candidate-se
            </Button>
          </div>
        )}
      </div>

      {/* Responsivo */}
      <div className="sm:hidden flex flex-col justify-center items-center mx-2 gap-3 mt-3 font-medium text-base">
        <div
          className="bg-gray-100 hover:bg-gray-200 rounded flex flex-row items-center justify-center cursor-pointer w-full mx-1 mt-2 px-3 py-2 sm:my-4"
          onClick={handleGoBack}
        >
          <ArrowLeftIcon className="w-3 h-3 mr-1" />
          <span className="text-xs">Voltar</span>
        </div>
        {!isApplicationOppotunity && (
          <div
            className="bg-teal-600 hover:bg-opacity-90 text-white rounded flex flex-row items-center justify-center cursor-pointer w-full mx-1 px-3 py-1"
            onClick={signed ? handleClickConfirm : handleClickNewLogInUserDisclosed}
          >
            Candidate-se
          </div>
        )}
      </div>
      <ModalLogInUserDisclosed isOpen={enabled} onClose={handleCloseNewLogInUserDisclosed} />
    </div>
  );
};

export default DiscloseOpportunityDetail;
