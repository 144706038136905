export enum Area {
  ADMINISTRATIVE = 'administrative',
  COMMERCIAL = 'commercial',
  DEVELOPMENT = 'development',
  SCANNING = 'scanning',
  INFRASTRUCTURE = 'infrastructure',
  RECEPTION = 'reception',
  HUMAN_RESOURCES = 'human resources',
  GENERAL_SERVICES = 'general services',
  TECHNICAL_SUPPORT = 'technical support'
}
