import { Menu } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../auth/contexts/AuthProvider';
import { getStatus, translateStatus } from '../../../../_shared/helpers/StatusHelper';
import { Status } from '../../../enum/Status';

const itemsStatus = getStatus();

type Props = {
  status?: Status;
  onClickChangeStatus: (value: any, item: any) => any;
  itemActive: any;
};

const StatusComponent: React.FC<Props> = ({ status, onClickChangeStatus, itemActive }: Props) => {
  const [itemStatus, setItemStatus] = useState(status);
  const [variant, setVariant] = useState('');
  const { user } = useAuth();

  const getValue = (item: string) => {
    return translateStatus(item);
  };

  const getVariant = (status: string) => {
    switch (status) {
      case Status.NOT_RATED:
        setVariant('not_rated');
        break;
      case Status.NOT_SELECTED:
        setVariant('not_selected');
        break;
      default:
        setVariant(status);
        break;
    }
  };

  useEffect(() => {
    if (status) getVariant(status);
    setItemStatus(status);
  }, [status]);

  return (
    <Menu as="div">
      <Menu.Button className="inline-flex w-28 justify-center rounded-md text-sm font-medium text-white">
        <div>
          <div
            className={`${variant} font-semibold rounded text-xs px-1 py-1 text-center w-28 ${
              !user?.isAdmin ? 'cursor-default' : ''
            }`}
          >
            {translateStatus(itemStatus)}
          </div>
        </div>
      </Menu.Button>
      <div>
        <Menu.Items className="w-28 absolute py-2 mt-2 bg-white rounded shadow-xl z-30">
          {itemsStatus.map((status, index) => (
            <Menu.Item key={index}>
              <span
                onClick={() => onClickChangeStatus(itemActive.id, status)}
                className="block px-2 py-1 text-xs font-semibold text-gray-700 hover:bg-gray-200 cursor-pointer"
              >
                {getValue(status)}
              </span>
            </Menu.Item>
          ))}
        </Menu.Items>
      </div>
    </Menu>
  );
};

export default StatusComponent;
