import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Notify } from '../../../../assets/notify.svg';
import { useAuth } from '../../../auth/contexts/AuthProvider';
import Routes from '../../../_shared/constants/Routes.enum';

const LogoutNotice = () => {
  const { signOut, user } = useAuth();
  const history = useHistory();
  const sourcePath = history.location.search.slice(1);

  const handleClickConfirmLogout = () => {
    signOut();
    history.push(sourcePath);
  };

  const handleClickReturnSession = () => {
    if (user?.isAdmin || user?.isRecruiter) {
      history.push(Routes.LIST_CAREERS);
    } else {
      history.push(Routes.REGISTER_CAREERS);
    }
  };

  return (
    <div className="py-16 flex flex-col items-center justify-center">
      <div className="mt-16 mb-10">
        <Notify className="max-w-[360px] w-auto h-auto" />
      </div>
      <div className="text-center max-w-xl">
        <span className="block text-lg text-stone-500">
          Identificamos que você possui uma sessão ativa em nosso site, para podermos prosseguir é
          necessário encerrá la.
        </span>

        <div className="flex flex-row flex-wrap gap-4 justify-center mt-8">
          <div
            onClick={handleClickReturnSession}
            className="py-1 px-4 bg-gray-400 rounded text-white hover:bg-gray-500 transition-all cursor-pointer"
          >
            Retornar a sessão
          </div>
          <div
            className="py-1 px-4 primary rounded cursor-pointer text-white hover:bg-teal-700 hover:bg-opacity-90 transition-all"
            onClick={handleClickConfirmLogout}
          >
            Sair e continuar
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutNotice;
