import React, { ReactNode } from 'react';

type DashedBoxProps = {
  children: ReactNode;
  title: string;
};

const DashedBox = ({ children, title }: DashedBoxProps) => {
  return (
    <div className="border-dashed border border-gray-300">
      <div className="flex flex-col p-4 sm:p-10 w-full mx-auto">
        <span className="text-lg font-bold text-gray-600 mb-4">{title}</span>
        {children}
      </div>
    </div>
  );
};

export default DashedBox;
