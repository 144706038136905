import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import React from 'react';
import { convertDateToString } from '../../../../_shared/helpers/FormatDateHelper';
import { WorkExperience } from '../../../models/WorkExperience';

interface Props {
  workExperience: WorkExperience;
  onClickEditItem?: (item: any) => void;
  onClickRemoveItem?: (item: any) => void;
}

const WorkExperienceCard: React.FC<Props> = ({
  workExperience,
  onClickEditItem,
  onClickRemoveItem
}) => {
  const havePeriodOfProfessionalExperience = () => {
    return workExperience.initialDate ? (
      <div className="flex flex-row items-center gap-3">
        <div className="text-sm text-gray-700 mt-2">
          {workExperience.initialDate && convertDateToString(new Date(workExperience.initialDate))}
        </div>
        <span className="mt-2">-</span>
        <div className="text-sm text-gray-700 mt-2">
          {!workExperience.currentJob && workExperience.finishDate
            ? convertDateToString(new Date(workExperience.finishDate))
            : 'Até o momento'}
        </div>
      </div>
    ) : null;
  };
  return (
    <div className="flex flex-col w-full rounded">
      <div className="flex flex-col">
        <div className="flex flex-col gap-2">
          <ul role="list" className="list-disc max-h-96 overflow-auto">
            <li className="relative flex flex-col p-3 mb-4 last:mb-0 border border-gray-300 rounded-sm">
              <div className="text-lg font-semibold text-secondary">
                {workExperience.occupation}
              </div>
              <div className="text-sm font-medium text-gray-800">{workExperience.company}</div>
              <div className="text-sm text-gray-700 mt-2">{workExperience.description}</div>
              <div>{havePeriodOfProfessionalExperience()}</div>
              <div className="flex flex-grow absolute top-3 right-3 items-center gap-2">
                <div className="group">
                  <div
                    className="p-1 group-hover:bg-red-500 rounded transition duration-700 ease-in-out cursor-pointer"
                    onClick={onClickRemoveItem}
                  >
                    <TrashIcon className="h-5 w-5 group-hover:text-red-50 text-red-600 transition duration-700 ease-in-out" />
                  </div>
                </div>
                <div className="group">
                  <div
                    className="p-1 group-hover:bg-gray-400 rounded transition duration-700 ease-in-out cursor-pointer"
                    onClick={onClickEditItem}
                  >
                    <PencilIcon className="h-5 w-5 group-hover:text-gray-50 text-teal-600 transition duration-700 ease-in-out " />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WorkExperienceCard;
