import { ArrowLeftIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import Pagination from '../../../../../components/Paginate/Pagination';
import { Paginate } from '../../../../_shared/types/api.types';
import { PaginateWorkspaceDto } from '../../dtos/PaginateWorkspaceDto';
import { Workspace } from '../../models/Workspace';
import WorkspaceService from '../../service/WorkspaceService';
import WorkspaceModal from './components/WorkspaceModal';
import WorkspaceTable from './components/WorkspaceTable';

const ListWorkspace = () => {
  const [paginationActive, setPaginationActive] = useState<PaginateWorkspaceDto>({});
  const [paginateWorkspace, setPaginateWorkspace] = useState<Paginate<Workspace>>();
  const [openWorkspaceModal, setOpenWorkspaceModal] = useState(false);
  const [itemActive, setItemActive] = useState<Workspace>();

  const loadWorkspace = async () => {
    const result = await WorkspaceService.paginateWorkspace({
      ...paginationActive,
      limit: 10
    });
    setPaginateWorkspace(result);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  const handleEditWorkspace = async (workspaceId?: number) => {
    if (workspaceId) {
      const response = await WorkspaceService.getWorkspace(workspaceId);
      setItemActive(response.data);
    }
    setOpenWorkspaceModal(true);
  };

  const handleNewWorkspace = () => {
    setItemActive(undefined);
    setOpenWorkspaceModal(true);
  };

  const handleWorkspace = () => {
    loadWorkspace();
  };

  const handleCloseNewWorkspace = () => {
    setOpenWorkspaceModal(false);
  };

  useEffect(() => {
    loadWorkspace();
  }, [paginationActive]);

  return (
    <div className="pt-16">
      <div className="flex flex-row mb-8">
        <h1 className="text-3xl font-semibold text-teal-600 grow">Locais de Trabalho</h1>
        <div className="flex flex-col mr-4">
          <div
            className="hover:bg-gray-100 rounded flex flex-row items-center justify-end cursor-pointer mx-1 px-3 py-1"
            onClick={() => history.back()}
          >
            <ArrowLeftIcon className="w-3 h-3 mr-1" />
            <span className="text-xs">Voltar</span>
          </div>
          <div className="flex items-center px-1 py-4 gap-3 w-full sm:w-auto justify-end">
            <Button variant="primary" type="button" onClick={handleNewWorkspace}>
              Novo
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <WorkspaceTable
          workspace={paginateWorkspace?.results}
          onClickEditWorkspace={handleEditWorkspace}
        />
      </div>

      <div className="mt-4">
        <Pagination
          currentPageLength={paginateWorkspace?.results.length}
          page={paginateWorkspace?.currentPage}
          pageSize={paginateWorkspace?.limit}
          onChangePage={onChangePage}
          totalItems={paginateWorkspace?.totalItems}
        />
      </div>

      <WorkspaceModal
        workspace={itemActive}
        isOpen={openWorkspaceModal}
        onClose={handleCloseNewWorkspace}
        onConfirm={handleWorkspace}
        modalText={itemActive?.id ? 'Editar Local de Trabalho' : 'Nova Local de Trabalho'}
      />
    </div>
  );
};

export default ListWorkspace;
