import { ArrowLeftIcon } from '@heroicons/react/solid';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as EmailSend } from '../../../../assets/emailSend.svg';
import Routes from '../../../_shared/constants/Routes.enum';

const EmailConfirmationNotice = () => {
  const { state: email } = useLocation();
  return (
    <div className="py-16 flex flex-col items-center justify-center">
      <div className="mb-16">
        <EmailSend className="max-w-[260px] w-full h-auto" />
      </div>
      <div className="text-center max-w-2xl">
        <span className="block text-3xl mb-6">Email de verificação enviado com sucesso!</span>
        <div className="max-w-xl">
          <span className="block text-xl text-stone-500">
            Foi enviado um email de confirmação para o endereço{' '}
            <span className="font-bold text-teal-600">{email as any}</span>, seu cadastro só será
            concluído após a confirmação do mesmo.
          </span>
        </div>

        <div className="flex flex-row flex-wrap gap-4 justify-center mt-8">
          <Link to={Routes.LOGIN}>
            <span className="flex flex-row items-center py-1 px-4 rounded text-gray-500 hover:bg-gray-200 transition-all cursor-pointer">
              <ArrowLeftIcon className="w-3 h-3 mr-1" />
              Retornar para pagina inicial
            </span>
          </Link>
          <a
            href={process.env.REACT_APP_URL_SITE_PSG}
            target="_blank"
            rel="noreferrer"
            className="py-1 px-4 bg-gray-400 rounded text-white hover:bg-gray-500 transition-all"
          >
            Ir para o site
          </a>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmationNotice;
