import { City } from '../models/City';
import { State } from '../models/State';
import { Result } from '../types/api.types';
import api from './api';

class LocationService {
  public async findState(): Promise<Result<State[]>> {
    const response = await api.get('/location/state');
    return response.data;
  }

  public async findCitiesByState(stateId: string): Promise<Result<City[]>> {
    const response = await api.get(`/location/cities/${stateId}`);
    return response.data;
  }
}

export default new LocationService();
