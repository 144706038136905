import { DownloadIcon, EyeIcon, TrashIcon } from '@heroicons/react/solid';
import React from 'react';
import Button from '../../../../../../components/Button';
import { DiscloseOpportunity } from '../../../../../discloseOpportunities/models/DiscloseOpportunity';
import DiscloseOpportunityItemLabel from '../../../../../discloseOpportunities/pages/ListDisclosedOpportunities/components/DiscloseOpportunityItemLabel';
import { Opportunity } from '../../../../../opportunities/Opportunities/models/Opportunity';

type Props = {
  opportunity: Opportunity;
  onClick: (discloseOpportunity: DiscloseOpportunity) => void;
  onClickQuitOpportunity?: (discloseOpportunity: DiscloseOpportunity) => void;
};

const ApplicationOpportunityCard = ({ opportunity, onClick, onClickQuitOpportunity }: Props) => {
  const handleClickViewOpportunity = () => {
    onClick(opportunity);
  };

  const handleClickQuitOpportunity = () => {
    if (onClickQuitOpportunity) onClickQuitOpportunity(opportunity);
  };

  return (
    <div>
      <div
        className={`${
          !opportunity.isActive && 'bg-gray-100 opacity-60'
        } rounded lg:flex hidden flex-col md:flex-row border-dashed border md:items-center md:text-left border-slate-300 md:h-14 h-auto px-6 py-2`}
      >
        <div
          className={`flex flex-1 text-lg ${
            opportunity.isActive ? 'text-teal-600' : 'text-gray-600'
          }`}
        >
          <strong>{opportunity.title}</strong>
        </div>
        <div className="flex flex-col md:flex-row md:min-w-[420px] md:text-left md:items-center gap-12 lg:gap-16">
          <div className="flex md:flex-col md:min-w-[214px]">
            <h3 className="font-semibold text-gray-700">{opportunity.occupationArea.name}</h3>
          </div>
          <div className="flex md:flex-col md:min-w-[192px]">
            <h3 className="font-medium text-sm text-gray-500">
              {opportunity.workspaceOpportunity.name}/{opportunity.typeOpportunity.name}
            </h3>
          </div>
        </div>
        {opportunity.isActive && (
          <div className="flex ml-2 gap-3">
            <EyeIcon
              className="h-5 w-5 text-gray-600 cursor-pointer"
              onClick={handleClickViewOpportunity}
            />
            <TrashIcon
              className=" h-5 w-5 text-red-600 cursor-pointer"
              onClick={handleClickQuitOpportunity}
            />
          </div>
        )}
      </div>
      <div
        className={`${
          !opportunity.isActive && 'bg-gray-100 opacity-60'
        } rounded flex lg:hidden flex-col lg:flex-row border-dashed border lg:items-center lg:text-center border-slate-300 lg:h-14 h-auto px-6 py-2 hover:cursor-pointer hover:bg-gray-100 hover:bg-opacity-75`}
      >
        <div
          className={`flex flex-1 mb-2 sm:mb-2 text-lg ${
            opportunity.isActive ? 'text-teal-600' : 'text-gray-600'
          }`}
        >
          <strong>{opportunity.title}</strong>
        </div>
        <div className="flex flex-col lg:flex-row lg:min-w-[420px] lg:text-left lg:items-center gap-2 lg:gap-20">
          <div className="flex flex-col lg:min-w-[192px]">
            <h3 className="font-semibold text-gray-700">{opportunity.occupationArea.name}</h3>
            <DiscloseOpportunityItemLabel title={'Área'} />
          </div>
          <div className="flex flex-col lg:min-w-[192px]">
            <h3 className="font-medium text-sm text-gray-500">
              {opportunity.workspaceOpportunity.name}/{opportunity.typeOpportunity.name}
            </h3>
            <DiscloseOpportunityItemLabel title={'Local'} />
          </div>
        </div>
        {opportunity.isActive && (
          <div className="flex mt-7 justify-center">
            <div className="flex flex-col md:flex-row gap-2 w-full mt-[-16px]">
              <Button styles="w-full" variant="default" onClick={handleClickViewOpportunity}>
                Visualizar
              </Button>
              <Button styles="w-full" variant="primary" onClick={handleClickQuitOpportunity}>
                Desistir
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationOpportunityCard;
