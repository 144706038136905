import React from 'react';
type Props = {
  disabled?: boolean;
  active: boolean;
  name: string;
};

const CandidateItemMenu = ({ disabled, active, name }: Props) => {
  return (
    <div
      className={`flex items-center justify-center sm:justify-start ${
        !disabled && !active
          ? 'hover:bg-gray-100 hover:rounded cursor-pointer '
          : active
          ? 'cursor-default'
          : 'cursor-not-allowed'
      }  sm:text-left text-center`}
    >
      <span
        className={`${
          active
            ? 'sm:bg-inherit sm:text-teal-600 bg-teal-600 text-white'
            : 'sm:text-gray-600 sm:bg-inherit bg-gray-400 text-white'
        } ${
          disabled ? 'sm:text-gray-400 opacity-40 text-white sm:opacity-50' : ''
        } font-semibold text-xl sm:py-1.5 py-2.5 px-2.5 rounded sm:rounded-none w-full sm:w-auto`}
      >
        {name}
      </span>
    </div>
  );
};

export default CandidateItemMenu;
