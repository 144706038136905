enum Routes {
  HOME = '/',
  REGISTER_CAREERS = '/careers',
  REGISTER_CAREERS_FINISHED = '/careers/finished',
  LIST_CAREERS = '/list-careers',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  CREATE_USERS = '/users',
  CREATE_TYPE_OPPORTUNITY = '/type-opportunity',
  CREATE_OPPORTUNITY = '/opportunity',
  EDIT_OPPORTUNITY = '/opportunity/:id',
  LIST_USERS = '/list-users',
  LIST_TYPE_OPPORTUNITY = '/list-type-opportunity',
  LIST_WORKSPACE = '/list-workspace',
  LIST_OCCUPATION_AREA = '/list-occupation-area',
  LIST_OPPORTUNITY = '/list-opportunity',
  NOTIFY_CONFIRMATION_EMAIL = '/notify/confirmation-email',
  NOTIFY = '/notify',
  DISCLOSE_OPPORTUNITIES = '/disclose-opportunities',
  DISCLOSE_OPPORTUNITIES_DETAIL = '/disclose-opportunities/:id',
  LIST_JOBS = '/list-jobs',
  EDIT_JOB = '/edit-job-profile'
}

export default Routes;
