import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

type EditorProps = {
  setContent: (value: string) => void;
  placeholder: string;
  textEditorValue?: string;
};

const TextEditor = ({ setContent, placeholder, textEditorValue }: EditorProps) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const onChangeEditor = () => {
    setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    if (!textEditorValue) return;
    const blocksFromHTML = htmlToDraft(textEditorValue);

    const textEditor = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
    setEditorState(EditorState.createWithContent(textEditor));
  }, [textEditorValue]);

  return (
    <Editor
      editorStyle={{
        padding: 5,
        borderRadius: 2,
        border: '1px solid rgb(241, 241, 241)',
        minHeight: 420,
        wordBreak: 'break-word'
      }}
      stripPastedStyles={true}
      editorState={editorState}
      wrapperClassName="card"
      editorClassName="card-body"
      onEditorStateChange={(editorState) => {
        setEditorState(editorState);
        onChangeEditor();
      }}
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'list',
          'textAlign',
          'history',
          'embedded',
          'emoji',
          'image',
          'colorPicker'
        ],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true }
      }}
      placeholder={placeholder}
    />
  );
};

export default TextEditor;
