import React from 'react';

type Props = {
  title: string;
};

const DiscloseOpportunityItemLabel = ({ title }: Props) => {
  return (
    <div className="flex">
      <span className="text-sm font-medium text-gray-400">{title}</span>
    </div>
  );
};

export default DiscloseOpportunityItemLabel;
