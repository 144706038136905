import { ArrowLeftIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import Pagination from '../../../../../components/Paginate/Pagination';
import { Paginate } from '../../../../_shared/types/api.types';
import { PaginateTypeOpportunityDto } from '../../dtos/PaginateTypeOpportunityDto';
import { TypeOpportunity } from '../../models/TypeOpportunity';
import TypeOpportunityService from '../../service/TypeOpportunityService';
import TypeOpportunityModal from './components/TypeOpportunityModal';
import TypeOpportunityTable from './components/TypeOpportunityTable';

const ListTypeOpportunity = () => {
  const [openTypeOpportunityModal, setOpenTypeOpportunityModal] = useState(false);
  const [paginationActive, setPaginationActive] = useState<PaginateTypeOpportunityDto>({});
  const [paginateTypeOpportunity, setPaginateTypeOpportunity] =
    useState<Paginate<TypeOpportunity>>();
  const [itemActive, setItemActive] = useState<TypeOpportunity>();

  const loadTypeOpportunity = async () => {
    const result = await TypeOpportunityService.paginateTypeOpportunity({
      ...paginationActive,
      limit: 10
    });
    setPaginateTypeOpportunity(result);
  };

  const onChangePage = async (page: number) => {
    setPaginationActive((old) => ({ ...old, page }));
  };

  const handleClickEditTypeOpportunity = async (typeOpportunityId?: number) => {
    if (typeOpportunityId) {
      const response = await TypeOpportunityService.getTypeOpportunity(typeOpportunityId);
      setItemActive(response.data);
    }
    setOpenTypeOpportunityModal(true);
  };

  const handleClickNewTypeOpportunity = () => {
    setItemActive(undefined);
    setOpenTypeOpportunityModal(true);
  };

  const handleTypeOpportunity = () => {
    loadTypeOpportunity();
  };

  const handleCloseTypeOpportunity = () => {
    setOpenTypeOpportunityModal(false);
  };

  useEffect(() => {
    loadTypeOpportunity();
  }, [paginationActive]);

  return (
    <div className="pt-16">
      <div className="flex flex-row mb-8">
        <h1 className="text-3xl font-semibold text-teal-600 grow">Tipos de Oportunidades</h1>
        <div className="flex flex-col mr-4">
          <div
            className="hover:bg-gray-100 rounded flex flex-row items-center justify-end cursor-pointer mx-1 px-3 py-1"
            onClick={() => history.back()}
          >
            <ArrowLeftIcon className="w-3 h-3 mr-1" />
            <span className="text-xs">Voltar</span>
          </div>
          <div className="flex items-center px-1 py-4 gap-3 w-full sm:w-auto justify-end">
            <Button variant="primary" type="button" onClick={handleClickNewTypeOpportunity}>
              Novo
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <TypeOpportunityTable
          vague={paginateTypeOpportunity?.results}
          onClickEditTypeOpportunity={handleClickEditTypeOpportunity}
        />
      </div>

      <div className="mt-4">
        <Pagination
          currentPageLength={paginateTypeOpportunity?.results.length}
          page={paginateTypeOpportunity?.currentPage}
          pageSize={paginateTypeOpportunity?.limit}
          onChangePage={onChangePage}
          totalItems={paginateTypeOpportunity?.totalItems}
        />
      </div>

      <TypeOpportunityModal
        typeOpportunity={itemActive}
        isOpen={openTypeOpportunityModal}
        onClose={handleCloseTypeOpportunity}
        onConfirm={handleTypeOpportunity}
        modalText={itemActive?.id ? 'Editar Tipo de Vaga' : 'Novo Tipo de Vaga'}
      />
    </div>
  );
};

export default ListTypeOpportunity;
