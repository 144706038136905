import { Dialog, Transition } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  getErrorMessage,
  getFieldErrors,
  manageApiErrorResponse
} from '../../modules/_shared/helpers/handleApiErrorResponse';
import { InviteUserDto } from '../../modules/users/dtos/InviteUserDto';
import userService from '../../modules/users/service/userService';
import Button from '../Button';
import InputForm from '../FormComponents/InputForm';
import SelectForm from '../FormComponents/SelectForm';

const profilesUser = [
  { label: 'Administrador', value: 'admin' },
  { label: 'Recrutador', value: 'recruiter' }
];

interface ConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InviteModal = ({ isOpen, onClose }: ConfigModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const [inviteUser, setInviteUser] = useState(true);

  const sendtInviteUser = async () => {
    try {
      const inviteUserDto = formRef.current?.getData() as InviteUserDto;
      if (inviteUser) {
        const result = await userService.inviteUser(inviteUserDto);
        toast.success(result.message);
      } else {
        const result = await userService.resendInviteUser(inviteUserDto.email);
        toast.success(result.message);
      }
      onClose();
      clearForm();
    } catch (resultError: unknown) {
      handleErrors(resultError);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorResponse);
    toast.error(error);
  };

  const handleCancel = () => {
    onClose();
    clearForm();
    setInviteUser(false);
  };

  const clearForm = () => {
    formRef.current?.reset();
    formRef.current?.setErrors({});
  };

  const handleClickOptionModal = () => {
    setInviteUser(!inviteUser);
    clearForm();
  };

  useEffect(() => {
    return () => {
      setInviteUser(true);
    };
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-lg h-auto m-auto">
                <Form ref={formRef} onSubmit={sendtInviteUser} className="flex justify-center">
                  <div className="relative bg-white rounded-lg shadow w-10/12 ">
                    <div className="flex justify-between items-start p-6 rounded-t border-b">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        {inviteUser ? 'Convidar novo usuário' : 'Reenviar convite'}
                      </span>
                    </div>
                    <div className="flex px-6 mt-4">
                      <p className="text-sm">
                        Cada convite é valido por 24 hrs, podendo ser gerado novamente após esse
                        período.
                      </p>
                    </div>
                    <div className="px-6 mb-3 space-y-3 ">
                      <InputForm
                        name="email"
                        type="string"
                        label="E-mail*"
                        placeholder="Digite um e-mail"
                      />
                      {inviteUser && (
                        <SelectForm name="profile" label="Perfil*" options={profilesUser} />
                      )}
                    </div>
                    <div className="flex px-6 mb-3 ">
                      {inviteUser ? (
                        <p
                          className="flex flex-row items-center font-medium text-sm text-teal-600 cursor-pointer"
                          onClick={handleClickOptionModal}
                        >
                          Reenviar um convite?
                        </p>
                      ) : (
                        <p
                          className="flex flex-row items-center font-medium text-sm text-teal-600 cursor-pointer"
                          onClick={handleClickOptionModal}
                        >
                          Enviar um novo convite?
                        </p>
                      )}
                    </div>
                    <div className="flex items-center  p-6 space-x-3 rounded-b border-t border-gray-200 justify-end">
                      <Button variant="default" type="button" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      <Button variant="primary" type="button" onClick={sendtInviteUser}>
                        {inviteUser ? 'Confirmar' : 'Enviar'}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InviteModal;
