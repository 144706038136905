import { Dialog, Transition } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../../components/Button';
import DatepickerForm from '../../../../../components/FormComponents/DatepickerForm';
import InputForm from '../../../../../components/FormComponents/InputForm';
import TextareaForm from '../../../../../components/FormComponents/TextareaForm';
import { Report } from '../../../../../infra/Report';
import { getFieldErrorsInReport } from '../../../../_shared/helpers/handleApiErrorResponse';
import { CreateWorkExperienceContract } from '../../../contracts/CreateWorkExperienceContract';
import { WorkExperience } from '../../../models/WorkExperience';

type ConfirmProps = { workExperience: WorkExperience; isEdit: boolean };

interface ConfigModalProps {
  isOpen: boolean;
  workExperienceToEdit?: WorkExperience;
  onClose: () => void;
  onConfirm: (confirmProps: ConfirmProps) => void;
}

const NewWorkExperienceModal = ({
  isOpen,
  onClose,
  onConfirm,
  workExperienceToEdit
}: ConfigModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const [currentJob, setCurrentJob] = useState(false);

  const addNewExperience = () => {
    try {
      const workExperience = {
        ...formRef.current?.getData(),
        currentJob: currentJob,
        id: workExperienceToEdit?.id
      } as WorkExperience;

      const contract = new CreateWorkExperienceContract(workExperience);
      contract.validate();
      if (!contract.isValid()) {
        throw contract.reports;
      }

      const isAEditWorkExperience = !!workExperienceToEdit;

      onConfirm({
        workExperience,
        isEdit: isAEditWorkExperience
      });
      onClose();
      clearForm();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrorsInReport(resultError as Report[]);
    formRef.current?.setErrors(fieldsErrors);

    toast.error('Erro ao adicionar experiência profissional.');
  };

  const handleCancel = () => {
    onClose();
    clearForm();
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  const handleChangeCurrentJob = (event: any) => {
    setCurrentJob(event.target.checked);
  };

  useEffect(() => {
    if (workExperienceToEdit) {
      formRef.current?.setData(workExperienceToEdit);
      formRef.current?.setFieldValue('currentJob', workExperienceToEdit.currentJob);
      setCurrentJob(workExperienceToEdit.currentJob);
    } else {
      setCurrentJob(false);
    }
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed md:top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-2xl h-auto m-auto">
                <Form
                  ref={formRef}
                  initialData={workExperienceToEdit}
                  onSubmit={addNewExperience}
                  className="flex justify-center"
                >
                  <div className="relative bg-white rounded-lg shadow md:w-10/12 ">
                    <div className="flex justify-between items-start p-6 rounded-t border-b">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        Nova experiência profissional
                      </span>
                    </div>
                    <div className="p-6 space-y-3">
                      <InputForm
                        name="company"
                        type="string"
                        label="Empresa*"
                        placeholder="Digite o nome da empresa"
                      />
                      <InputForm
                        name="occupation"
                        type="string"
                        label="Cargo*"
                        placeholder="Digite o cargo"
                      />
                      <div>
                        <div className="flex flex-col text-sm">
                          <div>
                            <label className="py-1 font-medium text-gray-500">
                              <input
                                className="accent-teal-600"
                                type="checkbox"
                                checked={currentJob}
                                onChange={handleChangeCurrentJob}
                              />
                              <span className="py-1 ml-2 font-mediumtext-gray-500">
                                {' '}
                                Trabalho atualmente neste cargo
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 justify-between">
                        <DatepickerForm name="initialDate" label="Data de início*" />
                        <DatepickerForm
                          name="finishDate"
                          label="Data de término*"
                          disabled={currentJob}
                        />
                      </div>
                      <TextareaForm
                        label="Descrição das atividades*"
                        name="description"
                        placeholder="Descreva brevemente suas atividades."
                        cols={2}
                        rows={4}
                        maxLength={3000}
                      ></TextareaForm>
                    </div>
                    <div className="flex items-center justify-end p-6 space-x-3 rounded-b border-t border-gray-200">
                      <Button variant="default" type="button" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      <Button variant="primary" type="button" onClick={addNewExperience}>
                        Salvar
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewWorkExperienceModal;
