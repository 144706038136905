import { InformationCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import React from 'react';
import { AlertType } from '../../modules/careers/enum/Alert';

interface AlertProps {
  text: string;
  variant?: AlertType;
}

const Alert: React.FC<AlertProps> = ({ text, variant = AlertType.ERROR }) => {
  const alertIcon = () => {
    switch (variant) {
      case AlertType.ERROR:
        return <XCircleIcon className="h-6 w-6 mr-2 text-write" />;
      case AlertType.WARNING:
        return <InformationCircleIcon className="h-6 w-6 mr-2 text-write" />;
      default:
        return;
    }
  };
  return (
    <div className={`flex flex-row items-center px-2 py-2 rounded relative ${variant}`}>
      {alertIcon()}
      <span className="block sm:inline text-sm">{text}</span>
    </div>
  );
};

export default Alert;
