import { OptionSelect } from '../../../components/FormComponents/SelectForm';
import { UniversityEducationType } from '../../careers/enum/Scholarity';

const universityEducationTypeDictionary = [
  { pt: 'Graduação', key: 'graduation' },
  { pt: 'Pós Graduação', key: 'postgraduate' },
  { pt: 'Mestrado', key: "master's degree" },
  { pt: 'Doutorado', key: 'doctorate' },
  { pt: 'Pós-Doutorado', key: 'post doctoral' }
];

export const translateUniversityEducation = (type?: string) => {
  for (const universityEducation of universityEducationTypeDictionary) {
    if (universityEducation.key === type) return universityEducation.pt;
  }
  return '';
};

export const getUniversityEducationOptions = (isFilter?: boolean): OptionSelect[] => {
  const items = Object.values(UniversityEducationType).map((type) => ({
    label: translateUniversityEducation(type),
    value: type as string,
    unavailable: false
  }));
  if (isFilter) {
    items.unshift({ label: 'Tipo', value: '', unavailable: true });
  } else {
    items.unshift({ label: 'Selecione', value: '', unavailable: true });
  }
  return items;
};
