import { DotsVerticalIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import Dropdown from '../Dropdown';

export interface TableColumn {
  columnName?: string;
  key: string;
  transform?: (value: any) => any;
  component?: (columnValue: any, currentItem: any) => any;
  onClickRow?: (value: any) => void;
}

export interface Action {
  label: string;
  onClick: (currentSelected: any) => void;
  keyValidadeOptionDisable?: string;
}

interface TableProps {
  columns?: TableColumn[];
  values?: any[];
  menuItems?: Action[];
}

const Table = ({ columns, values, menuItems }: TableProps) => {
  const [itemActive, setItemActive] = useState<any>();

  const getValueByColumn = (column: TableColumn, value: any) => {
    const keys = column.key.split('.');
    let valueToReturn: any;

    if (keys.length > 1) {
      let currentValue: any;
      for (const key of keys) {
        currentValue = !currentValue ? value[key] : currentValue[key];
      }
      valueToReturn = currentValue;
    } else {
      valueToReturn = value[column.key];
    }

    if (column.transform) {
      valueToReturn = column.transform(valueToReturn);
    }

    if (column.component) {
      valueToReturn = column.component(valueToReturn, value);
    }

    return valueToReturn;
  };

  const handleClickItemMenu = (onClick: (currentSelected: any) => void) => {
    if (itemActive) onClick(itemActive);
  };

  const handleClickOpenMenu = (item: any) => {
    setItemActive(item);
  };

  const handleClickColumnItem = (value: any, column: TableColumn) => {
    if (column.onClickRow) column.onClickRow(value);
  };

  return (
    <div className="w-full overflow-x-auto lg:overflow-x-visible">
      <table className="table border-collapse table-auto lg:w-full w-[1024px] text-sm ">
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th
                key={index}
                className={`border-b font-semibold p-3 text-gray-700 text-left ${
                  column.onClickRow && 'text-center'
                }`}
              >
                {column.columnName}
              </th>
            ))}
            {menuItems && <th className=" border-b font-semibold p-3 text-gray-700 text-left" />}
          </tr>
        </thead>
        <tbody className="bg-white">
          {values?.map((value, index) => (
            <tr key={index} className="hover:bg-gray-100 transition-all ">
              {columns?.map((column, index) => (
                <td
                  onClick={() => handleClickColumnItem(value, column)}
                  key={index}
                  className={` ${menuItems && 'last:w-2'} ${
                    column.onClickRow && 'cursor-pointer text-center hover:font-bold'
                  } border-b border-gray-100 p-3 text-gray-500`}
                >
                  {getValueByColumn(column, value)}
                </td>
              ))}
              {menuItems && (
                <td className=" last:w-2 border-b border-gray-100 p-3 text-gray-500">
                  <Dropdown
                    rowValue={value}
                    menuItens={menuItems}
                    onClick={handleClickItemMenu}
                    size="default"
                  >
                    <DotsVerticalIcon
                      className="w-4 h-4 cursor-pointer text-black"
                      onClick={() => handleClickOpenMenu(value)}
                    />
                  </Dropdown>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
