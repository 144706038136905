import { PlusIcon } from '@heroicons/react/solid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Alert from '../../../../../../components/Alert';
import Button from '../../../../../../components/Button';
import CheckboxForm from '../../../../../../components/FormComponents/CheckboxForm';
import DropzoneForm from '../../../../../../components/FormComponents/DropzoneForm';
import InputForm from '../../../../../../components/FormComponents/InputForm';
import InputMaskForm from '../../../../../../components/FormComponents/InputMaskForm';
import SelectForm, { OptionSelect } from '../../../../../../components/FormComponents/SelectForm';
import TextareaForm from '../../../../../../components/FormComponents/TextareaForm';
import ImagePreviewModal from '../../../../../../components/ImagePreviewModal';
import ConfirmModal from '../../../../../../components/ModalComponents/ConfirmModal';
import { useAuth } from '../../../../../auth/contexts/AuthProvider';
import { CreateUserDto } from '../../../../../users/dtos/CreateUserDto';
import User from '../../../../../users/models/User';
import Routes from '../../../../../_shared/constants/Routes.enum';
import { getAreaOptions } from '../../../../../_shared/helpers/AreaHelper';
import {
  getErrorMessage,
  getErrorMessagesByName,
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../../../_shared/helpers/handleApiErrorResponse';
import { getScholarityOptions } from '../../../../../_shared/helpers/ScholarityHelper';
import locationService from '../../../../../_shared/services/locationService';
import { AlertType } from '../../../../enum/Alert';
import { ScholarityType } from '../../../../enum/Scholarity';
import { UniversityCourse } from '../../../../models/Scholarity';
import { WorkExperience } from '../../../../models/WorkExperience';
import candidateService from '../../../../service/candidateService';
import ChangePassword from '../ChangePassword';
import ImageDetail from '../ImageDetail';
import NewWorkExperienceModal from '../NewExperienceModal';
import PrivacyNoticeTermCheckbox from '../PrivacyNoticeTermCheckbox';
import UniversityEducationForm from '../UniversityEducationForm';
import UniversityEducationItem from '../UniversityEducationItem';
import WorkExperienceCard from '../WorkExperienceCard';
import DashedBox from './components/DashedBox';
import { cnhType } from './enum/cnhType';

const areaList = getAreaOptions();
const scholarityTypeList = getScholarityOptions();
const RESET_INDEX = -1;

const CandidateForm = () => {
  const history = useHistory();
  const { origin } = window.location;
  const { signed, user, setUser } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const [initialDataValue, setInitialDataValue] = useState<User>();
  const [scholarityType, setScholarityType] = useState('');
  const [higherEducation, setHigherEducation] = useState(false);
  const [universityCourses, setUniversityCourses] = useState<UniversityCourse[]>([]);
  const [selectedUniversityCourseIndex, setSelectedUniversityCourseIndex] =
    useState<number>(RESET_INDEX);
  const [workExperiences, setWorkExperiences] = useState<WorkExperience[]>([]);
  const [haveWorkExperience, setHaveWorkExperience] = useState(false);
  const [hasPcd, setHasPcd] = useState(false);
  const [hasCnh, setHasCnh] = useState(false);
  const [canTravel, setCanTravel] = useState(false);
  const [workExperiencesIdsToDelete, setWorkExperiencesIdsToDelete] = useState<string[]>([]);
  const [scholarityErrors, setScholarityErrors] = useState('');
  const [workExperienceErrors, setWorkExperienceErrors] = useState('');
  const [openModalRemoveUniversityCourse, setOpenModalRemoveUniversityCourse] = useState(false);
  const [openNewExperienceModal, setOpenNewExperienceModal] = useState(false);
  const [openModalRemoveExperience, setOpenModalRemoveExperience] = useState(false);
  const [selectedWorkExperienceIndex, setSelectedWorkExperienceIndex] =
    useState<number>(RESET_INDEX);
  const [file, setFile] = useState<File>();
  const [isOpen, setIsOpen] = useState(false);
  const [currentState, setCurrentState] = useState('');
  const [states, setStates] = useState<OptionSelect[]>([]);
  const [cities, setCities] = useState<OptionSelect[]>([]);

  const stateOptions = async () => {
    const { data: states } = await locationService.findState();
    const optionsMapped = states
      .map(({ _id, name }) => ({ value: _id, label: name } as OptionSelect))
      .sort((a, b) => {
        if (a.label === 'Mato Grosso do Sul') return -1;
        if (b.label === 'Mato Grosso do Sul') return 1;
        return a.label.localeCompare(b.label);
      });
    setStates(optionsMapped);
  };

  const citiesOptions = async () => {
    const { data: cities } = await locationService.findCitiesByState(currentState);
    const optionsMapped = cities
      .filter((city) => city.state._id == currentState)
      .map(({ _id, name }) => ({ value: _id, label: name }));
    setCities(optionsMapped);
  };

  const onSubmitFormCandidate = async () => {
    const mainFormData = formRef.current?.getData();
    const originPath = origin + history.createHref({ pathname: Routes.REGISTER_CAREERS_FINISHED });

    const createCandidateDto = mainFormData as CreateUserDto;
    if (createCandidateDto.candidate) {
      createCandidateDto.candidate.workExperiences = workExperiences;
      createCandidateDto.candidate.scholarity.universityCourses = validateScholarityType(
        createCandidateDto.candidate.scholarity.type
      )
        ? universityCourses
        : undefined;
      const phoneNumberFormatted = createCandidateDto.candidate.phoneNumber
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/ /g, '');
      createCandidateDto.candidate.phoneNumber = phoneNumberFormatted;
      createCandidateDto.originPath = originPath;
      createCandidateDto.validationParam = 'verifyCode';
      createCandidateDto.candidate.haveNoWorkExperience = haveWorkExperience;
      createCandidateDto.candidate.state = currentState;
    }

    try {
      if (!signed) {
        const result = await candidateService.create(createCandidateDto);
        history.push({ pathname: Routes.NOTIFY_CONFIRMATION_EMAIL, state: result.data.email });
        return result;
      } else {
        const result = await candidateService.update({
          ...createCandidateDto,
          toDelete: workExperiencesIdsToDelete
        });
        setUser(result.data);
        setWorkExperiencesIdsToDelete([]);
        toast.success(result.message);
        return result;
      }
    } catch (resultError) {
      handleErrors(resultError);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);

    let skipDefaultError = false;
    const messageErrors = getErrorMessagesByName(resultError, 'toDelete');
    for (const messageError of messageErrors) {
      toast.error(messageError);
      skipDefaultError = true;
    }
    if (skipDefaultError) return;

    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(resultErrorResponse);

    const errorScholarity = resultErrorResponse.errors.find((error) =>
      error.name.includes('scholarity')
    );
    if (errorScholarity) {
      setScholarityErrors(errorScholarity.message);
    }

    const errorWorkExperiences = resultErrorResponse.errors.find((error) =>
      error.name.includes('workExperience')
    );
    if (errorWorkExperiences) setWorkExperienceErrors(errorWorkExperiences.message);

    if (signed) {
      resultErrors.map((resultError) => toast.error(resultError));
    } else {
      if (resultErrorResponse.errors[0].name == 'privacyTerm') {
        toast.error(resultErrors[0]);
      } else {
        const error = getErrorMessage(resultErrorResponse);
        toast.error(error);
      }
    }
  };

  const validateScholarityType = (scholarityType: ScholarityType) => {
    if (
      scholarityType === ScholarityType.UNIVERSITY_EDUCATION ||
      scholarityType === ScholarityType.INCOMPLETE_UNIVERSITY_EDUCATION
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleScholaritySelected = (value: any) => {
    const scholarity = String(value);
    setScholarityType(String(value));

    if (
      scholarity.includes(ScholarityType.UNIVERSITY_EDUCATION) ||
      scholarity.includes(ScholarityType.INCOMPLETE_UNIVERSITY_EDUCATION)
    ) {
      setHigherEducation(true);
    } else {
      setHigherEducation(false);
    }
  };

  const getSelectedUniversityCourse = () => {
    return universityCourses[selectedUniversityCourseIndex];
  };

  const handleNewUniversityCourse = ({
    universityCourse,
    isEdit
  }: {
    universityCourse: UniversityCourse;
    isEdit: boolean;
  }) => {
    if (isEdit) {
      const copyUniversityCoursesList = [...universityCourses];
      copyUniversityCoursesList[selectedUniversityCourseIndex] = universityCourse;
      setUniversityCourses(() => copyUniversityCoursesList);
    } else {
      setUniversityCourses((oldList) => {
        const newUniversityCoursesList = oldList.concat(universityCourse);
        return newUniversityCoursesList;
      });
    }
  };

  const handleClearUniversityCourseForm = () => {
    setSelectedUniversityCourseIndex(RESET_INDEX);
  };

  const handleClickRemoveUniversityCourse = (index: number) => {
    setOpenModalRemoveUniversityCourse(true);
    setSelectedUniversityCourseIndex(index);
  };

  const handleRemoveUniversityCourse = () => {
    setUniversityCourses((oldList) => {
      const copyContractList = [...oldList];
      copyContractList.splice(selectedUniversityCourseIndex, 1);
      return copyContractList;
    });
    setOpenModalRemoveUniversityCourse(false);
    setSelectedUniversityCourseIndex(RESET_INDEX);
  };

  const handleClickEditUniversityCourse = (index: number) => {
    setSelectedUniversityCourseIndex(index);
  };

  const handleNewWorkExperience = ({
    workExperience,
    isEdit
  }: {
    workExperience: WorkExperience;
    isEdit: boolean;
  }) => {
    if (isEdit) {
      const copyWorkExperiencesList = [...workExperiences];
      copyWorkExperiencesList[selectedWorkExperienceIndex] = workExperience;
      setWorkExperiences(() => copyWorkExperiencesList);
    } else {
      setWorkExperiences((oldList) => {
        const newWorkExperiencesList = oldList.concat(workExperience);
        return newWorkExperiencesList;
      });
    }
  };

  const handleRemoveWorkExperience = () => {
    if (workExperiences[selectedWorkExperienceIndex].id) {
      setWorkExperiencesIdsToDelete((old) =>
        old.concat(workExperiences[selectedWorkExperienceIndex].id)
      );
    }
    setWorkExperiences((oldList) => {
      const copyWorkExperiencesList = [...oldList];
      copyWorkExperiencesList.splice(selectedWorkExperienceIndex, 1);
      return copyWorkExperiencesList;
    });

    setOpenModalRemoveExperience(false);
    setSelectedWorkExperienceIndex(RESET_INDEX);
  };

  const getSelectedWorkExperience = () => {
    return workExperiences[selectedWorkExperienceIndex];
  };

  const handleClickEditWorkExperience = (index: number) => {
    setOpenNewExperienceModal(true);
    setSelectedWorkExperienceIndex(index);
  };

  const handleClickRemoveWorkExperience = (index: number) => {
    setOpenModalRemoveExperience(true);
    setSelectedWorkExperienceIndex(index);
  };

  const handleCloseNewExperience = () => {
    setOpenNewExperienceModal(false);
    setSelectedWorkExperienceIndex(RESET_INDEX);
  };

  const handleChangeHaveNoWorkExperience = () => {
    setHaveWorkExperience(!haveWorkExperience);
  };

  const handleChangeHasPcd = () => {
    setHasPcd(!hasPcd);
  };

  const handleChangeHasCnh = () => {
    setHasCnh(!hasCnh);
  };

  const handleChangeCanTravel = () => {
    setCanTravel(!canTravel);
  };

  const loadUserForm = async () => {
    if (!user) return;
    try {
      formRef.current?.setData(user);
      setInitialDataValue(user);
      const areaSelected = areaList.find((area) => area.value === user.candidate?.area);
      const scholarityTypeSelected = scholarityTypeList.find(
        (scholarityType) => scholarityType.value === user.candidate?.scholarity?.type
      );

      const state = user?.candidate?.state;
      formRef.current?.setFieldValue('candidate.state', { value: state?._id, label: state?.name });

      formRef.current?.setFieldValue('candidate.area', areaSelected);
      formRef.current?.setFieldValue('candidate.scholarity.type', scholarityTypeSelected);
      setHaveWorkExperience(
        user.candidate?.haveNoWorkExperience ? user.candidate?.haveNoWorkExperience : false
      );

      const city = user?.candidate?.city;
      setTimeout(() => {
        formRef.current?.setFieldValue('candidate.city', { value: city?._id, label: city?.name });
      }, 500);

      const candidate = user.candidate;
      if (candidate?.hasDriverLicense) {
        setHasCnh(candidate.hasDriverLicense);
        setTimeout(() => {
          formRef.current?.setFieldValue(
            'candidate.cnhCategory',
            cnhType.find((type) => candidate.cnhCategory === type.value)
          );
        }, 200);
      }

      if (candidate?.isPcd) {
        setHasPcd(candidate.isPcd);
      }

      const universityCourses = user.candidate?.scholarity?.universityCourses;
      const universityCoursesMapped =
        universityCourses &&
        universityCourses.map(
          (universityCourse) =>
            ({
              id: universityCourse.id,
              type: universityCourse.type,
              course: universityCourse.course,
              teachingEntity: universityCourse.teachingEntity,
              startDate: universityCourse.startDate && new Date(universityCourse.startDate),
              endDate: universityCourse.endDate && new Date(universityCourse.endDate)
            } as UniversityCourse)
        );
      if (universityCoursesMapped) {
        setUniversityCourses(universityCoursesMapped);
      } else {
        setUniversityCourses([]);
      }

      const workExdperiences = user.candidate?.workExperiences;
      const workExperiencesMapped =
        workExdperiences &&
        workExdperiences.map(
          (workExperience) =>
            ({
              id: workExperience.id,
              company: workExperience.company,
              occupation: workExperience.occupation,
              currentJob: workExperience.finishDate ? false : true,
              description: workExperience.description,
              initialDate: workExperience.initialDate && new Date(workExperience.initialDate),
              finishDate: workExperience.finishDate && new Date(workExperience.finishDate)
            } as WorkExperience)
        );
      if (workExperiencesMapped) setWorkExperiences(workExperiencesMapped);
    } catch (resultError) {
      handleErrors(resultError);
    }
  };

  const handleShowImage = () => {
    setIsOpen(true);
  };

  const handleCloseImage = () => {
    setIsOpen(false);
  };

  const handleSelectState = (value: any) => {
    if (value) {
      formRef.current?.clearField('candidate.city');
      setCurrentState(value);
    }
  };

  useEffect(() => {
    if (signed) loadUserForm();
  }, [user]);

  useEffect(() => {
    getSelectedUniversityCourse();
    setScholarityErrors('');
  }, [selectedUniversityCourseIndex]);

  useEffect(() => {
    setScholarityErrors('');
  }, [selectedUniversityCourseIndex, scholarityType, universityCourses]);

  useEffect(() => {
    citiesOptions();
  }, [currentState]);

  useEffect(() => {
    stateOptions();
  }, []);

  return (
    <div>
      <Form
        className="w-full"
        ref={formRef}
        initialData={initialDataValue}
        onSubmit={onSubmitFormCandidate}
      >
        <div className="flex flex-col gap-7">
          <h1 className="text-3xl font-semibold text-teal-600 py-3">
            {signed ? 'Editar conta' : 'Criar conta'}
          </h1>
          <DashedBox title="Dados de acesso">
            {signed ? (
              <ChangePassword />
            ) : (
              <div className="flex flex-col md:flex-row justify-between md:gap-16">
                <div className="flex flex-col w-full md:w-2/4 gap-4 ">
                  <InputForm
                    type="email"
                    name="email"
                    label="E-mail*"
                    placeholder="Digite um e-mail."
                  />
                </div>
                <div className="flex flex-col w-full md:w-2/4 gap-4 ">
                  <InputForm
                    type="password"
                    name="password"
                    label="Senha*"
                    placeholder="Digite uma senha."
                  />
                  <InputForm
                    type="password"
                    name="confirmPassword"
                    label="Confirmar senha*"
                    placeholder="Confirme sua senha."
                  />
                </div>
              </div>
            )}
          </DashedBox>
          <h1 className="text-3xl font-semibold text-teal-600 py-3">
            {signed ? 'Editar currículo' : 'Cadastre seu currículo'}
          </h1>

          <DashedBox title="Dados pessoais">
            <div className="flex flex-col justify-between gap-4">
              <div className="flex flex-col md:flex-row justify-between md:gap-16">
                <div className="w-full ">
                  <InputForm type="text" name="name" label="Nome*" placeholder="Digite seu nome." />
                </div>
                <div className="w-full ">
                  <InputMaskForm
                    name="candidate.phoneNumber"
                    maskChar={null}
                    inputMask="(99) 99999-9999"
                    label="Telefone*"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between md:gap-16">
                <div className="w-full">
                  <SelectForm
                    name="candidate.state"
                    label="Estado"
                    options={states}
                    onChangeValue={handleSelectState}
                  />
                </div>
                <div className="w-full">
                  <SelectForm
                    name="candidate.city"
                    label="Cidade"
                    options={cities}
                    disabled={!currentState}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between md:gap-16">
                <div className="w-full ">
                  <InputForm
                    type="text"
                    name="candidate.linkedin"
                    label="Linkedin (Opcional)"
                    placeholder="URL Linkedin."
                  />
                </div>
                <div className="w-full ">
                  <SelectForm name="candidate.area" label="Área de interesse*" options={areaList} />
                </div>
              </div>
              <div className="">
                <TextareaForm
                  label="Sobre mim*"
                  name="candidate.aboutMe"
                  placeholder="Descreva brevemente um resumo com informações que você considere importante contar sobre você."
                  cols={2}
                  rows={6}
                  maxLength={3000}
                ></TextareaForm>
                {!signed && (
                  <DropzoneForm
                    onChange={(file: File) => setFile(file)}
                    name="candidate.curriculumPdf"
                    label="adicionar um arquivo PDF"
                    acceptFiles={{ 'application/pdf': ['.pdf'] }}
                  />
                )}
              </div>
              {file && (
                <div className="flex flex-col">
                  <ImageDetail
                    showImage={handleShowImage}
                    removeImage={() => setFile(undefined)}
                    file={file}
                  />
                </div>
              )}
            </div>
          </DashedBox>
          <DashedBox title="Formações">
            <div className="w-full md:w-1/2 mb-6 md:pr-8">
              <SelectForm
                name="candidate.scholarity.type"
                label="Nivel*"
                options={scholarityTypeList}
                onChangeValue={handleScholaritySelected}
              />
            </div>
            {scholarityErrors && (
              <div className="w-full md:w-1/2 mb-4 md:pr-8">
                <Alert text={scholarityErrors} variant={AlertType.ERROR} />
              </div>
            )}
            {higherEducation && (
              <div className="flex flex-col md:flex-row gap-10 lg:gap-16">
                <div className="w-full">
                  <UniversityEducationForm
                    universityCourseToEdit={getSelectedUniversityCourse()}
                    onConfirm={handleNewUniversityCourse}
                    onClear={() => handleClearUniversityCourseForm()}
                  />
                </div>
                <div className="w-full flex flex-col gap-4 max-h-[400px] overflow-auto">
                  {universityCourses.length != 0 &&
                    universityCourses.map((universityCourse, index) => (
                      <div key={index}>
                        <UniversityEducationItem
                          universityCourse={universityCourse}
                          onClickEditItem={() => handleClickEditUniversityCourse(index)}
                          onClickRemoveItem={() => handleClickRemoveUniversityCourse(index)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </DashedBox>
          <DashedBox title="Experiência profissional">
            {workExperiences.length == 0 && (
              <div className="mb-4" onChange={handleChangeHaveNoWorkExperience}>
                <CheckboxForm
                  name="candidate.haveNoWorkExperience"
                  value="consent"
                  label="Não possuo experiência profissional"
                />
              </div>
            )}
            {haveWorkExperience ? (
              <div className="bg-gray-200 justify-center sm:justify-start flex flex-row p-1 items-center rounded opacity-40 mb-4 w-full sm:w-96">
                <div className="flex justify-center items-center ">
                  <PlusIcon className="h-8 w-8 text-teal-600 mr-3 sm:mr-0" />
                </div>
                <span className="text-sm text-gray-500 sm:text-base mr-4 sm:px-4 font-medium select-none">
                  Adicionar expêriencia profissional
                </span>
              </div>
            ) : (
              <div
                onClick={() => setOpenNewExperienceModal(true)}
                className="bg-teal-600 justify-center sm:justify-start flex flex-row p-1 items-center rounded mb-4 w-full sm:w-96 cursor-pointer"
              >
                <div className="flex justify-center items-center ">
                  <PlusIcon className="h-8 w-8 text-white mr-3 sm:mr-0" />
                </div>
                <span className="text-sm text-white sm:text-base mr-4 sm:px-4 font-medium select-none">
                  Adicionar expêriencia profissional
                </span>
              </div>
            )}
            <div className="w-full flex flex-col gap-4 max-h-96 overflow-auto">
              {workExperienceErrors && (
                <div className="w-full">
                  <Alert text={workExperienceErrors} variant={AlertType.ERROR} />
                </div>
              )}
              {workExperiences &&
                workExperiences.map((workExperience, index) => (
                  <div key={index}>
                    <WorkExperienceCard
                      workExperience={workExperience}
                      onClickEditItem={() => handleClickEditWorkExperience(index)}
                      onClickRemoveItem={() => handleClickRemoveWorkExperience(index)}
                    />
                  </div>
                ))}
            </div>
          </DashedBox>
          <DashedBox title="Informações Adicionais">
            <div className="flex md:flex-row flex-col justify-start md:gap-16 gap-0">
              <div className="flex flex-col items-center w-full">
                <div className="mb-2 w-full" onChange={handleChangeHasCnh}>
                  <CheckboxForm
                    name="candidate.hasDriverLicense"
                    value="consent"
                    label="Possuo habilitação"
                  />
                </div>
                {hasCnh && (
                  <div className="w-full">
                    <SelectForm
                      name="candidate.cnhCategory"
                      label="Tipo"
                      disabled={!hasCnh}
                      options={cnhType.map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        };
                      })}
                      onChangeValue={() => null}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center w-full">
                <div className="mb-2 w-full" onChange={handleChangeHasPcd}>
                  <CheckboxForm name="candidate.isPcd" value="consent" label="Sou PcD" />
                </div>
                {hasPcd && (
                  <div className="w-full mb-4">
                    <InputForm
                      type="text"
                      name="candidate.cid"
                      label="Tipo de CID"
                      placeholder="Informe aqui"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center w-full">
              <div className="mb-2 md:mt-8 mt-0 w-full" onChange={handleChangeCanTravel}>
                <CheckboxForm
                  name="candidate.canTravel"
                  value="consent"
                  label="Disponibilidade para viagens"
                />
              </div>
            </div>
          </DashedBox>
        </div>
        <NewWorkExperienceModal
          workExperienceToEdit={getSelectedWorkExperience()}
          isOpen={openNewExperienceModal}
          onClose={handleCloseNewExperience}
          onConfirm={handleNewWorkExperience}
        />
        {!signed && (
          <div className="py-2 px-4 mt-4 rounded bg-gray-100">
            <PrivacyNoticeTermCheckbox name="candidate.privacyTerm" />
          </div>
        )}
        <div className="flex justify-end gap-4 py-6">
          {!signed && (
            <Button variant="default" onClick={() => history.push(Routes.LOGIN)}>
              Cancelar
            </Button>
          )}
          <Button variant="primary" type="button" onClick={onSubmitFormCandidate}>
            Salvar
          </Button>
        </div>
      </Form>
      <ConfirmModal
        isOpen={openModalRemoveUniversityCourse}
        onClose={() => setOpenModalRemoveUniversityCourse(false)}
        modalText="Deseja remover esse ensino superior?"
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: () => setOpenModalRemoveUniversityCourse(false)
        }}
        confirmButton={{
          buttonText: 'Confirmar',
          onClick: handleRemoveUniversityCourse
        }}
      />
      <ConfirmModal
        isOpen={openModalRemoveExperience}
        onClose={() => setOpenModalRemoveExperience(false)}
        modalText="Deseja remover essa experiência profissional?"
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: () => setOpenModalRemoveExperience(false)
        }}
        confirmButton={{
          buttonText: 'Confirmar',
          onClick: handleRemoveWorkExperience
        }}
      />
      <ImagePreviewModal file={file} isOpen={isOpen} onClose={handleCloseImage} />
    </div>
  );
};

export default CandidateForm;
