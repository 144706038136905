import { Dialog, Switch, Transition } from '@headlessui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../../../../components/Button';
import InputForm from '../../../../../../../components/FormComponents/InputForm';
import {
  getFieldErrors,
  manageApiErrorMessages,
  manageApiErrorResponse
} from '../../../../../../_shared/helpers/handleApiErrorResponse';
import { CreateTypeOpportunityDto } from '../../../../dtos/CreateTypeOpportunityDto';
import { UpdateTypeOpportunityDto } from '../../../../dtos/UpdateTypeOpportunityDto';
import { TypeOpportunity } from '../../../../models/TypeOpportunity';
import TypeOpportunityService from '../../../../service/TypeOpportunityService';

interface ConfigModalProps {
  typeOpportunity?: TypeOpportunity;
  modalText: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const TypeOpportunityModal = ({
  typeOpportunity,
  modalText,
  isOpen,
  onClose,
  onConfirm
}: ConfigModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const [isActive, setIsActive] = useState(false);

  const handleAddNewOrUpdateTypeOpportunity = async () => {
    const mainFormData = formRef.current?.getData();
    const dto = { ...mainFormData, isActive: isActive };

    try {
      if (typeOpportunity?.id) {
        const updateTypeOpportunityDto = {
          ...dto
        } as UpdateTypeOpportunityDto;
        const result = await TypeOpportunityService.update(
          typeOpportunity.id,
          updateTypeOpportunityDto
        );
        toast.success(result.message);
      } else {
        const newTypeOpportunityDto = {
          ...dto
        } as CreateTypeOpportunityDto;
        const result = await TypeOpportunityService.newTypeOpportunity(newTypeOpportunityDto);
        toast.success(result.message);
      }
      onClose();
      onConfirm();
      clearForm();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (resultError: unknown) => {
    const fieldsErrors = getFieldErrors(resultError);
    formRef.current?.setErrors(fieldsErrors);
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const resultErrors = manageApiErrorMessages(resultErrorResponse);
    resultErrors.map((resultError) => toast.error(resultError));
  };

  const handleCancel = () => {
    onClose();
    clearForm();
  };

  const clearForm = () => {
    formRef.current?.reset();
  };

  useEffect(() => {
    if (typeOpportunity) {
      setIsActive(typeOpportunity?.isActive);
    } else {
      setIsActive(false);
    }
  }, [typeOpportunity]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center items-center h-full md:h-full inset-0">
              <div className="relative px-4 w-full max-w-lg h-auto m-auto">
                <Form
                  ref={formRef}
                  initialData={typeOpportunity}
                  onSubmit={handleAddNewOrUpdateTypeOpportunity}
                  className="flex justify-center"
                >
                  <div className="relative bg-white rounded-lg shadow w-10/12 ">
                    <div className="flex justify-between items-start p-6 rounded-t border-b">
                      <span className="text-xl font-semibold text-gray-title lg:text-2xl">
                        {modalText}
                      </span>
                    </div>
                    <div className="p-6 space-y-3 ">
                      <InputForm name="name" type="string" label="Nome" />
                      <div className="pt-3 text-sm font-medium flex items-center gap-2">
                        <span className="whitespace-nowrap border-y-0">Ativo:</span>
                        <Switch
                          checked={isActive}
                          onChange={setIsActive}
                          className={`${
                            isActive ? 'bg-teal-600' : 'bg-gray-600'
                          } inline-flex h-4 w-10 items-center rounded-full`}
                        >
                          <span
                            className={`${
                              isActive ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                          />
                        </Switch>
                      </div>
                    </div>

                    <div className="flex items-center  p-6 space-x-3 rounded-b border-t border-gray-200 justify-end">
                      <Button variant="default" type="button" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={handleAddNewOrUpdateTypeOpportunity}
                      >
                        Adicionar
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TypeOpportunityModal;
